import React, { useEffect, useState, useRef } from "react";

// Components
import TextField from "../components/TextField";

import SimpleReactValidator from "simple-react-validator";
import service from "../common/service";

function Popup({ title, popup_note, form, onChildClick }) {
  const apiServe = new service();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [email, setEmail] = useState();

  useEffect(() => {
    simpleValidator.current.showMessageFor("email");
    // document.body.addEventListener("click", (e) => bodyClick(e));
    document.body.addEventListener("keydown", (e) => keyDownClick(e));
  });

  // ESC Click
  function keyDownClick(e) {
    // Hide Popup
    return e.keyCode === 27 ? onChildClick() : "";
  }
  // Body Click
  // function bodyClick(e) {
  //   // Hide Popup
  //   if (document.querySelector(".sd_popup")) {
  //     return !e.target.closest(".sd_popup_inner") ? onChildClick() : "";
  //   }
  // }

  // Popup Close
  function closePopup(data) {
    onChildClick(data);
  }

  function submit(e) {
    e.preventDefault();
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      let data = {
        email: email,
      };
      apiServe.sendevent("generalUser|forgotPassword", data);
      checkRes();
    }
  }

  function checkRes() {
    apiServe.getevent().subscribe((response) => {
      switch (response.event) {
        case "generalUser|forgotPassword":
          if (response.error) {
            setInvalidEmail(true);
            return false;
          } else {
            closePopup("forgot");
          }
          break;
        default:
          break;
      }
    });
  }

  return (
    <React.Fragment>
      <div className="sd_popup">
        <div className="sd_popup_inner">
          {title ? (
            <div className="sd_popup_header">
              <h3>{title}</h3>
            </div>
          ) : (
            ""
          )}
          <div className="sd_popup_content">
            {Object.values(popup_note).map((note) => {
              return <p>{note}</p>;
            })}

            {form ? (
              <div className="sd_popup_form sd_user_form">
                {title === "Forgot Password" ? (
                  <form className="sd_forgot_password">
                    <div className="sd_form_group">
                      <TextField
                        type="text"
                        label="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />

                      <span className="sd_input_error_msg">
                        {simpleValidator.current.message(
                          "email",
                          email,
                          "required|email"
                        )}
                      </span>

                      {invalidEmail ? (
                        <span className="sd_input_error_msg">
                          This email doesn’t exist in our database! Please try
                          again.
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="sd_form_buttons sd_popup_buttons">
                      <button
                        className="sd_form_submit_button sd_grey_btn"
                        type="button"
                        onClick={closePopup}
                      >
                        Cancel
                      </button>
                      <button
                        className="sd_form_submit_button sd_yellow_fill_btn"
                        type="submit"
                        onClick={submit}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div className="sd_popup_buttons sd_popup_confirm_buttons">
                <button
                  className="sd_grey_btn"
                  type="button"
                  onClick={closePopup}
                >
                  OK
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Popup;
