import React, { Component } from "react";
// layouts
import Footer from "../../layout/footer";
import Header from "../../layout/header";
// services
import service from "../../common/service";
//Carousel
import { Carousel } from "react-responsive-carousel";
//component
import PartnerBlock from "../../components/PartnerBlock";
import PartnerEvent from "../../components/PartnerEvent";
//Masonry css
import Masonry from "react-masonry-css";
// video
import VideoPlayer from "../../components/VideoPlayer";

import Loader from "../../components/Loader";
import Cookies from "universal-cookie";
import FavoriteCard from "../../components/FavoriteCard";
const cookies = new Cookies();

const videoExtensions = [
  "3g2",
  "3gp",
  "aaf",
  "asf",
  "avchd",
  "avi",
  "drc",
  "flv",
  "m2v",
  "m4p",
  "m4v",
  "mkv",
  "mng",
  "mov",
  "mp2",
  "mp4",
  "mpe",
  "mpeg",
  "mpg",
  "mpv",
  "mxf",
  "nsv",
  "ogg",
  "ogv",
  "qt",
  "rm",
  "rmvb",
  "roq",
  "svi",
  "vob",
  "webm",
  "wmv",
  "yuv",
];
let sub;
let timer = null;
class Partner extends Component {
  constructor(props) {
    super(props);
    this.apiServe = new service();
    this.state = {
      heroHeader: "",
      heroBanner: true,
      homeData: "",
      SatelliteData: "",
      SatelliteAllData: "",
      partnerData: "",
      highLightData: "",
      checkVideo: false,
      loadData: false,
      prtnerHeroHeader: "",
    };
    this.hideHeroBanner = this.hideHeroBanner.bind(this);
    this.changeCarousal = this.changeCarousal.bind(this);
    this.playerVideo = React.createRef();
    document.querySelector("body").scrollTo({ top: 0, behavior: "smooth" });
  }
  componentWillUnmount = () => {
    sub.unsubscribe();
    clearTimeout(timer);
  };

  pauseYoutube() {
    var video = document.querySelectorAll('iframe[src*="youtube.com"]');
    video.forEach((element) => {
      element.contentWindow.postMessage(
        '{"event":"command","func":"' + "pauseVideo" + '","args":""}',
        "*"
      );
    });
  }

  getHeroHeadre = () => {
    this.apiServe.sendevent("homePage|HeaderAndFooter|getList", {
      limit: 10,
      page: 1,
    });
  };

  getPartner = () => {
    this.apiServe.sendevent("FestivalVillage|FV_04_Partner", {
      limit: this.loadItem,
      page: 1,
    });
  };
  getPreview = () => {
    this.apiServe.sendevent("FestivalVillage|Event|Preview|getPreview", {});
  };
  getPartnerHeroHeader(previewData) {
    let paramsData = this.apiServe.decrypt(previewData);
    var self = this;
    paramsData.then(function (val) {
      if (val) {
        self.setState({
          homeData: val,
        });
      }
    });
    // if (this.props.location.search) {
    //   var paramsData1 = this.props.location.search.replace("?data=", "");
    //   let paramsData = this.apiServe.decrypt(paramsData1);
    //   var self = this;
    //   paramsData.then(function (val) {
    //     if (val) {
    //       self.setState({
    //         homeData: val,
    //       });
    //     }
    //   });
    // }
  }
  componentDidMount = () => {
    this.getHeroHeadre();
    this.getPartner();
    this.getPreview();
    let particularUser =
      cookies.get("SundanceNewUser") || cookies.get("particularNewUser");

    if (particularUser && particularUser.token) {
      this.apiServe.sendevent("authentication", {
        token: particularUser.token,
      });
    }

    sub = this.apiServe.getevent().subscribe((response) => {
      switch (response.event) {
        case "authentication":
          if (response.error) {
            let Zone = localStorage.getItem('TIMEZONE')
            localStorage.clear();
            if (Zone) localStorage.setItem('TIMEZONE', Zone)
          } else {
            localStorage.setItem("loggedInUser", response.data.user.role);
            localStorage.setItem("myLoginToken", response.data.token);
            this.getProfile();
          }

          break;
        case "user|getProfile":
          if (response.error) {
          } else {
            if (localStorage.getItem("myLoginToken")) {
              let userInfo = {
                fname: response.data.fname,
                lname: response.data.lname,
                image: response.data.image,
                showMyLibrary: response.data.showMyLibrary,
              };
              localStorage.setItem("Header", JSON.stringify(userInfo));
            }
            this.setState({
              Header: true,
              code_conduct: response.data.COMM_AGMT_FLAG,
            });
            this.forceUpdate();
          }
          break;
        case "homePage|HeaderAndFooter|getList":
          if (response.error) {
          } else {
            this.setState({
              heroHeader: response.data.docs[0],
            });
          }
          break;

        case "FestivalVillage|FV_04_Partner":
          if (response.error) {
          } else {
            this.setState({
              partnerData: response.data,
              loadData: false,
            });
            
          }
          break;
        case "FestivalVillage|Partner|PartnerPage|getList":
          if (response.error) {
          } else {
            this.setState({
              homeData: response.data,
            });
           
          }
          break;
        case "FestivalVillage|Event|Preview|getPreview":
          if (response.error) {
          } else {
            this.getPartnerHeroHeader(response.data.previewString);
            
          }
          break;
        default:
          break;
      }
    });
  };

  getProfile = () => {
    this.apiServe.sendevent("user|getProfile", {});
  };

  changeCarousal(index) {
    if (this.playerVideo && this.playerVideo.current) {
      this.playerVideo.current.pause();
    }
    var video = document.querySelectorAll('iframe[src*="youtube.com"]');
    if (video.length > 0) {
      this.pauseYoutube();
    }
  }

  setContainer(c) {
    this.container = c;
  }
  // HeroBanner
  hideHeroBanner() {
    this.setState({
      heroBanner: !this.state.heroBanner,
    });
  }
  // Extension
  getFileExtension(filename) {
    return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined;
  }

  checkIsVideo(filePath) {
    var getExtension = this.getFileExtension(filePath);
    return videoExtensions.includes(getExtension);
  }
  checkisIframe(url) {
    var expVimeo = new RegExp(/(vimeo\.com)/);
    var expYoutube = new RegExp(/(youtu\.be|youtube\.com)/);

    var expVimeoTest = expVimeo.test(url);
    var expYoutubeTest = expYoutube.test(url);

    if (expVimeoTest || expYoutubeTest) {
      return true;
    } else {
      return false;
    }
  }
  render() {
    const {
      heroBanner,
      heroHeader,
      partnerData,
      loadData,
      homeData,
      prtnerHeroHeader,
    } = this.state;
    const breakpointColumnsObj = {
      default: 4,
      1220: 2,
      1024: 2,
      768: 2,
      600: 1,
    };

    return (
      <section className="sd_Partner_section sd_home_page">
        {/* Header */}
        <Header history={this.props.history} />
        {/* Carousel */}
        {/* Loader */}
        {loadData && <Loader />}
        <section className="sd_header ">
          <div className="sd_header_sec_section">
            <div className="sd_simplelp_section">
              <div className="sd_large_container sd_inner_container sd_hero_header_inner_section sd_relative">
                <div className="sd_header_sec">
                  <div className="sd_simplelp_left sd_header_text">
                    <h1 className="sd_textuppercase">
                      {homeData && homeData.pageTitle}
                    </h1>
                    <p
                      className="paragraph_span_22"
                      dangerouslySetInnerHTML={{
                        __html: homeData && homeData.pageDescription,
                      }}
                    />
                  </div>
                  <div className="sd_simplelp_right_inner">
                    <Carousel
                      showArrows={true}
                      infiniteLoop={true}
                      swipeable={true}
                      emulateTouch={true}
                      dynamicHeight={true}
                      onChange={this.changeCarousal}
                    >
                      {homeData && homeData.frontMedia == ""
                        ? ""
                        : homeData &&
                        homeData.frontMedia.map((card, i) => (
                          <div key={i}>
                            {this.checkisIframe(
                              card.mediaImage != ""
                                ? card.mediaImage
                                : card.mediaUrl
                            ) ? (
                                <div className="sd_video_wpr iframeContainer">
                                  <iframe
                                    src={
                                      card.mediaImage != ""
                                        ? process.env.REACT_APP_IMAGE_URL +
                                        card.mediaImage
                                        : card.mediaUrl +
                                        "?enablejsapi=1&version=3&playerapiid=ytplayer"
                                    }
                                  ></iframe>
                                </div>
                              ) : this.checkIsVideo(
                                card.mediaImage != ""
                                  ? process.env.REACT_APP_IMAGE_URL +
                                  card.mediaImage
                                  : card.mediaUrl
                              ) ? (
                                  <div className="sd_video_wpr">
                                    <VideoPlayer
                                      videoSrc={
                                        card.mediaImage != ""
                                          ? process.env.REACT_APP_IMAGE_URL +
                                          card.mediaImage
                                          : card.mediaUrl
                                      }
                                      autoplay={false}
                                      loop={false}
                                      ref={this.playerVideo}
                                    />
                                  </div>
                                ) : (
                                  <img
                                    src={
                                      card.mediaImage != ""
                                        ? `${process.env.REACT_APP_IMAGE_URL}` +
                                        card.mediaImage
                                        : card.mediaUrl
                                    }
                                  />
                                )}
                          </div>
                        ))}
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* PartnerBlock */}
        <section className="sd_PartnerBlock">
          <div className="sd_PartnerBlock_container">
            <div className="sd_PartnerBlock_inner">
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {this.state.partnerData &&
                  this.state.partnerData.partner_instituteAssociate_false.partner.map(
                    (item, i) => (
                      <PartnerBlock
                        content={{
                          link: [
                            item.partnerVenueName,
                            "/main-street/" + item?.partnerName.trim().split(' ').join('-').toLowerCase() + '/' + item._id,
                          ],
                          state: item,
                        }}
                      />
                    )
                  )}
              </Masonry>
            </div>
          </div>
        </section>

        {/* PartnerAllEvent */}
        <section className="sd_partner_event">
          <div className="sd_partnerevent_container">
            <div className="sd_inner_partnerevent">
              <h2>See events from other partners</h2>
            </div>
            <div className="sd_block_sec">
              <div className="sd_inner_block">
                {this.state.partnerData &&
                  this.state.partnerData.partner_instituteAssociate.partner.map(
                    (item, i) => (
                      <PartnerEvent
                        key={i}
                        content={{
                          link: [
                            item.partnerVenueName,
                            "/main-street/" + item?.partnerName.trim().split(' ').join('-').toLowerCase() + '/' + item._id,
                          ],
                          state: item,
                        }}
                      />
                    )
                  )}
              </div>
            </div>
          </div>

          {/* <PartnerEvent /> */}
        </section>

        {/* footer */}
        <Footer />
      </section>
    );
  }
}
export default Partner;
