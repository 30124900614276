import React, { useEffect } from "react";

// Components
// import TextField from "../components/TextField";

function Popup({ title, popup_note, form, name, onChildClick }) {
  useEffect(() => {
    document.body.addEventListener("click", (e) => bodyClick(e));
    document.body.addEventListener("keydown", (e) => keyDownClick(e));
  });

  // ESC Click
  function keyDownClick(e) {
    // Hide Popup
    return e.keyCode === 27 ? onChildClick() : "";
  }
  // Body Click
  function bodyClick(e) {
    // Hide Popup
    if (document.querySelector(".sd_popup_light")) {
      return !e.target.closest(".sd_popup_light_inner") ? onChildClick() : "";
    }
  }

  // Popup Close
  function closePopup(e) {
    onChildClick(e);
  }

  function submitPopup(e) {
    onChildClick(e);
  }

  return (
    <React.Fragment>
      <div className="sd_popup_light">
        <div className="sd_popup_light_inner">
          {title ? (
            <div className="sd_popup_light_header">
              <h3>{title}</h3>
            </div>
          ) : (
            ""
          )}
          <div className="sd_popup_light_content">
            {Object.values(popup_note).map((note) => {
              return <p>{note}</p>;
            })}

            {form ? (
              <div className="sd_popup_light_form sd_user_form">
                {/* Forgot Password */}
                {title === "Forgot Password" ? (
                  <form className="sd_forgot_password">
                    <div className="sd_form_group">
                      {/* <TextField type="text" label="email" /> */}
                      <span className="sd_input_error_msg">
                        This email doesn’t exist in our database! Please try
                        again.
                      </span>
                    </div>
                    <div className="sd_form_buttons sd_popup_light_buttons sd_popup_buttons">
                      <button
                        className="sd_form_submit_button sd_grey_btn"
                        type="button"
                        onClick={(e) => closePopup(e)}
                      >
                        Cancel
                      </button>
                      <button
                        className="sd_form_submit_button sd_pink_fill_btn"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                ) : (
                  ""
                )}

                {/* Remove Item */}
                {name === "remove_item" ? (
                  <form className="sd_remove_item">
                    <h5>Do you want to proceed?</h5>
                    <div className="sd_form_buttons sd_popup_light_buttons sd_popup_buttons">
                      <button
                        className="sd_form_submit_button sd_grey_btn"
                        type="button"
                        value="Yes"
                        onClick={submitPopup}
                      >
                        Yes
                      </button>
                      <button
                        className="sd_form_submit_button sd_pink_fill_btn"
                        type="submit"
                        onClick={closePopup}
                      >
                        No
                      </button>
                    </div>
                  </form>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div className="sd_popup_light_buttons sd_popup_confirm_buttons">
                <button
                  className="sd_grey_btn"
                  type="button"
                  onClick={closePopup}
                >
                  OK
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Popup;
