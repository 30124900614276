import React, { Component } from "react";
// layouts
import Footer from '../../layout/footer';
import Header from '../../layout/header';
// annimation
import { VelocityTransitionGroup } from "velocity-react";
// services

import service from '../../common/service';
import Cookies from "universal-cookie";
//Carousel
import { Carousel } from "react-responsive-carousel";
//component
import SponsorsLogo from '../../components/sponsorsLogo/SponsorsLogo';
import ArtistEventBlock from '../../components/ArtistEventBlock';
// video

import VideoPlayer from '../../components/VideoPlayer';
import Loader from '../../components/Loader';
const cookies = new Cookies();

const videoExtensions = [
  "3g2",
  "3gp",
  "aaf",
  "asf",
  "avchd",
  "avi",
  "drc",
  "flv",
  "m2v",
  "m4p",
  "m4v",
  "mkv",
  "mng",
  "mov",
  "mp2",
  "mp4",
  "mpe",
  "mpeg",
  "mpg",
  "mpv",
  "mxf",
  "nsv",
  "ogg",
  "ogv",
  "qt",
  "rm",
  "rmvb",
  "roq",
  "svi",
  "vob",
  "webm",
  "wmv",
  "yuv",
];
let sub;
let timer = null;
class ArtistLounge extends Component {
  constructor(props) {
    super(props);
    this.apiServe = new service();
    this.state = {
      heroHeader: "",
      heroBanner: true,
      Artist: "",
      checkVideo: false,
      loadData: false,
      ArtistDataPreview: '',
    };
    this.hideHeroBanner = this.hideHeroBanner.bind(this);
    this.changeCarousal = this.changeCarousal.bind(this);
    this.playerVideo = React.createRef();
    document.querySelector("body").scrollTo({ top: 0, behavior: "smooth" });
  }
  componentWillUnmount = () => {
    sub.unsubscribe();
    clearTimeout(timer);
  };
  pauseYoutube() {
    var video = document.querySelectorAll('iframe[src*="youtube.com"]');
    video.forEach(element => {
      element.contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
    });
  }
  changeCarousal(index) {
    if (this.playerVideo && this.playerVideo.current) {
      this.playerVideo.current.pause()
    }
    var video = document.querySelectorAll('iframe[src*="youtube.com"]');
    if (video.length > 0) {
      this.pauseYoutube();
    }
  }

  getHeroHeadre = () => {
    this.apiServe.sendevent("homePage|HeaderAndFooter|getList", {
      limit: 10,
      page: 1,
    });
  };

  //   getArtist = () => {
  //     this.setState({
  //       loadData: true,
  //     });
  //     this.apiServe.sendevent(
  //       "FestivalVillage|ArtistLounge|ArtistLoungePage|getList",
  //       {
  //         limit: 10,
  //         page: 1,
  //       }
  //     );
  //   };


  getPreview = () => {
    this.apiServe.sendevent("FestivalVillage|Event|Preview|getPreview", {});
  }
  getArtist = (previewData) => {
    let paramsData = this.apiServe.decrypt(previewData)
    var self = this;
    paramsData.then(function (val) {
      if (val) {
        self.setState({
          Artist: val
        })

      }
    });
  }

  componentDidMount = () => {
    this.getHeroHeadre();
    // this.getArtist();
    this.getPreview();
    let particularUser =
      cookies.get("SundanceNewUser") || cookies.get("particularNewUser");

    if (particularUser && particularUser.token) {
      this.apiServe.sendevent("authentication", {
        token: particularUser.token,
      });
    }

    sub = this.apiServe.getevent().subscribe((response) => {
      switch (response.event) {
        case "authentication":
          if (response.error) {
            let Zone = localStorage.getItem('TIMEZONE')
            localStorage.clear();
            if (Zone) localStorage.setItem('TIMEZONE', Zone)
          } else {
            localStorage.setItem("loggedInUser", response.data.user.role);
            localStorage.setItem("myLoginToken", response.data.token);
            this.getProfile();
          }

          break;
        case "user|getProfile":
          if (response.error) {
          } else {
            if (localStorage.getItem("myLoginToken")) {
              let userInfo = {
                fname: response.data.fname,
                lname: response.data.lname,
                image: response.data.image,
                showMyLibrary: response.data.showMyLibrary,
              };
              localStorage.setItem("Header", JSON.stringify(userInfo));
            }
            this.setState({
              Header: true,
              code_conduct: response.data.COMM_AGMT_FLAG,
            });
            this.forceUpdate();
          }
          break;
        case "homePage|HeaderAndFooter|getList":
          if (response.error) {
          } else {
            this.setState({
              heroHeader: response.data.docs[0],
            });
          }
          break;

        case "FestivalVillage|ArtistLounge|ArtistLoungePage|getList":
          if (response.error) {
          } else {
            this.setState({
              Artist: response.data,
              loadData: false,
            });

          }
          break;

        case "FestivalVillage|Event|Preview|getPreview":
          if (response.error) {
          } else {
            this.setState({

              ArtistDataPreview: response.data.previewString,
              loadData: false,
            });
            this.getArtist(response.data.previewString);
          }
          break;
      }
    });


  };

  getProfile = () => {
    this.apiServe.sendevent("user|getProfile", {});
  };

  setContainer(c) {
    this.container = c;
  }
  // HeroBanner
  hideHeroBanner() {
    this.setState({
      heroBanner: !this.state.heroBanner,
    });
  }
  // Extension
  getFileExtension(filename) {
    return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined;
  }

  checkIsVideo(filePath) {
    var getExtension = this.getFileExtension(filePath);
    return videoExtensions.includes(getExtension);
  }

  checkisIframe(url) {
    var expVimeo = new RegExp(/(vimeo\.com)/);
    var expYoutube = new RegExp(/(youtu\.be|youtube\.com)/);

    var expVimeoTest = expVimeo.test(url);
    var expYoutubeTest = expYoutube.test(url);

    if (expVimeoTest || expYoutubeTest) {
      return true;
    } else {
      return false;
    }
  }



  render() {
    const { heroBanner, heroHeader, Artist, loadData } = this.state;
    return (
      <section className="sd_Artist_Lodge_section sd_home_page">
        {/* Header */}
        <Header history={this.props.history} />
        {/* Carousel */}
        {/* Loader */}
        {loadData && <Loader />}
        <section className="sd_header">
          <div className="sd_header_sec_section">
            <div className="sd_simplelp_section">
              <div className="sd_large_container sd_inner_container sd_hero_header_inner_section sd_relative">
                <div className="sd_header_sec">
                  <div className="sd_simplelp_left sd_header_text">
                    <h1 className="sd_textuppercase">
                      {Artist && Artist.pageTitle}
                    </h1>
                    <p
                      className="paragraph_span_22"
                      dangerouslySetInnerHTML={{
                        __html: Artist && Artist.pageDescription,
                      }}
                    />
                  </div>
                  <div className="sd_simplelp_right_inner">
                    <Carousel
                      showArrows={true}
                      infiniteLoop={true}
                      swipeable={true}
                      emulateTouch={true}
                      dynamicHeight={true}
                      onChange={this.changeCarousal}
                      className="bannerSlider"
                    >
                      {
                        Artist &&
                          Artist.frontMedia == '' ?
                          ''
                          :
                          Artist &&
                          Artist.frontMedia &&
                          Artist.frontMedia.map((card, i) => (
                            <div key={i}>
                              {this.checkisIframe(
                                card.mediaImage != ""
                                  ? card.mediaImage
                                  : card.mediaUrl
                              ) ? (
                                  <div className="sd_video_wpr iframeContainer">
                                    <iframe
                                      src={
                                        card.mediaImage != ""
                                          ? process.env.REACT_APP_IMAGE_URL +
                                          card.mediaImage
                                          : card.mediaUrl + '?enablejsapi=1&version=3&playerapiid=ytplayer'
                                      }
                                    ></iframe>
                                  </div>
                                ) : this.checkIsVideo(
                                  card.mediaImage != ""
                                    ? process.env.REACT_APP_IMAGE_URL +
                                    card.mediaImage
                                    : card.mediaUrl
                                ) ? (
                                    <div className="sd_video_wpr">
                                      <VideoPlayer
                                        videoSrc={
                                          card.media != ""
                                            ? process.env.REACT_APP_IMAGE_URL +
                                            card.mediaImage
                                            : card.mediaUrl
                                        }
                                        autoplay={false}
                                        loop={false}
                                        ref={this.playerVideo}
                                      />
                                    </div>
                                  ) : (
                                    <img
                                      src={
                                        card.mediaImage != ""
                                          ? process.env.REACT_APP_IMAGE_URL +
                                          card.mediaImage
                                          : card.mediaUrl
                                      }
                                    />
                                  )}
                            </div>
                          ))}
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* EVENTS FOR ARTISTS */}
        <ArtistEventBlock />

        {/* SponsorsLogo */}
        <section className="sd_sponsors_section sd_home_sponsors_section">
          <SponsorsLogo slider={true} />
        </section>

        {/* footer */}
        <Footer />
      </section>
    );
  }
}
export default ArtistLounge;
