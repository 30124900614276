import { Component } from "react";
import io from "socket.io-client";
import axios from "axios";
import { Observable } from "rxjs";
import CryptoJS from "crypto-js";

// import alertify from "alertifyjs";
require('dotenv').config()

let socket;

class service extends Component {

  apiURL = process.env.REACT_APP_API_KEY;
  socketApi = process.env.REACT_APP_SOCKET_API_KEY + localStorage.getItem('loginToken');
  imageUrl = process.env.REACT_APP_IMAGE_URL;
  loader = "data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="

  connect = async (next) => {

    const socketApi = process.env.REACT_APP_SOCKET_API_KEY;
    socket = io.connect(socketApi);

    socket.on("connect", (res) => {
      next(socket.connected)
    });

  };

  postApi = (data) => {
    return axios.post(`${this.apiURL + data.api}`, data.value, {
      headers: {
        Authorization: localStorage.getItem("loginToken"),
      },
    });
  };

  getApi = (data) => {
    return axios.get(`${this.apiURL + data.api}`, data);
  };

  // sendevent = (e, Data) => {
  //   return socket.emit("request", { event: e, data: Data });
  // };

  // getevent = () => {
  //   return Observable.create((observer) => {
  //     socket.on("response", (data) => {
  //       observer.next(data);
  //     });
  //   });
  // };
  sendevent = (e, Data) => {
    if(process.env.REACT_APP_DISABLE_ENCRYPTION === 'true'){
      return socket.emit("request", { event: e, data: Data });
    }else{
      return socket.emit("request", this.encrypt({ event: e, data: Data }));
    }
  };
    
  getevent = () => {
    var self = this;
    return Observable.create((observer) => {  
      if(process.env.REACT_APP_DISABLE_ENCRYPTION === 'true'){
        socket.on("response", (data) => {
          observer.next(data);
        });
      }else{
        socket.on("response", async (data) => {
          var responseData = await self.decrypt(data)
          observer.next(responseData);
        });
      }
      socket.on("error", (data) => {
        // console.log(data);
      });
    });
  };

  // sendevent = (e, Data) => {
  //   if (process.env.REACT_APP_DISABLE_ENCRYPTION === 'true') {
  //     return socket.emit("request", { event: e, data: Data });
  //   }
  //   else {
  //     return socket.emit("request", this.encrypt({ event: e, data: Data }));
  //   }
  // };
  
  // getevent = () => {
  //   var self = this;
  //   return Observable.create((observer) => {
  
  //     if (process.env.REACT_APP_DISABLE_ENCRYPTION === 'true') {
  //       socket.on("response", (data) => {
  //         observer.next(data);
  //       });
  //     }
  //     else {
  //       socket.on("response", async (data) => {
  //         var responseData = await self.decrypt(data)
  //         observer.next(responseData);
  //       });
  //     }
  
  //     socket.on("error", (data) => {
  //       console.log(data);
  //     });
  //   });
  // };


  success = (msg) => {
    console.log('success - msg=> ', msg)
    // alertify.success(msg);
  };

  error = (msg) => {
    console.log('error - msg=> ', msg)
    // alertify.error(msg);
  };

  removeLocalstorage = () => {
    localStorage.removeItem("loginToken");
  };

  //  encrypt = (data) => {
  //    return CryptoJS.AES.encrypt(
  //      JSON.stringify(data),
  //      process.env.REACT_APP_SECRET_KEY
  //    ).toString();
  //  };
  
  //  decrypt = (data) => {
  //    return new Promise((resolve, reject) => {
  //      var bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_SECRET_KEY);
  //      var decryptedData = []

  //      try {
  //        decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));  
  //      } catch (error) {
  //        decryptedData = []
  //      }
      
  //      return resolve(decryptedData);
  //    });
  //  };
  DPro = (data) => {
    // eslint-disable-next-line
    const bytes = CryptoJS.AES.decrypt(data, 'M+H)E?B\y8v5s2pXXp2s5v8y/B?E(H+M');
    try {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (error) {
        return []
    }

};

  encrypt = (data) => {
    return CryptoJS.AES.encrypt(
       // JSON.stringify(data), process.env.REACT_APP_SECRET_KEY
       JSON.stringify(data), this.DPro(this.DPro(process.env.REACT_APP_SECRET_KEY))
    ).toString();
  };

  decrypt = (data) => {
    return new Promise((resolve, reject) => {
      var bytes = CryptoJS.AES.decrypt(data, this.DPro(this.DPro(process.env.REACT_APP_SECRET_KEY)));
            // var bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_SECRET_KEY);
      var decryptedData = []
      try {
        decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      } catch (error) {
        decryptedData = []
      }
      return resolve(decryptedData);
    });
  };
}


export default service



