import moment from "moment";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Cookies from "universal-cookie";
//service
import service from "../common/service";
import Cart from "../images/cart_icon.svg";
//img
import Resources from "../images/resources_icon.svg";
import Schedule from "../images/my_schedule_icon.svg";
import Settings from "../images/settings_icon.svg";
// import user_avtar from "../images/user_avtar.svg";
import user_avtar from "../images/user_profile_logo.svg";
import { TimezoneConverter } from "../store/functions";

const cookies = new Cookies();

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
    };
    this.apiserve = new service();
  }

  componentDidMount() {
    this.getCurrentTime();
    setInterval(() => {
      this.getCurrentTime();
      this.setState({
        date: new Date(),
      });
    }, 1000);
    setTimeout(() => {
      this.props.updateCartCount()
    }, 400);
  }
  checkAuthentication = () => {
    let particularUser =
      cookies.get("SundanceNewUser") || cookies.get("particularNewUser");
    if (!particularUser) {
      window.open(process.env.REACT_APP_HOME_URL + "sign-in", "_self");
    } else {
      window.open(`${process.env.REACT_APP_HOME_URL}my-account?tab=0`, "_self");
    }
  };
  // Get Current Time
  getCurrentTime = () => {
    let hours, minutes;
    hours = this.state.date.getHours();
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = this.state.date.getMinutes();
    minutes = minutes <= 9 ? `0${minutes}` : minutes;
    return `${hours}:${minutes}`;
  };

  gotoProfile(e, to) {
    if (this.props.tab) {
      this.props.tab(to);
    }
    window.open(`${process.env.REACT_APP_HOME_URL}my-account?tab=${to}`, "_self");
  }
  cartPage(e) { 
    window.open(`${process.env.REACT_APP_HEADER_BUY_TICKETS}/cart`, "_self");
  }
	logout = (e) => {
    document.cookie =
    "SundanceNewUser=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
		cookies.remove("SundanceUser", { domain: ".sundance.org", path: "/" });
    cookies.remove("particularUser", { domain: ".sundance.org", path: "/" });
    cookies.remove("SundanceNewUser", { domain: ".sundance.org", path: "/" });
    document.cookie = "particularNewUser" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    cookies.remove("particularNewUser", { domain: ".sundance.org", path: "/" });
    this.props.closeCartModal()
		  let Zone = localStorage.getItem('TIMEZONE')
      localStorage.clear();
      if(Zone) localStorage.setItem('TIMEZONE' , Zone)
		this.props.history.push("/");
		this.forceUpdate();
    window.open(process.env.REACT_APP_HOME_URL + 'sign-in',"_self")
		return this.props.userLogout();
  
	};

  render() {
    // State
    let { date } = this.state;
    var userData = {};
    let userInfo = JSON.parse(localStorage.getItem("Header"));
    if (this.props && this.props.userInfo) {
      userData = this.props.userInfo;
    }

    let SundanceNewUser = cookies.get("SundanceNewUser");
    let cartCount = cookies.get("CartCount");

    return (
      <div className="sd_user_wpr sd_flex sd_aligncenter">
        {/* Profile */}
        {userData && userData.fname && (
          <div className="sd_user_profile">
            <div class="sd_user_profile_wpr sd_flex sd_aligncenter">
              <div class="sd_user_profile_user sd_flex sd_aligncenter">
                <>
                  <div>
                    <Link
                      onClick={this.checkAuthentication}
                      className="sd_user_profile sd_flex sd_aligncenter"
                    >
                      <div className="sd_user_profile_image">
                        <img
                          src={
                            userInfo && userInfo.image
                              ? `${process.env.REACT_APP_IMAGE_URL}${userInfo.image}`
                              : user_avtar
                          }
                          alt="User name"
                          title={`${userInfo && userInfo.fname} ${
                            userInfo && userInfo.lname
                          }`}
                        />
                      </div>
                      <p
                        title={`${userInfo && userInfo.fname} ${
                          userInfo && userInfo.lname
                        }`}
                      >
                        {" "}
                        Hi, {userInfo && userInfo.fname}{" "}
                      </p>
                    </Link>
                  </div>
                </>

                {/* Nav Options */}
                {userData && userData.fname && (
                  <div className="sd_user_profile_menus sd_user_profile_m_menu sd_hidden">
                    <ul>
                      {/* <li>
                        <Link onClick={(e) => this.gotoProfile(e, 2)}>
                          <img
                            src={Resources}
                            alt="My Purchases "
                            title="My Purchases    "
                          />
                          My Purchases
                        </Link>
                      </li>
                      <li>
                        <Link onClick={(e) => this.gotoProfile(e, 1)}>
                          <img
                            src={Schedule}
                            alt="My Schedule"
                            title="My Schedule"
                          />
                          My Schedule
                        </Link>
                      </li>
                      <li>
                        <Link onClick={(e) => this.gotoProfile(e, 3)}>
                          <img src={Settings} alt="Settings" title="Settings" />
                          Settings
                        </Link>
                      </li> */}
                      <li>
                        <Link onClick={this.logout}>Sign out</Link>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              {
							SundanceNewUser ? 
							  <div className={`sd_user_profile_cart sd_flex ${this.props.isActive ? 'active' : ''}`}>
                		<div className="sd_calender">
                        <div className='sd_calender_img'>
                          <Link onClick={(e) => this.gotoProfile(e, 2)}>
                            <img src="/images/calendar.png" alt="calender" title="calender"/>
                          </Link>
                        </div>
                    </div>
                    
                    <div className="sd_cart_btn">
                        <button onClick={window.innerWidth <= 768 ? (e) => this.cartPage(e) : (e) => this.props.cartPage(e)}>
                          <img src={`${process.env.REACT_APP_IMAGE_PATH}cart_icon.svg`} alt="Cart" title="Cart" />
                          { 
                            // cartCount !== "0" && cartCount !== undefined 
                            // ? 
                            // <span className="sd_label sd_label_yellow"> {cartCount} </span>
                            // : ''

                              this.props.cartCount ?(
                                  <span className="sd_label sd_label_yellow">
                                      {this.props.cartCount}
                                      </span>
                                  ) : 
                                  ""
                          }	
								        </button>
                    </div>
  
                  <div class="sd_menu_search" onClick={() => this.props.isSearchOpen()}><img src="/images/search.png" alt="search" title="search"/></div>
							</div>
							: ''
						}
            </div>
          </div>
        )}
        {/* Nav Options */}
        <div className="sd_user_profile_menus sd_hidden">
          <ul>
            <li>
              <Link onClick={(e) => this.gotoProfile(e, 2)}>
                <img
                  src={Resources}
                  alt="My Purchases "
                  title="My Purchases    "
                />
                My Purchases
              </Link>
            </li>
            <li>
              <Link onClick={(e) => this.gotoProfile(e, 1)}>
                <img src={Schedule} alt="My Schedule" title="My Schedule" />
                My Schedule
              </Link>
            </li>
            <li>
              <Link onClick={(e) => this.gotoProfile(e, 3)}>
                <img src={Settings} alt="Settings" title="Settings" />
                Settings
              </Link>
            </li>
            <li>
              <Link onClick={this.logout}>Sign out</Link>
            </li>
          </ul>
          <div className="sd_user_profile_timezone sd_hidden">
            <div className="sd_user_timezone_time">
              <p>{this.getCurrentTime()} </p>
              <div className="sd_user_timezone_select">
                <p>{this.props.timezone}</p>
              </div>
            </div>
            <div className="sd_user_timezone_date">
              <span className="sd_textuppercase">
                {TimezoneConverter(moment(date),"MMMM")}
              </span>
              <h3>{TimezoneConverter(moment(date),"DD")}</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(UserProfile);
