import React, { Component } from "react";
import { Link } from "react-router-dom";
import service from "../../common/service";
//slider arrow
import Sliderarrow from "../../images/slider_arrow_right.svg";

// Slick Slider - Package
import Slider from "react-slick";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActCreators } from "../../store/bindActionCretor";

const mapStateToProps = (state) => ({
  sponsors: state.sponsors,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(ActCreators, dispatch);
};

class SponsorsLogo extends Component {
  constructor() {
    super();
    this.state = {
      sponsors: [],
      sponsors_title: [],
      current_sponsors_title: "",
    };
    this.apiserve = new service();
  }
  componentDidMount() {

    this.apiserve.sendevent("sponsor|getSponsor", {});

    this.apiserve.getevent().subscribe((response) => {
        switch (response.event) {
            case "sponsor|getSponsor":
                if (!response.error) {
                    // Set Sponsors Title
                    let sponsors_titles = [], sponsors = response.data;

                    sponsors && sponsors.sponsorArray && sponsors.sponsorArray.length > 0 && sponsors.sponsorArray.forEach(sponsor => {
                        if (sponsor.isVisible) {
                            sponsors_titles.push(sponsor.sponsorType);
                        }

                    });

                    this.setState({
                        sponsors: sponsors,
                        sponsors_title: sponsors_titles,
                        current_sponsors_title: sponsors_titles[0],
                        mobileSlider: sponsors.sponsorArray.mobile_images?.length,

                    });
                }
                else {
                }
                break;
            default:
                break;
        }
    });
}
getSponsorsMobile = () => {
  let { sponsors } = this.state;
        return sponsors && sponsors.sponsorArray && sponsors.sponsorArray.length > 0 && sponsors.sponsorArray.map((sponsor, i) => {

            if(sponsor.mobile_images.length > 0 && sponsor.isVisible)
            {
                return (<>
                    <div className="sd_mobile">
                        <p className="sd_sponsored_title">{sponsor.sponsorType}</p>
                    </div>
                    {sponsor.isVisible &&
                        <>
                            <div className="sd_logo_Mobile" key={i}>
                                <div className=" sd_img sd_flex sd_flexwrap sd_justbetween">
                                    {sponsor?.mobile_images?.map((mob, index) => {
                                        return (
                                            <div className="logo_img  sd_justcenter ">
                                                <img src={this.apiserve.imageUrl + mob.url} alt={sponsor.sponsorType} title={sponsor.sponsorType} />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </>
                    }
                </>)
            }
            else
                return false;
        })
}
  getSponsors = () => {
    const sponsors = this.state.sponsors;
    return (
      sponsors &&
      sponsors.sponsorArray &&
      sponsors.sponsorArray.length > 0 &&
      sponsors.sponsorArray.map((sponsor, i) => {
        if (sponsor.isVisible) {
          return (
            <>
              <div className="sd_sponsore_sec_d">
              <div>
                  <p className="sd_sponsored_title">{sponsor.sponsorType}</p>
                </div>
                <div className="sd_logo_sec_wpr" key={i}>
                  <img
                    src={this.apiserve.imageUrl + sponsor.image}
                    alt={sponsor.sponsorType}
                    title={sponsor.sponsorType}
                  />
                </div>
              </div>
            </>
          );
        } else {
          return false;
        }
      })
    );
  };
  next = () => {
       
    this.slider.slickNext();
  }
  previous = () => {
      this.slider.slickPrev();
  }
  render() {
    const HeaderSliderConfig = {
      dots: false,
      infinite: true,
      arrows: true,
      autoplay: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      beforeChange: (current, direction) => {
          this.setState({
              current_sponsors_title: this.state.sponsors_title[direction]
          });
      },
  };
    // Sponsor slider Configuration
    const SponsorSliderConfig = {
      dots: true,
      infinite: true,
      arrows: false,
      speed: 3000,
      autoplay: true,
      adaptiveHeight: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: (current) => {
        this.setState({
          current_sponsors_title: this.state.sponsors_title[current],
        });
      },
    };

    // Props
    const { slider } = this.props;
    let {  sponsors,current_sponsors_title } = this.state;
   
    return (
      <>
      <div className="sd_sponsored_section">
        <div className="sd_sponsored_inner">
          <div className="sd_inner_container sd_container sd_relative">
            <div className="sd_sponsored_inner_flex sd_flex sd_aligncenter sd_sponsore">
              {/* <p className="sd_sponsored_title">{current_sponsors_title}</p> */}
              {this.state.sponsors && sponsors.sponsorArray && sponsors.sponsorArray.length > 1 ? (
                <>
                  {/* <div className="sd_chat_with_us">
                      <div className="sd_inner_chat">
                          <span>Chat with Us</span>
                      </div>
                  </div> */}

                  <Slider className="sd_logo_section" {...SponsorSliderConfig}>
                    {this.getSponsors()}
                  </Slider>

                  <div className="sd_view_all_sponsors">
                    <Link to="/sponsors" className="sd_textuppercase">
                    View All sponsors
                      <img
                        src={Sliderarrow}
                        alt="view all the partners"
                        title="view all the partners"
                      />
                    </Link>
                  </div>
                </>
              ) : (
                <div className="sd_logo_sec_wpr">
                  <img
                    src="/village/images/sponsors_logo_images.png"
                    alt="Sponsors"
                    title="Sponsors"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="sd_hero1_slider_sec">
        <div className="sd_inner_container">
            <div className="sd_hero_header_inner relative">

              

                {
                    this.state.sponsors && sponsors.sponsorArray && sponsors.sponsorArray.length > 1 ?
                        <>
                    
                <div className="d_flex sd_justbetween">
                    <span className="arrow_left header_arrow pointer" onClick={this.previous}>
                        <img src="/images/slider_arrow_left.svg" alt="view all the sponsors" title="view all the sponsors" />
                    </span>
                    <span className="arrow_right header_arrow pointer" onClick={this.next}>
                        <img src="/images/slider_right.svg" alt="view all the sponsors" title="view all the sponsors" />
                    </span>
                </div> 
                            <Slider ref={c => (this.slider = c)} className="sd_hero_header_slider" {...HeaderSliderConfig}>
                                {
                                    this.getSponsorsMobile()
                                }
                            </Slider>
                            <div className="sd_view_all_sponsors">
                              <Link to="/sponsors" className="sd_textuppercase">
                                View All sponsors
                                <img
                                  src={Sliderarrow}
                                  alt="view all the partners"
                                  title="view all the partners"
                                />
                              </Link>
                            </div>
                        </>
                        :
                        this.getSponsorsMobile()
                }

            </div>
        </div>
      </div>
  </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SponsorsLogo);
