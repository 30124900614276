// import * as ActionCreators from './action'

// export const ActCreators = Object.assign({},
//     ActionCreators,
// );


import * as ActionCreators from './action'

export const ActCreators = Object.assign({},
    ActionCreators,
);