import React, { Component } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Sundance from "../images/sundanceimg.png";
import { TimezoneConverter } from "../store/functions";

class PartnerBlock extends Component {
  render() {
    // Props
    let { content } = this.props;
    return (
      <div className="sd_blogs_sec">
        <div className="sd_inner_blog">
          <div className="sd_PartnerBlock_tag ">
            <p>{content.state.partnerName}</p>
          </div>
          <div className="sd_blog_img">
            <Link to={content.link[1]}>
              <img
                src={
                  content.state.image && content.state.image != ""
                    ? process.env.REACT_APP_IMAGE_URL + content.state.image
                    : content.state.imageUrl != ""
                    ? content.state.imageUrl
                    : Sundance
                }
                alt="Partner"
                title="Partner"
              />
            </Link>
          </div>
          <div className="sd_blog_description">
            <Link to={{ pathname: content.link[1], state: content }}>
              <div className="sd_blog_content">
                <h2>{content.link[0]}</h2>
              </div>
            </Link>
            <div className="sd_blog_event">
              {content.state &&
                content.state.event &&
                content.state.event.map((card, i) => (
                  <div className="sd_Partner_event" key={i}>
                    <Link to={"/panel-view/" + card._id}>
                      {card && card.eventTitle ? (
                        <div className="event-title">{card.eventTitle}</div>
                      ) : (
                        <div className="event-title">{card.offerTitle}</div>
                      )}
                      <div className="right_arrow">
                        <img
                          src={`${process.env.REACT_APP_IMAGE_PATH}arrow_right.svg`}
                          alt="Next Arrow"
                          title="Next Arrow"
                        />
                      </div>
                    </Link>
                    {card.offerTitle && <p>All Festival</p>}
                    {card && card.eventDate == "" ? (
                      ""
                    ) : 
                      // card &&
                      // card.eventDate[0] &&
                      // card.eventDate[0].dateKey &&
                      // card.eventDate[0].dateKey != "" ? (
                      // <p>
                      //   {card && card.eventDate[0] && card.eventDate[0].dateKey}
                      // </p>
                      card.availableThroughoutFestival === true ? (
                      <p>
                        All Festival
                      </p>
                    ) : (
                      <>
                        <p>
                          {TimezoneConverter(moment.utc(
                            card && card.eventDate[0] && card.eventDate[0].date
                          ).local(),"MMMM")}{" "}
                          {TimezoneConverter(moment.utc(
                            card && card.eventDate[0] && card.eventDate[0].date
                          ).local(),"DD")}
                        </p>
                        <p>
                          {TimezoneConverter(moment
                            .utc(
                              card &&
                                card.eventDate[0] &&
                                card.eventDate[0].eventParticularTime[0].start
                            )
                            .local()
                            ,"hh:mm A ")}{" "}
                          -{" "}
                          {TimezoneConverter(moment
                            .utc(
                              card &&
                                card.eventDate[0] &&
                                card.eventDate[0].eventParticularTime[0].end
                            )
                            .local()
                           ,"hh:mm A")}
                        </p>
                      </>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PartnerBlock;
