import React, { Component } from "react";

// Video-React - Package
import "video-react/dist/video-react.css";
import {
  Player,
  ControlBar,
  BigPlayButton,
  PlayToggle,
  VolumeMenuButton,
} from "video-react";

class VideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoSrc: "",
      getTime: "",
      duration: "",
      player: "",
      currentTime: "",
      endvideo: "",
      isPause: false,
      playing: false,
      videoPause: false
    };
    this.load = this.load.bind(this);
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
  }

  load = () => {
    this.player.load();
  };

  play = () => {
    this.player.load();
    document.querySelectorAll('video').forEach(vid => vid.pause());
    this.player.play(() => {});
    this.setState({
      videoPause : false
    })
  };

  pause() {
    document.querySelectorAll('video').forEach(vid => vid.pause());
  }

  componentDidMount() {
    this.player.subscribeToStateChange(this.handleStateChange.bind(this));
  }

  handleStateChange(state, prevState) {
    if (!state.paused) {
      this.setState({
        playing: true,
      });
    }

    if(this.state.videoPause){
      this.setState({
        playing: false,
      });
      state.paused = true
    }
   
    this.setState({
      player: state,
      endvideo: state.ended,
      duration: state.duration,
      currentTime: state.currentTime,
      isPause: state.paused,
    });
  }


  render() {
    // Props
    let { autoplay, loop, thumbnail, isPause } = this.props;
    // State
    let { playing } = this.state;

    return (
      <>
        {!playing && thumbnail ? (
          <div className="sd_video_thumbnail">
            <img
              src={thumbnail}
              alt="Video Thumbnail"
              title="Video Thumbnail"
            />
          </div>
        ) : (
          ""
        )}

        <Player
          autoPlay={autoplay}
          muted={autoplay}
          loop={loop}
          ref={(player) => {
            this.player = player;
          }}
          autoPaused={true}
          onPause = {()=> {}}
        >
          <source src={this.props.videoSrc} />
          <BigPlayButton position="center" />
          <ControlBar autoHide={false} className="controls_custom">
            <PlayToggle />
            <VolumeMenuButton />
          </ControlBar>
          <button onClick={this.pause} className="videoPuausebutton">pause</button>
        </Player>
      </>
    );
  }
}

export default VideoPlayer;
