import React, { Component } from "react";
//components
import GeneralView from "../../components/GeneralView";
import PanelView from "../../components/PanelView";
import ScreeningView from "../../components/ScreeningView";
import OfferView from "../../components/OfferView";
// services
import service from "../../common/service";
import Cookies from "universal-cookie";

import FavoriteCard from "../../components/FavoriteCard";

const cookies = new Cookies();

let sub;
let timer = null;
class GeneralPanelScreeningPreview extends Component {
  constructor(props) {
    super(props);
    this.apiServe = new service();
    this.state = {
      panelData: "",
      frontData: "",
      frontDummy: "",
      viewType: null,
      loginPrompt: false,
    };
    document.querySelector("body").scrollTo({ top: 0, behavior: "smooth" });
    this.loginChild = React.createRef();
  }

  componentWillUnmount = () => {
    sub.unsubscribe();
    clearTimeout(timer);
  };
  componentDidMount() {

    // if (!this.props.match.params.id) this.props.history.push("/");
    // this.getHomeData();
    let particularUser =
      cookies.get("SundanceNewUser") || cookies.get("particularNewUser");

    if (particularUser && particularUser.token) {
      this.apiServe.sendevent("authentication", {
        token: particularUser.token,
      });
    }
    this.getPreview();

    sub = this.apiServe.getevent().subscribe((response) => {
      switch (response.event) {
        case "authentication":
          if (response.error) {
              let Zone = localStorage.getItem('TIMEZONE')
              localStorage.clear();
              if(Zone) localStorage.setItem('TIMEZONE' , Zone)
          } else {
            localStorage.setItem("loggedInUser", response.data.user.role);
            localStorage.setItem("myLoginToken", response.data.token);
            this.getProfile();
          }

          break;
        case "user|getProfile":
          if (response.error) {
          } else {
            if (localStorage.getItem("myLoginToken")) {
              let userInfo = {
                fname: response.data.fname,
                lname: response.data.lname,
                image: response.data.image,
                showMyLibrary: response.data.showMyLibrary,
              };
              localStorage.setItem("Header", JSON.stringify(userInfo));
            }
            this.setState({
              Header: true,
              code_conduct: response.data.COMM_AGMT_FLAG,
            });
            this.forceUpdate();
          }
          break;
        case "FestivalVillage|Event|getEventByIdFront":
          if (response.error) {
          } else {
            var selfFrontData = this.state.frontDummy;
            this.setState({
              frontData: {
                user: response.data.user,
                event: selfFrontData.event,
              },
            });
            
          }
          break;
        case "FestivalVillage|Event|Preview|getPreview":
          if (response.error) {
          } else {
            this.getPartnerDataFront(response.data.previewString);
            
          }
          break;
        default:
          break;
      }
    });
  }

  getProfile = () => {
    this.apiServe.sendevent("user|getProfile", {});
  };

  getPreview = () => {
    this.apiServe.sendevent("FestivalVillage|Event|Preview|getPreview", {});
  };

  getPartnerDataFront = (previewData) => {
    let data = this;
    let paramsData = this.apiServe.decrypt(previewData);
    var self = this;
    paramsData.then(function (val) {
      if (val) {
        self.setState({
          frontDummy: { event: val },
          frontData: { event: val },
          viewType: val.eventDisplayTemplate,
        });
        data.apiServe.sendevent("FestivalVillage|Event|getEventByIdFront", {
          id: val.id,
        });
      }
    });

    // if (this.props.location.search) {
    //   var paramsData1 = this.props.location.search.replace("?data=", "");
    //   let paramsData = this.apiServe.decrypt(paramsData1);
    //   var self = this;
    //   paramsData.then(function (val) {

    //     if (val) {
    //       self.setState({
    //         frontDummy: { event: val },
    //         frontData: { event: val },
    //         viewType: val.eventDisplayTemplate,
    //       });
    //       data.apiServe.sendevent("FestivalVillage|Event|getEventByIdFront", {
    //         id: val.id,
    //       });
    //     }
    //   });
    // }
  };

  checkuserLogin = () => {
    this.setState({
      loginPrompt: true,
    });
  };

  removePopup = () => {
    this.setState({
      loginPrompt: false,
    });
  };

  render() {
    const { panelData, viewType, frontData } = this.state;
    return (
      <>
        <section>
          {viewType == "Offer" ? (
            <OfferView
              frontData={frontData}
              isuserLogin={this.checkuserLogin}
            />
          ) : viewType == "Panel" ? (
            <PanelView
              frontData={frontData}
              isuserLogin={this.checkuserLogin}
            />
          ) : viewType == "Screening" ? (
            <ScreeningView
              frontData={frontData}
              isuserLogin={this.checkuserLogin}
            />
          ) : viewType == "General" ? (
            <GeneralView
              frontData={frontData}
              isuserLogin={this.checkuserLogin}
            />
          ) : null}

          {this.state.loginPrompt && (
            <FavoriteCard userLogin={this.removePopup} />
          )}
        </section>
      </>
    );
  }
}

export default GeneralPanelScreeningPreview;
