import React, { Component } from "react";

export default class InputField extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      placeholder,
      label,
      type,
      name,
      value,
      disable,
      require,
      readonly,
      required,
      onBlur,
      pattern,
    } = this.props;
    return (
      <div className="field_row_inner">
        {label ? (
          <label>
            {label} {require ? <sup>*</sup> : <sup></sup>}{" "}
            {required ? (
              <sup>
                <img
                  src="/village/images/asterisk_icon.svg"
                  alt="Required"
                  title="Required"
                />
              </sup>
            ) : (
              ""
            )}
          </label>
        ) : (
          ""
        )}
        <div className="field_input relative">
          <input
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            disabled={disable}
            pattern={pattern}
            readOnly={readonly}
            onChange={this.props.onChange}
            onBlur={onBlur}
          />
        </div>
      </div>
    );
  }
}
