import React, { Component } from 'react';

class CheckBox extends Component {
    
    render() {
        // Props
        const{label, name, id, checked, value, disabled} = this.props;

        return (            
            <div className="sd_filter_checkbox">
                <input 
                    type="checkbox" 
                    id={id} 
                    name={name}
                    checked={checked}
                    disabled={disabled}
                    value={value}
                    onChange={(e) => this.props.onChange(e)}
                />
                <label htmlFor={id}>{label}</label>

            </div>
        )
    }
}

export default CheckBox;