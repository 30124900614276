import React, { Component } from "react";

//Loader
import LoaderSvg from "../common/images/loader.svg";

class Loader extends Component {
  render() {
    return (
      <div id="pre-loader" className="sd_loader" style={{ display: "flex" }}>
        <img alt="" src={LoaderSvg} />
      </div>
    );
  }
}

export default Loader;

