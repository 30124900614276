import React, { Component, createRef } from "react";
// custom components
import Slider from "react-slick";
// services
import service from "../common/service";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import moment from "moment";
//img
import Plus from "../images/plus.png";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import Righticon from "../images/tick-black-icon.png";
import Loader from "../components/loader/loader";
import FavoriteCard from "../components/FavoriteCard";
import SwiperCore, { Navigation, Mousewheel, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.scss";
import $ from "jquery";

const cookies = new Cookies();

SwiperCore.use([Navigation, Mousewheel, Scrollbar, A11y]);

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1366,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

let sub;
let timer = null;

class ArtistEventBlock extends Component {
  constructor(props) {
    super(props);
    this.apiServe = new service();
    this.state = {
      activePage: 1,
      homeData: "",
      allEvent: "",
      heroHeader: "",
      heroBanner: true,
      listItems: false,
      ArtistEvent: "",
      ArtistAllOfferEvent: "",
      DailySpecialOffer:'',
      loginPrompt: false,
    };
    this.next_medium_1 = this.next_medium_1.bind(this);
    this.previous_medium_1 = this.previous_medium_1.bind(this);
    this.next_small_1 = this.next_small_1.bind(this);
    this.previous_small_1 = this.previous_small_1.bind(this);
    this.next_small_2 = this.next_small_2.bind(this);
    this.previous_small_2 = this.previous_small_2.bind(this);
    this.next_small_3 = this.next_small_3.bind(this);
    this.previous_small_3 = this.previous_small_3.bind(this);
    this.next_small_4 = this.next_small_4.bind(this);
    this.previous_small_4 = this.previous_small_4.bind(this);

    this.prevRef = createRef();
    this.nextRef = createRef();
  }

  next_medium_1() {
    this.slider1.slickNext();
  }
  previous_medium_1() {
    this.slider1.slickPrev();
  }
  next_small_1() {
    this.small_slider_1.slickNext();
  }
  previous_small_1() {
    this.small_slider_1.slickPrev();
  }
  next_small_2() {
    this.small_slider_2.slickNext();
  }
  previous_small_2() {
    this.small_slider_2.slickPrev();
  }
  next_small_3() {
    this.small_slider_3.slickNext();
  }
  previous_small_3() {
    this.small_slider_3.slickPrev();
  }
  next_small_4() {
    this.small_slider_4.slickNext();
  }
  previous_small_4() {
    this.small_slider_4.slickPrev();
  }
  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateDimensions);
    sub.unsubscribe();
    clearTimeout(timer);
  };
  getProfile = () => {
    this.apiServe.sendevent("user|getProfile", {});
  };
  getArtistEvent = () => {
    this.apiServe.sendevent("FestivalVillage|ArtistLounge|getArtistEvent", {
      limit: 10,
      page: 1,
    });
  };
  getArtistAllOfferEvent = () => {
    this.apiServe.sendevent("FestivalVillage|Event|getAllOfferEvent", {
      limit: 10,
      page: 1,
    });
  };
  getDailySpecialOffer = () => {
    this.apiServe.sendevent("FestivalVillage|DailySpecialOfferEvent|get", {});
  };
  removePopup = () => {
    this.setState({
      loginPrompt: false,
    });
  };
  getAddScheduleEvent = (cardid, cardDateTime, cardTemplate) => {
    let particularUser =
      cookies.get("SundanceNewUser") || cookies.get("particularNewUser");
    if (!particularUser) {
      return this.setState({
        loginPrompt: true,
      });
    }

    this.setState({
      [cardid]: !this.state[cardid],
    });

    this.apiServe.sendevent("FestivalVillage|Event|addToFavourite", {
      eventId: cardid,
      date: cardDateTime,
      eventType: cardTemplate,
    });
  };
  getRemoveScheduleEvent = (cardid, cardDateTime) => {
    this.setState({
      [cardid]: !this.state[cardid],
    });
    this.apiServe.sendevent("FestivalVillage|Event|removeFavourite", {
      eventId: cardid,
      date: cardDateTime,
    });
  };
  componentDidMount = () => {
    let particularUser =
      cookies.get("SundanceNewUser") || cookies.get("particularNewUser");

    if (particularUser && particularUser.token) {
      this.apiServe.sendevent("authentication", {
        token: particularUser.token,
      });
    }
    this.getArtistEvent();
    this.getArtistAllOfferEvent();
    this.getDailySpecialOffer();
    sub = this.apiServe.getevent().subscribe((response) => {
      switch (response.event) {
        case "authentication":
          if (response.error) {
              let Zone = localStorage.getItem('TIMEZONE')
                localStorage.clear();
              if(Zone) localStorage.setItem('TIMEZONE' , Zone)
          } else {
            localStorage.setItem("loggedInUser", response.data.user.role);
            localStorage.setItem("myLoginToken", response.data.token);
            this.getProfile();
          }
          break;
        case "user|getProfile":
          if (response.error) {
          } else {
            if (localStorage.getItem("myLoginToken")) {
              let userInfo = {
                fname: response.data.fname,
                lname: response.data.lname,
                image: response.data.image,
              };
              localStorage.setItem("Header", JSON.stringify(userInfo));
            }
            this.setState({
              Header: true,
              code_conduct: response.data.COMM_AGMT_FLAG,
            });
            this.forceUpdate();
            this.getArtistEvent();
          }
          break;
        case "FestivalVillage|ArtistLounge|getArtistEvent":
          if (response.error) {
          } else {
            this.setState({
              ArtistEvent: response.data,
            });
            response.data.docs.forEach((element) => {
              if (element.isFavourite) {
                this.setState({
                  [element._id]: element.isFavourite,
                });
              }
            });
          }
          break;
        case "FestivalVillage|Event|getAllOfferEvent":
          if (response.error) {
          } else {
            this.setState({
              ArtistAllOfferEvent: response.data,
            });
          }
          break;
          case "FestivalVillage|DailySpecialOfferEvent|get":
          if (response.error) {
          } else {
            this.setState({
              DailySpecialOffer: response.data,
            });
          }
          break;
        case "FestivalVillage|Event|addToFavourite":
          if (response.error) {
          } else {
          }
          break;
        case "FestivalVillage|Event|removeFavourite":
          if (response.error) {
          } else {
          }
          break;
        default:
          break;
      }
    });
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  };

  updateDimensions() {
    var windowWidth = window.innerWidth;
    var containerWidth = 1680;
    if (windowWidth > containerWidth) {
      var marginSpace = (windowWidth - containerWidth) / 2;
      var ele = document.getElementById("sd_container");
      ele.setAttribute("style", "margin-left: " + marginSpace + "px");
      if (windowWidth > containerWidth) {
        var marginSpace = (windowWidth - containerWidth) / 2;
        var ele = document.getElementById("sd_container");
        ele.setAttribute("style", "margin-left: " + marginSpace + "px");
      }
    }
  }
  checkisIframe(url) {
    var expVimeo = new RegExp(/(vimeo\.com)/);
    var expYoutube = new RegExp(/(youtu\.be|youtube\.com)/);
    var a = url.match(
      "/http://(?:www.)?(?:(vimeo).com/(.*)|(youtube).com/watch?v=(.*?)&)/"
    );
    if (expVimeo || expYoutube) {
      return true;
    } else {
      return false;
    }
  }
  render() {
    const { homeData, ArtistEvent, ArtistAllOfferEvent ,DailySpecialOffer} = this.state;
    return (
      <>
        {/* EVENTS FOR ARTISTS */}
        <section className="sd_medium_card sd_medium_card_home sd_medium_border">
          <div className="sd_home_slider slider-artist">
            <div id="sd_container">
              <div className="sd_slider_title">
                <h2 className="sd_textuppercase">EVENTS FOR ARTISTS</h2>
                <div className="sd_slider_arrows">
                  <button className="button " ref={this.prevRef}>
                    <img
                      src="/village/images/arrow_left.svg"
                      alt="Prev Arrow"
                      title="Prev Arrow"
                    />
                  </button>
                  <button className="button " ref={this.nextRef}>
                    <img
                      src="/village/images/arrow_right.svg"
                      alt="Next Arrow"
                      title="Next Arrow"
                    />
                  </button>
                </div>
              </div>
              <div className="sd_slider">
                {ArtistEvent && ArtistEvent.docs && (
                  <Swiper
                    spaceBetween={0}
                    slidesPerView={"auto"}
                    freeMode={true}
                  
                    onSlideChange={() => {}}
                    onSwiper={(swiper) => {}}
                    navigation={{
                      nextEl: this.nextRef.current,
                      prevEl: this.prevRef.current,
                    }}
                  >
                    {ArtistEvent.docs.map((card, i) => (
                      <SwiperSlide>
                        <div className="slider-images">
                          <div className="sd_slider_img">
                            <img
                              src={
                                card &&
                                card.keyEventThumbnailImage[0] &&
                                card.keyEventThumbnailImage[0].link != ""
                                  ? `${process.env.REACT_APP_IMAGE_URL}` +
                                    card.keyEventThumbnailImage[0].link
                                  : card.keyEventThumbnailImage[0].url
                              }
                            />
                          </div>
                          <div className="content_slider">
                            {card && card.eventDisplayTemplate == "Offer" ? (
                              <h2
                                className={
                                  card.eventDate == ""
                                    ? "sd_eventdate_space"
                                    : "artist_heading"
                                }
                              >
                                {" "}
                                {card && card.offerTitle}
                              </h2>
                            ) : (
                              <h2
                                className={
                                  card.eventDate == ""
                                    ? "sd_eventdate_space"
                                    : "artist_heading"
                                }
                              >
                                {card && card.eventTitle}
                              </h2>
                            )}
                            {card &&
                            card.eventDisplayTemplate == "Offer" &&
                            card.eventDate == "" ? (
                              <span>All Festival</span>
                            ) : card && card.eventDate == "" ? (
                              ""
                            ) : card &&
                              card.availableThroughoutFestival == true ? (
                              <span className="sd_time_span">
                                {card.eventDate[0] && card.eventDate[0].dateKey}
                                {this.state[card._id] ? (
                                  <button
                                    className="sd_textuppercase"
                                    onClick={() =>
                                      this.getRemoveScheduleEvent(
                                        card._id,
                                        card.eventDate[0]
                                          ? card.eventDate[0]
                                              .eventParticularTime[0].start
                                          : ""
                                      )
                                    }
                                  >
                                    <img
                                      src={Righticon}
                                      alt="plus"
                                      className="home-right-btn"
                                    />
                                  </button>
                                ) : (
                                  <button
                                    onClick={() =>
                                      this.getAddScheduleEvent(
                                        card._id,
                                        card.eventDate[0]
                                          ? card.eventDate[0]
                                              .eventParticularTime[0].start
                                          : "",
                                        card.eventDisplayTemplate
                                      )
                                    }
                                  >
                                    <img src={Plus} alt="Plus" />
                                  </button>
                                )}
                              </span>
                            ) : (
                              <span className="sd_time_span sd_artist_button">
                                {moment
                                  .utc(
                                    card.eventDate[0] &&
                                    card.eventDate[0]
                                    .date
                                  )
                                  .local()
                                  .format("MMM DD,")} {" "}
                                {moment
                                  .utc(
                                    card.eventDate[0] &&
                                      card.eventDate[0].eventParticularTime[0]
                                        .start
                                  )
                                  .local()
                                  .format("hh:mm A ")}{" "}
                                -{" "}
                                {moment
                                  .utc(
                                    card.eventDate[0] &&
                                      card.eventDate[0].eventParticularTime[0]
                                        .end
                                  )
                                  .local()
                                  .format("hh:mm A")}
                                {this.state[card._id] ? (
                                  <button
                                    className="sd_textuppercase"
                                    onClick={() =>
                                      this.getRemoveScheduleEvent(
                                        card._id,
                                        card.eventDate[0]
                                          ? card.eventDate[0]
                                              .eventParticularTime[0].start
                                          : ""
                                      )
                                    }
                                  >
                                    <img
                                      src={Righticon}
                                      alt="plus"
                                      className="home-right-btn"
                                    />
                                  </button>
                                ) : (
                                  <button
                                    onClick={() =>
                                      this.getAddScheduleEvent(
                                        card._id,
                                        card.eventDate[0]
                                          ? card.eventDate[0]
                                              .eventParticularTime[0].start
                                          : "",
                                        card.eventDisplayTemplate
                                      )
                                    }
                                  >
                                    <img src={Plus} alt="Plus" />
                                  </button>
                                )}
                              </span>
                            )}
                            {card.eventDisplayTemplate == "Offer" ? (
                              <p
                                className="paragraph_span_16"
                                dangerouslySetInnerHTML={{
                                  __html: card.offerDescription,
                                }}
                              />
                            ) : (
                              <p
                                className="paragraph_span_16"
                                dangerouslySetInnerHTML={{
                                  __html: card.eventDescription,
                                }}
                              />
                            )}
                            <Link
                              to={"/panel-view/" + card._id}
                              className="sd_textcapitalize"
                            >
                              Learn More
                              <div className="sd_arrow">
                                <img
                                  src="/village/images/arrow_right.svg"
                                  alt="Next Arrow"
                                  title="Next Arrow"
                                />
                              </div>
                            </Link>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
              </div>
            </div>
          </div>
        </section>

        {this.state.loginPrompt && (
          <FavoriteCard userLogin={this.removePopup} />
        )}
        {/* Daily Specials */}
        <section className="sd_artists_daily_data">
          <div className="sd_artists_main">
            <div className="sd_artist_container">
              <div className="sd_artists_daily_data_header">
                <h2>Daily Specials</h2>
                <p>Featured offerings and events from our partners</p>
              </div>
              <div className="sd_artists_daily_block">
                {DailySpecialOffer && DailySpecialOffer.dailySpecialEvent &&
                DailySpecialOffer.dailySpecialEvent.map((block, i) => (
                    <div className="sd_artists_daily_block_inner">
                      <div className="sd_artists_block_images">
                        {block.keyEventThumbnailImage ==
                        "" ? null : 
                        block.keyEventThumbnailImage &&
                          block.keyEventThumbnailImage[0].link == "" &&
                          block.keyEventThumbnailImage[0].url == "" ? (
                          ""
                        ) : (
                          <img
                            src={
                              block.keyEventThumbnailImage &&
                              block.keyEventThumbnailImage[0].link != ""
                                ? `${process.env.REACT_APP_CLOUD_FRONT}` +
                                  block.keyEventThumbnailImage[0].link
                                : block.keyEventThumbnailImage[0].url
                            }
                            alt="artists"
                          />
                        )}
                      </div>
                      <div className="sd_artists_block_description">
                        <h2>
                          <div className="offer-title">{block.offerTitle}</div>
                        </h2>
                        <p
                          className="artist_data"
                          dangerouslySetInnerHTML={{
                            __html: block.offerDescription,
                          }}
                        />
                        <Link to={"/offer-view/" + block._id}>
                          <div className="offer-title">Learn More</div>
                          <div className="sd_arrow">
                            <img
                              src="/village/images/arrow_right.svg"
                              alt="Next Arrow"
                              title="Next Arrow"
                            />
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default ArtistEventBlock;
