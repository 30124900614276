import React, { Component } from 'react'
import service from "../common/service";
import remove from '../images/remove.svg'
// DatePicker - Package
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// Components
import CheckBox from "../components/CheckBox";
// import Tooltip from './Tooltip';

import { connect } from 'react-redux'

const mapStateToProps = state => ({
    filters: state.filters,
    appliedFullNewVillage: state.appliedFullNewVillage,
    appliedNewVillage: state.appliedNewVillage
})
let accessibilityData = [
    { _id: "1", name: "In-Person" },
    { _id: "2", name: " ONLINE" },
]

class Filters extends Component {

    constructor() {
        super();
        this.state = {
            startDate: new Date(),
            endDate: new Date(),
            startTime: '',
            endTime: '',
            isActive: false,
            filters: {},
            isFilterOpen: false,
            filterType: ''
        };
        this.apiserve = new service();
    }

    componentDidMount() {
        // debugger
        this.filterData();
    }


    filterData = () => {
        this.setState({
            filters: this.props.filters.festivalVillage
        })
    }

    getFilterList = (e, type) => {
        if (this.state.filterType === type && this.state.isFilterOpen)
            this.setState({ isFilterOpen: false, filterType: '' })
        else {
            this.setState({ isFilterOpen: true, filterType: type })
        }

    }

    handleChange = (e) => {
        //debugger
        this.props.filterData(e)
    }

    changeCategory = (id, name) => {
        //debugger
        let STATE_TYPE = this.props.appliedNewVillage[name.toString()]
        return STATE_TYPE ? STATE_TYPE.includes(id) : false
    }

    selectDate = (name) => {
        const page = this.props.filtersFor;
        var TYPE = this.props.appliedNewVillage[name]
        return TYPE === "" ? name === 'startDate' || name === 'endDate' ? name === 'startDate' ? 
        new Date('2022-01-20') < new Date('January 20,2022') ? new Date('2022-01-21') :new Date('2022-01-20') : new Date('2022-01-30') < new Date('January 30,2022') ? new Date('2022-01-31') :new Date('2022-01-30') : null : new Date(TYPE)
    }

    handleDateChange = (date, name) => { this.props.handleDateChange(date, name) }

    resetFilters = () => { this.props.clearFilters() }

    deleteFilters = (filter) => {

        const page = this.props.filtersFor;
        this.props.dispatch({
            type: 'REMOVE_PARTNERFILTER_FILTERS', payload: { id: filter.id, filterType: filter.filterType }
        })
    }

    resetDateFilter = (DATE1, DATE2) => {
        if (DATE1) this.props.dispatch({ type: `REMOVE_DATE_TIME_FILTERS`, payload: { filterType: DATE1 } })
        if (DATE2) this.props.dispatch({ type: `REMOVE_DATE_TIME_FILTERS`, payload: { filterType: DATE2 } })
    }

    getSnapshotBeforeUpdate = (prevProps) => {

        const page = this.props.filtersFor
        if (prevProps.appliedNewVillage !== this.props.appliedNewVillage) {
            return { snapAppliedNewVillage: prevProps.appliedNewVillage !== this.props.appliedNewVillage }
        }
        if (prevProps.filters !== this.props.filters) {
            return {snapFilters : prevProps.filters !== this.props.filters}
          }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (snapshot && snapshot.snapFilters !== undefined) {
            this.filterData();
        }

        if (snapshot && (snapshot.snapAppliedNewVillage !== undefined )) {
            this.updateAppliedFilters();
        }
    }

    updateAppliedFilters = () => {
        let appliedFilters = []
        let appliedDateFilters = []

        const page = this.props.filtersFor;
        appliedFilters = this.props.appliedFullNewVillage
        appliedDateFilters = page === 'Film' ?
            this.props.appliedFilm : page === 'Btf' ? this.props.appliedBeyondFilm
                : page === 'NewFT' ? this.props.appliedNewVillage : page === 'partner' ? this.props.appliedPartnersEvent : []

        let emptyArr = []
        // FILTERS FOR OTHER FILTERS

        if (appliedFilters && appliedFilters.length > 0) {
            appliedFilters && appliedFilters.forEach(filter => { emptyArr.push(<span className="sd_flex"> {filter.name}  <img onClick={() => this.deleteFilters(filter)} src={remove} alt="delete category" /></span>) });
        }
        //  

        // FILTERS FOR DATE
        if (appliedDateFilters['startDate'] !== '' && appliedDateFilters['endDate'] !== '')
            emptyArr.push(this.getFilteDesign(appliedDateFilters, 'startDate', 'MMM. DD', 'endDate'))

        else if (appliedDateFilters['startDate'] !== '' && appliedDateFilters['endDate'] === '')
            emptyArr.push(this.getFilteDesign(appliedDateFilters, 'startDate', 'MMM. DD'))

        else if (appliedDateFilters['startDate'] === '' && appliedDateFilters['endDate'] !== '')
            emptyArr.push(this.getFilteDesign(appliedDateFilters, 'endDate', 'MMM. DD'))


        // FILTERS FOR TIME
        if (appliedDateFilters['startTime'] !== '' && appliedDateFilters['endTime'] !== '')
            emptyArr.push(this.getFilteDesign(appliedDateFilters, 'startTime', 'hh:mm A', 'endTime'))

        else if (appliedDateFilters['startTime'] !== '' && appliedDateFilters['endTime'] === '')
            emptyArr.push(this.getFilteDesign(appliedDateFilters, 'startTime', 'hh:mm A'))

        else if (appliedDateFilters['startTime'] === '' && appliedDateFilters['endTime'] !== '')
            emptyArr.push(this.getFilteDesign(appliedDateFilters, 'endTime', 'hh:mm A'))
        return emptyArr;

    }

    getFilteDesign = (FILTERS, FOR, FORMAT, PARA1) => {
        return (
            <span className="sd_flex"> {moment(FILTERS[FOR]).format(FORMAT)}{PARA1 ? ' - ' + moment(FILTERS[PARA1]).format(FORMAT) : null} <img onClick={() => this.resetDateFilter(FOR, PARA1 ? PARA1 : null)} src={remove} alt="delete category" /></span>
        )
    }

    HideFilterDropdown = () => {
        this.setState({
            isFilterOpen: false,
            filterType: ''
        })
    }

    // Date Range Next Prev
    handleDateOperation = (OPERATION, DATE_TYPE) => {

        let DateOperation = {
            operation: OPERATION,
            dateType: DATE_TYPE,
            page: this.props.filtersFor
        }
        this.props.dispatch({ type: 'HANDLE_DATE_OPERATION', payload: { DateOperation } })
    }

    handleDate = (date) => {
        this.setState({ startDate: date })
    }

    checkIsLastDate = (OPERATION, DATE_TYPE) => {
// debugger
        if (OPERATION === 'add') {
            if (this.selectDate(DATE_TYPE) > new Date('2022-01-30T00:00:00-00:00')){
                // debugger
                return false
            }
            else{
                // debugger
                return true
            }
        }
        else if (OPERATION === 'subtract') {
            if (this.selectDate(DATE_TYPE) <= new Date('2022-01-01T00:00:00-00:00'))
                return false
            else
                return true
        }
    }

    render() {
        const { isActive } = this.props;
        var filters = this.state.filters;

        return (
            <React.Fragment>
                {this.state.isFilterOpen ? <span className="filter_overlay" onClick={this.HideFilterDropdown}> </span> : ''}
                <div className="sd_inner_container">
                    <div className="sd_flex lg_flex_wrap">
                        <div className="sd_filters_options_wpr ">
                            <div className="sd_filters_reset_btn">
                                <button className="sd_textuppercase mobile_hide" onClick={this.resetFilters}> <i>Reset Filters</i></button>
                                <div className="sd_cat_label sd_flex sd_flexwrap ">
                                    {this.updateAppliedFilters()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`sd_filters_wpr  ${isActive ? 'sd_filters_active' : ''}`}>

                        <div className="sd_filters_wpr_inner">
                            <div className="sd_filters_reset_btn sd_filters_reset_btn_popup">
                                <button className="sd_textuppercase mobile_hide" onClick={this.resetFilters}> <i>Reset Filters</i></button>
                            </div>
                            {/* Close Filter */}
                            <div className="btn-popup">
                                <button className="sd_close_filter" onClick={this.props.onChildClick}>
                                    <span></span>
                                    <span></span>
                                </button>
                            </div>
                            {/* Filters */}
                            <div className="">

                                <div className="sd_filters_sec">
                                    <div className="sd_filters_options_wpr">
                                        <div className="sd_flex lg_flex_wrap">
                                                    <div className="sd_filters_options_sec">
                                                        <div className="sd_filters_options_block">
                                                            <div className={`sd_filters_options_header ${this.state.isFilterOpen && this.state.filterType === 'users' ? 'sd_active' : ''}`}>
                                                                <h4 onClick={(e) => this.getFilterList(e, 'users')}>
                                                                    Partner Name

                                                                    <span className="sd_filter_click"><img src={`${process.env.REACT_APP_IMAGE_PATH}arrow_up_light.svg`} alt="Arrow" title="Arrow" /></span>
                                                                </h4>
                                                            </div>

                                                            <div className={`sd_filters_options ${this.state.isFilterOpen && this.state.filterType === 'users' ? 'sd_active' : ''}`}>

                                                                {
                                                                    filters && filters?.partneruser?.map(filter => {
                                                                        return (<CheckBox name='users' checked={this.changeCategory(filter._id, 'users')} label={filter.partnerName ? filter.partnerName : filter.satelliteScreenName} value={filter.partnerName ? filter.partnerName : filter.satelliteScreenName} id={filter._id} onChange={this.handleChange} />)
                                                                    })
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                   
                                    

                                            <div className="sd_filters_options_sec">
                                                <div className="sd_filters_options_block">
                                                    <div className={`sd_filters_options_header ${this.state.isFilterOpen && this.state.filterType === 'accessibility' ? 'sd_active data7' : ''}`}>
                                                        <h4 onClick={(e) => this.getFilterList(e, 'accessibility')}>
                                                            Availability

                                                            <span className="sd_filter_click"><img src={`${process.env.REACT_APP_IMAGE_PATH}arrow_up_light.svg`} alt="Arrow" title="Arrow" /></span>
                                                        </h4>
                                                    </div>

                                                    <div className={`sd_filters_options ${this.state.isFilterOpen && this.state.filterType === 'accessibility' ? 'sd_active data7' : ''}`}>
                                                        {
                                                            accessibilityData && accessibilityData?.map(filter => {
                                                          
                                                                return (<CheckBox name='accessibility' checked={this.changeCategory(filter._id, 'accessibility')} label={filter.name} value={filter.name} id={filter._id} onChange={this.handleChange} />)
                                                            })
                                                        }

                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="sd_date_filter">
                                        <div className="sd_filters_day">
                                            <div className="sd_filters_day_sec sd_filter_by_day">
                                                <div className="date_filter_title_wrap" >
                                                    <h3 className="date_filter_title">Filter by Date & Time</h3>
                                                </div>
                                                <div className="sd_filters_day_wpr">
                                                    <div className="sd_filters_date_picker sd_filters_date_from">
                                                        <DatePicker
                                                            selected={this.selectDate('startDate')}
                                                            onChange={(e) => this.handleDateChange(e, 'startDate')}
                                                            selectsStart
                                                            dateFormat='EEE,MMM d'
                                                            // minDate={new Date('2022-01-01T00:00:00-00:00')}
                                                            // maxDate={new Date('2022-01-31T00:00:00-00:00')}
                                                            // minDate={moment().toDate()}
                                                        />
                                                        <span className="sd_cal_icon">
                                                            <img src={`${process.env.REACT_APP_IMAGE_PATH}calendar_icon.svg`} alt="calendar" />
                                                        </span>
                                                        <div className="sd_date_arrow">
                                                            <div className="left_arrow" onClick={() => this.checkIsLastDate('subtract', 'startDate') ? this.handleDateOperation('subtract', 'startDate') : ''}>
                                                                <img style={{ cursor: this.checkIsLastDate('subtract', 'startDate') ? 'pointer' : 'not-allowed' }} src={`${process.env.REACT_APP_IMAGE_PATH}arrow_prev.svg`} alt="left arrow" title="left arrow" />
                                                            </div>
                                                            <div className="right_arrow" onClick={() => this.checkIsLastDate('add', 'startDate') ? this.handleDateOperation('add', 'startDate') : ''}>
                                                                <img style={{ cursor: this.checkIsLastDate('add', 'startDate') ? 'pointer' : 'not-allowed' }} src={`${process.env.REACT_APP_IMAGE_PATH}arrow_next.svg`} alt="left arrow" title="right arrow" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="sd_filters_date_picker sd_filters_date_to">
                                                        <DatePicker
                                                            selected={this.selectDate('endDate')}
                                                            onChange={(e) => this.handleDateChange(e, 'endDate')}
                                                            selectsEnd
                                                            dateFormat='EEE,MMM d'
                                                            // minDate={new Date('2022-01-01T00:00:00-00:00')}
                                                            // maxDate={new Date('2022-01-31T00:00:00-00:00')}
                                                            // minDate={moment().toDate()}
                                                        />
                                                        <div className="sd_date_arrow">
                                                            <div className="left_arrow" onClick={() => this.checkIsLastDate('subtract', 'endDate') ? this.handleDateOperation('subtract', 'endDate') : ''} >
                                                                <img style={{ cursor: this.checkIsLastDate('subtract', 'endDate') ? 'pointer' : 'not-allowed' }} src={`${process.env.REACT_APP_IMAGE_PATH}arrow_prev.svg`} alt="left arrow" title="left arrow" />
                                                            </div>

                                                            <div className="right_arrow" onClick={() => this.checkIsLastDate('add', 'endDate') ? this.handleDateOperation('add', 'endDate') : ''}>
                                                                <img style={{ cursor: this.checkIsLastDate('add', 'endDate') ? 'pointer' : 'not-allowed' }} src={`${process.env.REACT_APP_IMAGE_PATH}arrow_next.svg`} alt="left arrow" title="right arrow" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="sd_filters_day_sec sd_filter_by_time">
                                                        <div className="sd_filters_day_wpr">
                                                            <div className="sd_filters_date_picker sd_filters_time_from">
                                                                <DatePicker

                                                                    selected={this.selectDate('startTime')}
                                                                    onChange={(e) => this.handleDateChange(e, 'startTime')}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    selectsStart
                                                                    dateFormat="h:mm aa"
                                                                    placeholderText="HH:MM"
                                                                    openToDate={new Date(new Date().setHours(24, 0))}
                                                                />
                                                            </div>
                                                            <p className="sd_to_devide"> To </p>
                                                            <div className="sd_filters_date_picker sd_filters_time_to">
                                                                <DatePicker
                                                                    selected={this.selectDate('endTime')}
                                                                    onChange={(e) => this.handleDateChange(e, 'endTime')}
                                                                    showTimeSelect
                                                                    showTimeSelectOnly
                                                                    selectsEnd
                                                                    dateFormat="h:mm aa"
                                                                    placeholderText="HH:MM"
                                                                    popperPlacement={`${window.innerWidth <= 576 ? 'top-end' : ''}`}
                                                                    openToDate={new Date(new Date().setHours(24, 0))}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                      
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sd_Filter_by_Interest sd_filter_sec">
                                    <div className="sd_container">
                                        <div className="sd_Interest_filter_title" >
                                            <h3 className="Interest_filter_title">Filter by Interest</h3>
                                        </div>
                                        <div className="sd_main_category sd_flex sd_flexwrap" >
                                            {
                                                filters && filters.interest && filters.interest.length > 0 &&
                                                (
                                                    filters && filters.interest.map((filter, i) => (
                                                        <div className="sd_inner_category sd_flex sd_aligncenter active">
                                                            <div className="sd_filter_icon">
                                                                <img
                                                                    src={filter.icon ? this.apiserve.imageUrl + filter.icon :`${process.env.REACT_APP_IMAGE_PATH}interest_icon.svg`}
                                                                    alt="Next Arrow"
                                                                    title={filter.name}
                                                                />
                                                            </div>
                                                            <div className="sd_filter_title">
                                                                <CheckBox name='interest' checked={this.changeCategory(filter._id, 'interest')} label={filter.name} value={filter.name} id={filter._id} onChange={this.handleChange} />
                                                                {/* <span>Accessibility</span> */}
                                                            </div>
                                                        </div>
                                                    ))
                                                )

                                            }

                                        </div>
                                    </div>
                                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, null)(Filters)