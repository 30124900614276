import React, { useState, useEffect } from 'react';
import service from "../common/service";

// Layouts
import Footer from "../layout/footer";
import Header from "../layout/header";

import { useSelector } from 'react-redux'
import ReactPaginate from 'react-paginate';
import { Link, useParams, useLocation } from 'react-router-dom';
import Films from './SearchResults/Films';
import TalksEvents from './SearchResults/TalksEvents';
import NewFrontier from './SearchResults/NewFrontier';
import PartnerEvents from './SearchResults/PartnerEvents';
import FestivalVillage from './SearchResults/FestivalVillage';
import Venues from './SearchResults/Venues';
import Highlights from './SearchResults/Highlights';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';



// const mapDispatchToProps = dispatch => {
//     return bindActionCreators(ActCreators, dispatch)
// };

const SearchResults = ({ history }) => {

    let sub;
    const apiserve = new service();
    const [loader, setLoader] = useState(false)
    const [festivalData, setFestivalData] = useState({})
    const [mobile, setMobile] = useState(false)
    const [data, setData] = useState([
        {
            label: "Films",
            Content: "This is content for Tab 1",
            isActive: false,
            identifier: 'film'
        },
        {
            label: "Beyond Film",
            Content: "This is content for Tab 2",
            isActive: false,
            identifier: 'talksevent'
        },
        {
            label: "New Frontier",
            Content: "This is content for Tab 3",
            isActive: false,
            identifier: 'frontFilm'
        },
        {
            label: "Partner Events",
            Content: "This is content for Tab 4",
            isActive: false,
            identifier: 'partnerFilm'
        },
        {
            label: "Festival Village",
            Content: "This is content for Tab 5",
            isActive: false,
            identifier: 'festivalVillage'
        },
        {
            label: "Venues",
            Content: "This is content for Tab 6",
            isActive: false,
            identifier: 'venue'
        },
        {
            label: "Highlights",
            Content: "This is content for Tab 7",
            isActive: false,
            identifier: 'highlight'
        }
    ])
    const search = useLocation().search;
    // const searchList = useSelector(state => state.GlobleSearch)
    const searchList = new URLSearchParams(search).get('id') || ''
    const [activetab, setActivetab] = useState(0)
    const [SearchData, setSearchData] = useState(searchList)

    useEffect(() => {
        getSearchData(searchList)
        // apiserve.sendevent("search|searchAll", { filter: searchList })
        sub = apiserve.getevent().subscribe((response) => {
            switch (response.event) {
                case "search|searchAll":
                    if (!response.error) {
                        setLoader(false);
                        setFestivalData(response.data);
                        !mobile && setActiveTabs(response.data)
                    } else {
                        setLoader(false);
                    }
                    break;

                default:
                    break;
            }
        });
        return () => {
            sub.unsubscribe()
        }
    }, [])

    useEffect(() => {
        // if ()
        window.innerWidth < 991 && setMobile(true)
        setSearchData(searchList)
        getSearchData(searchList)
    }, [searchList])
    window.addEventListener('resize', () => {
        window.innerWidth < 991 ? setMobile(true) : setMobile(false)
    })
    const setActiveTabs = (DATA) => {
        let Alldata = data;
        Alldata.map((item, i) => {
            if (DATA[item.identifier].length > 0) {
                item.isActive = true
                setActivetab(i)
            } else {
                item.isActive = false
                // setActivetab(0)
            }
        });
        setData(Alldata)
    }

    const getSearchData = (value, str = '') => {
        setLoader(true)
        apiserve.sendevent("search|searchAll", { filter: value });
        if (str === 'submit') {
            history.push(`/search-results?id=${value}`)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        getSearchData(SearchData, 'submit')
    }

    const handleChanges = (e) => {
        setSearchData(e.target.value)
        // getSearchData(e.target.value)

    }

    const TabClick = (index) => {
        setActivetab(index)
    }

    return (

        <div className="sd_search_result">

            {/* Header */}
            <Header history={history} />

            {/* Content */}
            <section className="sd_search_section">
                <div className="sd_search_banner">
                    <div className="sd_inner_container">
                        <div className="sd_serach_box">
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <input
                                    type="text"
                                    placeholder="Search the Festival"
                                    onChange={(e) => handleChanges(e)}
                                    value={SearchData}
                                />
                                <button className="sd_enter_serach" type="submit">
                                    <img src={loader ? apiserve.loader : `${process.env.REACT_APP_IMAGE_PATH}slider_arrow_right.svg`} alt="search List" title="Search List" />
                                </button>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="sd_inner_container">
                    <div className="sd_main_filter">
                        <div className="sd_search_filter">
                            <p className="sd_filter_title">FILTTER RESULTS</p>
                        </div>
                        <div className="sd_sub_title">
                            <ul className="sd_filters_options sd_flex">
                                {data && data ?.map((list, index) => {
                                    return (
                                        <>
                                        <li className={activetab === index ? "active" : 'test'} onClick={() => TabClick(index)}>
                                            <p>{list.label}</p>
                                        </li>
                                        </>
                                    );
                                })}
                            </ul>
                        </div>

                        {!mobile && activetab === 0 && (<Films festivalData={festivalData ?.film} history={history} SearchData={SearchData} />)}
                        {!mobile && activetab === 1 && (<TalksEvents festivalData={festivalData ?.talksevent} history={history} SearchData={SearchData} />)}
                        {!mobile && activetab === 2 && (<NewFrontier festivalData={festivalData ?.frontFilm} history={history} SearchData={SearchData} />)}
                        {!mobile && activetab === 3 && (<PartnerEvents festivalData={festivalData ?.partnerFilm} history={history} SearchData={SearchData} />)}
                        {!mobile && activetab === 4 && (<FestivalVillage festivalData={festivalData ?.festivalVillage} history={history} SearchData={SearchData} activetab={activetab} />)}
                        {!mobile && activetab === 5 && (<Venues festivalData={festivalData ?.venue} history={history} SearchData={SearchData} activetab={activetab} />)}
                        {!mobile && activetab === 6 && (<Highlights festivalData={festivalData ?.highlight} history={history} SearchData={SearchData} />)}
                        {mobile && (<Accordion allowZeroExpanded className="search_result" preExpanded={['highlight']}>
                            <Films festivalData={festivalData ?.film} history={history} SearchData={SearchData} />
                            <TalksEvents festivalData={festivalData ?.talksevent} history={history} SearchData={SearchData} />
                            <NewFrontier festivalData={festivalData ?.frontFilm} history={history} SearchData={SearchData} />
                            <PartnerEvents festivalData={festivalData ?.partnerFilm} history={history} SearchData={SearchData} />
                            <FestivalVillage festivalData={festivalData ?.festivalVillage} history={history} SearchData={SearchData} activetab={activetab} />
                            <Venues festivalData={festivalData ?.venue} history={history} SearchData={SearchData} activetab={activetab} />
                            <Highlights festivalData={festivalData ?.highlight} history={history} SearchData={SearchData} />
                        </Accordion>)}
                    </div>
                </div>

                {/* <div className="custom-pagination">
                    <ReactPaginate
                        previousLabel={<img src="/images/slider_arrow_left.svg" />}
                        nextLabel={<img src="/images/slider_arrow_right.svg" />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                    // forcePage={this.state.currentPage.selected || 0}

                    />
                </div> */}
            </section>

            {/* Footer */}
            <Footer />
        </div>

    )
}
export default SearchResults