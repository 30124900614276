import React, { Component } from 'react'
import service from "../common/service";
import Loader from "../components/Loader";
import moment from "moment";
import Popup from "../components/CartPopup";
// import { redirect } from '../common/functions';


let sub;
const FILM_TYPE = {
  1: "Premiere",
  2: "Second Screening",
  3: "Award winner",
  4: "On demand",
};
class Mycart extends Component {

    constructor(props) {
        super(props);
        this.state = {
          loader: false,
          alert_popup: false,
          alert_popup_title: "",
          alert_popup_note: "",
          alert_form_popup: false,
          alert_popup_name: "",
        };
        this.Service = new service();
      }
    
      componentWillUnmount() {
        sub.unsubscribe();
      }
    
      componentDidMount() {
        this.getList();
    
        sub = this.Service.getevent().subscribe((response) => {
          switch (response.event) {
            case "cart-purchase|getList":
                this.setState({ loader: false });
                if (response.data.cartData && response.data.cartData.length) {
                  this.setState({ cartData: response.data.cartData });
                  this.setState({ allCartInfo: response.data }) }
                else this.setState({ cartData: {} });
                break;
            case "cart-purchase|updateCart":
                this.getList();
                this.setState({
                  loader:false
                })
                break;
            case "cart-purchase|removeCart":
                this.getList();
                break;
          }
        });
      }

      getList() {
        this.setState({ loader: true });
        this.Service.sendevent("cart-purchase|getList", {});
      }

      openAlertPopup(popup_title, note, form, name) {
        this.setState({
          alert_popup: true,
          alert_popup_title: popup_title,
          alert_popup_note: note,
          alert_form_popup: form,
          alert_popup_name: name,
        });
      }

      closeAlertPopup(e) {
        if (e?.target.value === "Yes") {
          let data = { cartId: this.state.removeCartId };
          this.Service.sendevent("cart-purchase|removeCart", data);
          // this.props.updateCartCount()
          setTimeout(() => {
            this.props.updateCartCount()
          }, 400);
        }
        this.setState({
          alert_popup: false,
          alert_popup_title: "",
          alert_popup_note: "",
          alert_form_popup: false,
          alert_popup_name: "",
        });
      }


    //   minusCart(e, pass, index, transferIndex) {
    //     e.preventDefault();
    //     const cartData = Object.assign([], this.state.cartData);
    //     if (cartData[index].quantity > 1) {
    //         let data = {
    //             cartId: pass._id,
    //             quantity: cartData[index].quantity - 1,
    //             transfer: cartData[index].transfer,
    //         };
    //         this.setState({ cartData });
    //         this.Service.sendevent("cart-purchase|updateCart", data);
    //     } else this.setState({ loader: false });
    //     this.props.updateCartCount()
    //   }
    
    //   plusCart(e, pass, index) {
    //     e.preventDefault();
    //     const plusCart = Object.assign([], this.state.cartData);
    //     if (
    //       plusCart[index].quantity < 10 ||
    //       plusCart[index].identifier === "ExplorerPass"
    //     ) {
    //       plusCart[index].quantity = plusCart[index].quantity + 1;
    //       pass.transfer.push({ email: "", fname: "", lname: "" });
    //       let data = {
    //         cartId: pass._id,
    //         quantity: plusCart[index].quantity,
    //         transfer: pass.transfer,
    //       };
    //       this.Service.sendevent("cart-purchase|updateCart", data);
    //     }
    //     this.props.updateCartCount()
    //   }

    minusCart(e, pass, index, transferIndex) {
        e.preventDefault();
        
        const cartData = Object.assign([], this.state.cartData);
        cartData[index].transfer?.length > 0 ? cartData[index].transfer.pop() : cartData[index].gift.pop();
        if (cartData[index].quantity > 1) {
          let data = {
            cartId: pass._id,
            quantity: cartData[index].quantity - 1,
            transfer: cartData[index].transfer,
            gift: cartData[index].gift,
            identifier: cartData[index].identifier
          };
    
          this.setState({ 
            cartData,
            handlePassQty : {
              ...this.state.handlePassQty,
              passId: pass._id,
              operation: 'REMOVE',
            }
           });
    
          this.Service.sendevent("cart-purchase|updateCart", data);
    
    
    
        } else this.setState({ loader: false });
        this.props.updateCartCount()
      }
    
      plusCart(e, pass, index) {
        e.preventDefault();
        const plusCart = Object.assign([], this.state.cartData);
          plusCart[index].quantity = plusCart[index].quantity + 1;
    
          plusCart[index].gift?.length > 0 ? plusCart[index].gift.push({ email: "", fname: "", lname: "" }) : plusCart[index].transfer.push({ email: "", fname: "", lname: "" });
    
          let data = {
            cartId: pass._id,
            quantity: plusCart[index].quantity,
            transfer: plusCart[index].transfer,
            gift: plusCart[index].gift,
            identifier: plusCart[index].identifier
          };
    
          this.setState({ 
            handlePassQty : {
              ...this.state.handlePassQty,
              passId: pass._id,
              operation: 'ADD',
            }
           });

           window.dataLayer.push({
            'event': 'addToCart',
            'Item name': plusCart[index].identifier === 'hybridSingleFilmTicket' ? 'Single Film Ticket - ' + plusCart[index].selectedFilm.title + ' - ('+ moment(plusCart[index].selectedFilm.dateTime.date).format("MMM DD") +')' : plusCart[index].identifier === 'onlineDayPass' ? 'Day Package - Online Only - (' + moment(plusCart[index].selectedDate).format("MMM DD") + ')': plusCart[index].identifier === "hybridFestivalPass" ? 'Festival Package' : plusCart[index].identifier === "saltLackCityPackage" ? 'Salt Lake City Package' : plusCart[index].identifier === "onlineExplorerPass" ? 'Explorer Pass' : plusCart[index].identifier === "hybridAwardWinnersPass" ? 'Award-Winners Package' : plusCart[index].name || plusCart[index].title,
            'quantity': plusCart[index].quantity
          });
    
          this.Service.sendevent("cart-purchase|updateCart", data);
        this.props.updateCartCount()
      }
    
      removeCart(e, pass) {
        e.preventDefault();
        this.openAlertPopup(
          "Remove Item",
          ["You are about to remove an item from your shopping cart"],
          true,
          "remove_item"
        );
        this.setState({ removeCartId: pass._id });
        // this.props.updateCartCount()
        setTimeout(() => {
          this.props.updateCartCount()
        }, 400);
      }
      

      getDate = (pass) => {
        if(pass.identifier === "onlineDayPass")
            return <p>{moment(pass.selectedDate).tz("America/Denver").format("dddd, MMMM DD")}</p>
        else if(pass.identifier === "hybridSingleFilmTicket")
            return    (<><p>{pass.selectedFilm.title || pass.selectedFilm.name}</p> 
             <div className="location_desc">
                <span>
                  {moment(
                    pass.selectedFilm.dateTime
                      ? pass.selectedFilm
                          .dateTime.date
                      : pass.selectedFilm
                          .shortProgramme.date
                  ).format("MMMM DD, YYYY")}{" "}
                </span>
                <span>
                  {moment(
                    pass.selectedFilm.dateTime
                      ? pass.selectedFilm
                          .dateTime.date
                      : pass.selectedFilm
                          .shortProgramme.date
                  ).format("hh:mmA")}{" "}
                  {pass.selectedFilm.dateTime
                    .screeningType
                    ? FILM_TYPE[
                        pass.selectedFilm
                          .dateTime
                          .screeningType
                      ]
                    : ""}
                </span>
              <br />
              {pass.selectedFilm.dateTime.locations && pass.selectedFilm.dateTime.locations.length>0 ?  pass.selectedFilm.dateTime.locations.map((loc)=> {
                // return loc.name + ', ' +loc.city
                const name = loc.name===undefined ? "Name" : loc.name 
                const city= loc.city === undefined ? undefined : loc.city
                  if (city === undefined || city === '' ) return  loc.name 
                  else return name + ', '+city
                }).join(' | '):(<>Online</>)}</div>
              </>)
      }

      getGrandTotal = () => {
        const { cartData } = this.state;

        let total = 0;
        cartData && cartData.length &&  cartData.forEach(pass => { total += pass.productId.price * pass.quantity })
        return total;
      }

      getQuickCart = () => {
          const { cartData , loader } = this.state;

          if(cartData && cartData.length && !loader) {
                return cartData.map((pass, i) => {
                    return (
                        <li>
                            <span className="remove_item" onClick={(e) => this.removeCart(e, pass)}> × </span>
                            <div className="item_desc">
                                <h3>{pass.productId.name}</h3>
                                <p>{this.getDate(pass)}</p>
                            </div>
                            <div className="sd_flex sd_flexwrap sd_aligncenter sd_justbetween cart_purchase_col">
                                <h4 className="price">${pass.productId.price}</h4>
                                <div className="sd_home_pass_count">
                                    <button className="sd_home_pc_decrease">
                                        <img src="/village/images/minus_icon.svg" alt="Decrease" title="Decrease" onClick={(e) =>
                                            pass.quantity <= 1
                                            ? this.removeCart(e, pass)
                                            : this.minusCart(e, pass, i, 0)
                                        }/>
                                    </button>
                                    <input type="text" value={pass.quantity} />
                                    {/* <button disabled={pass.identifier === "ExplorerPass" ? "" : pass.limit >= 10 ? "disabled" : ""} className="sd_home_pc_increase"  >
                                        <img src="/village/images/filter_plus_icon.svg" alt="Increase" title="Increase" onClick={(e) => this.plusCart(e, pass, i)} />
                                    </button> */}
                                    <button disabled={pass.quantity >= pass.pendingPurchase ? 'disabled' : ''} className="sd_home_pc_increase"  >
                                    <img src={`${process.env.REACT_APP_IMAGE_PATH}filter_plus_icon.svg`} alt="Increase" title="Increase" onClick={(e) => this.plusCart(e, pass, i)} />
                                  </button>
                                  {!pass.forceGift ? pass.error ? <span className="sd_home_pass_count_error"> {pass.error && pass.errorMsg} </span> : '' : '' }
                                    {/* {pass.identifier !== "ExplorerPass" &&
                                        pass.limit >= 10 && (
                                        <span className="sd_home_pass_count_error">
                                            Maximum limit reached
                                        </span>
                                    )} */}
                                </div>
                                <h3 className="item_total">${pass.productId.price * pass.quantity}</h3>
                            </div>
                        </li>
                    )
                })
          }
          else
          {
            return (
              <div className="sd_mycart_item_not_found">
                  <p className="sd_textcenter">
                      You do not have any items in your cart.
                  </p>
                  <div className="sd_textcenter sd_btn_wrap">
                    <button className="sd_btn sd_btn_black" onClick={this.getTickets}>Explore Tickets</button>
                  </div>
              </div>
            )
          }   
      }

      checkOut = () => {
        window.open(`${process.env.REACT_APP_HEADER_BUY_TICKETS}cart`, "_self");
      }
      getTickets = () => {
        window.open(`${process.env.REACT_APP_HEADER_BUY_TICKETS}`, "_self");
      }
      checkForDisabled = () => {
        const { cartData } = this.state;
        let ERROR = false;
        cartData.forEach(PASS => { if(PASS.error && !PASS.forceGift) ERROR = true });
        return ERROR;
      }

      render() {
        const {loader, alert_popup, alert_popup_title, alert_popup_note, alert_form_popup, 
            alert_popup_name, cartData} = this.state;
        return (
            <>
            {/* {loader && <Loader />} */}
            <div>
                {alert_popup && (
                    <Popup
                    title={alert_popup_title}
                    popup_note={alert_popup_note}
                    form={alert_form_popup}
                    name={alert_popup_name}
                    onChildClick={(e) => this.closeAlertPopup(e)}
                    />
                )}
            </div>
            <div className="outer_cart_modal">
                <span className="overlay_fix" onClick={this.props.closeCartModal}></span>
                <div className="mycart_wrap">
                    <div className="mycart_container">
                        <h2 className="cart_title">My Cart</h2>
                        {
                            cartData && cartData.length > 0 && !loader?

                            <div className="mycart_content">
                                <div className="mycart_content_inner">
                                    <div className="wrap_row_list">
                                        <ul>
                                            {
                                                this.getQuickCart()
                                            }
                                        </ul>
                                    </div>
                                    <div className="total_row">
                                        <h4>Total</h4>
                                        <p>${this.getGrandTotal()}</p>
                                    </div>
                                </div>
                                <div className="sd_data">
                                    <p>Please support the Sundance Film Festival with an optional tax-deductible donation. <a href={process.env.REACT_APP_HEADER_DONATION}> Make a donation.</a></p>
                                    <button className={`sd_btn sd_btn_black ${this.checkForDisabled() ? 'disabled' : ''}`} disabled={this.checkForDisabled()}  onClick={() => this.checkForDisabled() ? '' :this.checkOut()}>Checkout</button>
                                </div>
                                {/* <button className="sd_btn sd_btn_black" onClick={() => this.checkOut()}>Checkout</button> */}
                            </div> : 
                              loader ? 
                                <div className='abs_loader'>
                                   <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
                                    <path fill="#FF7433" d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3 c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z"> <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="2s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /> </path>
                                    <path fill="#3349FF" d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7 c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z"> <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="-360 50 50" repeatCount="indefinite" /> </path>
                                    <path fill="#FF7433" d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5 L82,35.7z"> <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="2s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /> </path>
                                </svg>  
                                </div> :  <div className="sd_mycart_item_not_found">
                                <p className="sd_textcenter">
                                    You do not have any items in your cart.
                                </p>
                                <div className="sd_textcenter sd_btn_wrap">
                                  <button className="sd_btn sd_btn_black" onClick={this.getTickets}>Explore Tickets</button>
                                </div>
                            </div>
                        }
                        
                        <div className="cart_item_dropdown">
                            <span className="up_aerrow" onClick={this.props.closeCartModal}>
                                <svg width="42px" height="23px" viewBox="0 0 42 23" version="1.1" >
                                    <g id="TICKETING_ARCHIVE" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="TX-07-cart/drawer_v2" transform="translate(-819.000000, -537.000000)">
                                            <g id="Group-22" transform="translate(-173.000000, -1011.000000)"></g>
                                            <g id="Rectangle">
                                                <use fill="black" fill-opacity="1" filter="url(#filter-2)" ></use>
                                                <use fill="#FFFFFF" fill-rule="evenodd" ></use>
                                            </g>
                                            <polyline id="Path-2" stroke="#000000" stroke-width="2" transform="translate(840.000000, 559.000000) rotate(45.000000) translate(-840.000000, -559.000000) " points="826 573 826 545 854 545"></polyline>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default Mycart;