import React, { Component } from 'react';
import service from "../common/service";

// Layouts
import Footer from "../layout/footer";
import Header from "../layout/header";

import { connect } from 'react-redux'

const mapStateToProps = state => ({
    sponsors: state.sponsors,
})

// const mapDispatchToProps = dispatch => {
//     return bindActionCreators(ActCreators, dispatch)
// };

class Sponsors extends Component {
    constructor() {
		super();
		this.state = {
			sponsors: [],
            header: {}
        };
        this.apiserve = new service();
        document.querySelector('body').scrollTo({top: 0, behavior: 'smooth' });
    }

    scrollTopFn = () =>{
        window.scrollTo({top:0,left:0,behavior:'smooth'})
    }

    componentDidMount(){

        this.apiserve.sendevent("sponsor|getSponsor", { });

        this.apiserve.getevent().subscribe((response) => {
            switch (response.event) {
            case "sponsor|getSponsor":
                    if(!response.error)
                    {
                        // Set Sponsors Title
                        let sponsors_titles = [], sponsors = response.data;    
                        this.props.dispatch({type: 'Sponsors', res: response.data})
                        sponsors && sponsors.sponsorArray && sponsors.sponsorArray.length > 0 && sponsors.sponsorArray.forEach(sponsor => {
                            sponsors_titles.push(sponsor.sponsorType);
                        });      
                        
                        this.setState({
                            sponsors: sponsors,
                            sponsors_title: sponsors_titles,
                            current_sponsors_title: sponsors_titles[0],
                            header: response.data

                        });  
                        this.scrollTopFn()
                    }
                    else{ }
                break;            
            default:
                  break;
            }
        });
    }
    getSponsorsMobile = () => {
        let { sponsors} = this.state;
        return sponsors && sponsors.sponsorArray && sponsors.sponsorArray.length > 0 && sponsors.sponsorArray.map((sponsor, i) => {
            
            return (<>
                <div className="sd_sponsored_mobile">
                    <div className="sd_sponsored_inner">
                        <div className="sd_inner_container sd_relative  sd_sponsors_main ">
                            <div className="sd_sponsored_inner_flex sd_flex sd_aligncenter ">
                                <div className="sd_mobile">
                                    <p className="sd_sponsored_title">{sponsor.sponsorType}</p>
                                </div>
                    {sponsor.isVisible &&
                        <>
                            <div className="sd_logo_Mobile" key={i}>
                                <div className=" sd_img sd_flex sd_flexwrap sd_justbetween">
                                    {sponsor.mobile_images.map((mob, index) => {
                                        return (
                                            <div className="logo_img  sd_justcenter ">
                                                <img src={this.apiserve.imageUrl + mob.url} alt={sponsor.sponsorType} title={sponsor.sponsorType} />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </>
                    }
                    </div>
                    </div>
                    </div>
                </div>
            </>)
        })
    }

    getSponsors = () => {
        const sponsors = this.state.sponsors;

        return sponsors && sponsors.sponsorArray && sponsors.sponsorArray.length > 0 && sponsors.sponsorArray.map((sponsor, i) => {
            if (sponsor.isVisible) {
                return (<>
                <div className="sd_sponsored_section ">
                    <div className="sd_sponsored_inner">
                        <div className="sd_inner_container sd_relative">       
                            <div className="sd_sponsored_inner_flex sd_flex sd_aligncenter">
                                <p className="sd_sponsored_title">{ this.state.sponsors_title[i] }</p>                     
                                <div className="sd_logo_sec_wpr">
                                    <img src={this.apiserve.imageUrl + sponsor.image} alt={sponsor.sponsorType} title={sponsor.sponsorType} />
                                </div>  
                            </div>  
                        </div>  
                    </div>  
                </div> 
                    </>
                )
            }
            else {
                return false
            }
            
        })
    }
    gotoSponsor(e, pageName) {
        window.open(`${process.env.REACT_APP_HEADER_PROGRAM}${pageName}`, '_self');
    }
    render() {
        let { sponsors, current_sponsors_title,header } = this.state;
        return (
            <div className="sponsors page_sec">

                {/* Header */}
                <Header history={this.props.history} hero_header={true}/>

                {/* Content */}
                <section className="sd_about_header">
					<div className="sd_about_header_sec">
						{/* Hero Header */}
						<div className="sd_hero_header sd_sponsors_header">
							<div className="sd_inner_container sd_hero_header_inner">
								<div className="sd_hero_header_title">
									<h1 className="sd_textuppercase">{header.title}</h1> 
									<div className="sd_rtf_content"  dangerouslySetInnerHTML={{__html: header.description}} ></div>
								</div>
							</div>
						</div>
					</div>
				</section>

                {/* Sponsors Logo */}
                {/* <section className="sd_sponsors_section sd_sponsors_page sd_sponsors_pink_section">
                    {
                        this.getSponsors()
                    }     
                </section> */}
                 <div className="sd_sponsored_section sd_sponsors_page">
                  
                        {
                            this.getSponsors()
                        }
                                  
                </div>
                <div className="sd_hero1_slider_sec">
                   
                    {
                        this.state.sponsors && sponsors.sponsorArray && sponsors.sponsorArray.length > 1 ?
                            <>
                                    {
                                        this.getSponsorsMobile()
                                    }
                            </>
                            :
                            this.getSponsorsMobile()
                    }
                     
                </div>

            {/* Footer */}  
            <Footer />  
        </div>
            
        )
    }
}

export default connect(mapStateToProps, null)(Sponsors)