import React, { Component } from "react";
//service
import service from "../src/common/service";
import { Switch, Route } from "react-router-dom";
// page
import Home from "./pages/Home";
import Sponsors from "./pages/Sponsors";
import CinemaNetwork from "./pages/CinemaNetwork";
import ArtistLounge from "./pages/ArtistLounge";
import Partner from "./pages/Partner";
import MainStreet from "./pages/MainStreet";
import SatelliteDetail from "./pages/SatelliteDetail";
import GeneralPanelScreeningView from "./pages/GeneralPanelScreeningView";
import OfferView from './pages/OfferView';
import GeneralPanelScreeningPreview from './pages/preview/GeneralPanelScreeningPreview';
import ArtistLoungePreview from './pages/preview/ArtistLoungePreview';
import PartnerPreview from './pages/preview/PartnerPreview';
import VillageHomePreview from './pages/preview/VillageHomePreview';
import CinemaNetworkPreview from './pages/preview/CinemaNetworkPreview';
import SearchResults from "./pages/SearchResults";

// for slick slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loader from "./components/loader/loader";
import Cookies from "universal-cookie";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  CurrentUserData: state.CurrentUserData,
});

export const AuthContext = React.createContext({});
let sub;
const cookies = new Cookies();

class Routers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSocketConnected: false,
      isAuthenticated: false,
      isLoading: true,
    };
    this.apiServe = new service();
  }

  componentWillUnmount = () => {
    sub.unsubscribe();
  };

  componentWillMount = () => {
    this.apiServe.connect((flag) => {
      if (flag) {
        this.setState({ isSocketConnected: true }, () => {
          var token = localStorage.getItem("myLoginToken")
            ? localStorage.getItem("myLoginToken")
            : "";

          let particularUser =
            cookies.get("SundanceNewUser") || cookies.get("particularNewUser");
          if (particularUser && particularUser.token) {
            this.apiServe.sendevent("authentication", {
              token: particularUser.token,
            });
          } else {
            this.setState({ isLoading: false });
          }
        });
      }
    });

    sub = this.apiServe.getevent().subscribe((response) => {
      switch (response.event) {
        case "authentication":
          this.setState({ isAuthenticated: true, isLoading: false }, () => {
            // this.props.history.push('/access-management')
          });
          break;
        default:
          break;
      }
    });
  };

  render() {
    return (
      <AuthContext.Provider
        value={{
          isAuthenticated: this.state.isAuthenticated,
          isLoading: this.state.isLoading,
        }}
      >
        {this.state.isSocketConnected ? (
          <Switch>
            <Route exact path="/" render={(props) => <Home {...props} />} />
            <Route
              exact
              path="/sponsors"
              render={(props) => <Sponsors {...props} />}
            />
            <Route
              exact
              // path="/satellite-screens"
              path="/community-hub"
              render={(props) => <CinemaNetwork {...props} />}
            />
            <Route
              exact
              path="/artist-lounge"
              render={(props) => <ArtistLounge {...props} />}
            />

            <Route
              exact
              path="/main-street"
              render={(props) => <Partner {...props} />}
            />
            <Route
              exact
              path="/main-street/:sponsor/:id"
              render={(props) => <MainStreet {...props} />}
            />
            <Route
              exact
              path="/community-hub/:organization/:id?"
              render={(props) => <SatelliteDetail {...props} />}
            />
            <Route
              exact
              path="/panel-view/:id?"
              render={(props) => <GeneralPanelScreeningView {...props} />}
            />
            <Route
              exact
              path="/offer-view/:id?"
              render={(props) => <OfferView {...props} />}
            />
            <Route
              exact
              path="/film-preview"
              render={(props) => <GeneralPanelScreeningPreview {...props} />}
            />
            <Route
              exact
              path="/artist-preview"
              render={(props) => <ArtistLoungePreview {...props} />}
            />
            <Route
              exact
              path="/mainstreet-preview"
              render={(props) => <PartnerPreview {...props} />}
            />
            <Route
              exact
              path="/home-preview"
              render={(props) => <VillageHomePreview {...props} />}
            />
            <Route
              exact
              path="/satellite-preview"
              render={(props) => <CinemaNetworkPreview {...props} />}
            />
				    <Route exact path="/search-results" render={(props) => <SearchResults {...props}  />}/>
				{/* <Route exact path={`/search-results`} component={SearchResults} /> */}


          </Switch>
        ) : (
            ""
          )}
      </AuthContext.Provider>
    );
  }
}

// export default Routers;
export default connect(mapStateToProps, null)(Routers);
