import React, { Component } from "react";
import { Link } from "react-router-dom";

class CinemaBlock extends Component {
  render() {
    // Props
    let { content } = this.props;
    return (
      <>
        <div className="sd_block">
          <Link to={content.link[0]}>
            <div className="sd_block_images">
              <img
                src={
                  content.state.satelliteScreenThumbnail != ""
                    ? `${process.env.REACT_APP_IMAGE_URL}` +
                      content.state.satelliteScreenThumbnail
                    : content.state.satelliteScreenThumbnailUrl
                }
                alt="CinemaBlock"
              />
            </div>
            <div className="sd_block_des">
              {/* <p>
              {content.state.city},{" "}{content.state.state}
              </p> */}
              <h3>{content.state.satelliteScreenName}</h3>
              {/* <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat Ut wisi enim ad minim veniam. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh.</p> */}
              {/* <p>{content.state.landingPageShortDescription}</p> */}
                      <p
                      className="paragraph_span_14"
                      dangerouslySetInnerHTML={{
                        __html: content.state.landingPageShortDescription && content.state.landingPageShortDescription,
                      }}
                    />
              <Link to={content.link[0]} className="sd_textcapitalize sd_learn_more sd_flex">
                Learn More
                <div className="sd_arrow">
                  <img
                    src={`${process.env.REACT_APP_IMAGE_PATH}arrow_right.svg`}
                    alt="Next Arrow"
                    title="Next Arrow"
                  />
                </div>
              </Link>
            </div>
          </Link>
        </div>
      </>
    );
  }
}

export default CinemaBlock;
