export function LogdedUserData(res) {
  return { type: 'LogdedUserData', res };
}

export function Filters(res) {
  // debugger
  return { type: 'Filters', res };
}

export function Sponsors(res) {
  return { type: 'Sponsors', res };
}

export function Applied_Filters_Film(res) {
  return { type: 'APPLIED_FILTERS_FILMS', res };
}

export function Applied_Filters_Village(res) {
  return { type: 'APPLIED_NEW_VILLAGE', res };
}
