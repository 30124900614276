import React, { Component } from "react";
// layout
import Header from "../layout/header";
import Footer from "../layout/footer";
// services
import service from "../common/service";
//moment
import moment from "moment";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
// video
import VideoPlayer from "../components/VideoPlayer";
import Plus from "../images/plus.png";
import Righticon from "../images/tick-black-icon.png";
import Loader from "../components/loader/loader";
import FavoriteCard from "../components/FavoriteCard";
import { getuserRole, getZone, TimezoneConverter } from "../store/functions";
import { Helmet } from "react-helmet";
const cookies = new Cookies();

const videoExtensions = [
  "3g2",
  "3gp",
  "aaf",
  "asf",
  "avchd",
  "avi",
  "drc",
  "flv",
  "m2v",
  "m4p",
  "m4v",
  "mkv",
  "mng",
  "mov",
  "mp2",
  "mp4",
  "mpe",
  "mpeg",
  "mpg",
  "mpv",
  "mxf",
  "nsv",
  "ogg",
  "ogv",
  "qt",
  "rm",
  "rmvb",
  "roq",
  "svi",
  "vob",
  "webm",
  "wmv",
  "yuv",
];

let sub;
let timer = null;
export default class SatelliteDetail extends Component {
  constructor(props) {
    super(props);
    this.apiServe = new service();
    this.state = {
      MainStreetData: "",
      article: "",
      articles: "",
      checkVideo: false,
      ispartnerTitleEvent: false,
    };
    document.querySelector("body").scrollTo({ top: 0, behavior: "smooth" });
  }
  componentWillUnmount = () => {
    sub.unsubscribe();
    clearTimeout(timer);
  };
  removePopup = () => {
    this.setState({
      loginPrompt: false,
    });
  };
  getAddScheduleEvent = (cardid, cardDateTime, cardTemplate) => {
    let particularUser =
      cookies.get("SundanceNewUser") || cookies.get("particularNewUser");
    if (!particularUser) {
      return this.setState({
        loginPrompt: true,
      });
    }

    this.setState({
      [cardid]: !this.state[cardid],
    });
    this.apiServe.sendevent("FestivalVillage|Event|addToFavourite", {
      eventId: cardid,
      date: cardDateTime,
      eventType: cardTemplate,
    });
  };
  getRemoveScheduleEvent = (cardid, cardDateTime) => {
    this.setState({
      [cardid]: !this.state[cardid],
    });
    this.apiServe.sendevent("FestivalVillage|Event|removeFavourite", {
      eventId: cardid,
      date: cardDateTime,
    });
  };

  scrollTopFn = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }

  componentDidMount() {
    if (!this.props.match.params.id) this.props.history.push("/");
    let particularUser =
      cookies.get("SundanceNewUser") || cookies.get("particularNewUser");
    if (particularUser && particularUser.token) {
      this.apiServe.sendevent("authentication", {
        token: particularUser.token,
      });
    }
    this.getHomeData();
    sub = this.apiServe.getevent().subscribe((response) => {
      switch (response.event) {
        case "authentication":
          if (response.error) {
            let Zone = localStorage.getItem('TIMEZONE')
            localStorage.clear();
            if (Zone) localStorage.setItem('TIMEZONE', Zone)
          } else {
            localStorage.setItem("loggedInUser", response.data.user.role);
            localStorage.setItem("myLoginToken", response.data.token);
            this.getProfile();
          }
          break;
        case "user|getProfile":
          if (response.error) {
          } else {
            if (localStorage.getItem("myLoginToken")) {
              let userInfo = {
                fname: response.data.fname,
                lname: response.data.lname,
                image: response.data.image,
                showMyLibrary: response.data.showMyLibrary,
              };
              localStorage.setItem("Header", JSON.stringify(userInfo));
            }
            this.setState({
              Header: true,
              code_conduct: response.data.COMM_AGMT_FLAG,
            });
            this.forceUpdate();
            // this.getHomeData();
          }
          break;
        case "FestivalVillage|SatelliteScreen|getAllSatelliteScreen":
          if (response.error) {
          } else {

            var partnerAll = [];

            for (const key in response.data) {
              if (response.data.hasOwnProperty(key)) {
                const element = response.data[key];
                for (const key in element) {
                  if (element.hasOwnProperty(key)) {
                    const ele = element[key];
                    ele.forEach((partner) => {
                      partnerAll.push(partner);
                    });
                  }
                }
              }
            }
            this.setState({
              articles:
                response &&
                partnerAll.find(
                  (item) => item._id === this.props.match.params.id
                ),
            });
            this.scrollTopFn()
          }
          break;
        case "FestivalVillage|FV_06_Branded_Satellite":
          if (response.error) {
           
          } else {
            this.setState({
              article: response && response.data,
            });
            window.dataLayer.push({
              'sponsor':response.data?.satelliteData?.satelliteScreenName,
              'pageCategory': 'Community Hub',
              'visitorType': getuserRole(particularUser?.user?.role || '')
              });

            response &&
              response.data.eventData[0] &&
              response.data.eventData[0].forEach((element) => {
                if (element.satelliteTitleEvent) {
                  this.setState({
                    ispartnerTitleEvent: true,
                  });
                }
                if (element.isFavourite) {
                  this.setState({
                    [element._id]: element.isFavourite,
                  });
                }
              });
              this.scrollTopFn()
            
          }

          break;
        case "FestivalVillage|Event|addToFavourite":
          if (response.error) {
          } else {
          }
          break;
        case "FestivalVillage|Event|removeFavourite":
          if (response.error) {
          } else {
          }
          break;
        default:
          break;
      }
    });
  }

  getProfile = () => {
    this.apiServe.sendevent("user|getProfile", {});
  };
  getHomeData() {
    this.apiServe.sendevent("FestivalVillage|FV_06_Branded_Satellite", {
      id: this.props.match.params.id,
    });
  }

  // Extension
  getFileExtension(filename) {
    return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined;
  }

  checkIsVideo(filePath) {
    var getExtension = this.getFileExtension(filePath);
    return videoExtensions.includes(getExtension);
  }
  checkisIframe(url) {
    var expVimeo = new RegExp(/(vimeo\.com)/);
    var expYoutube = new RegExp(/(youtu\.be|youtube\.com)/);

    var expVimeoTest = expVimeo.test(url);
    var expYoutubeTest = expYoutube.test(url);

    if (expVimeoTest || expYoutubeTest) {
      return true;
    } else {
      return false;
    }
  }
  render() {
    const { article, ispartnerTitleEvent } = this.state;
    return (
      <section className="sd_mainstreet_section sd_home_page">
        {/* <Helmet>
          <meta property="og:url" content={`https://festival.sundance.org/village/community-hub/${this.props?.match?.params?.organization}/${this.props?.match?.params?.id}` } />
          <meta property="og:type" content='article' />
          <meta property="og:title" content={`${article?.satelliteData?.satelliteScreenName ? article?.satelliteData?.satelliteScreenName : this.props?.match?.params?.organization} | 2023 Sundance Film Festival`}/>
          <meta property="og:description" content='' />
          <meta property="og:image" content="https://d2wsrejhnxatgp.cloudfront.net/imgpsh_fullsize_festival_2023.png" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title"  content={`${article?.satelliteData?.satelliteScreenName ? article?.satelliteData?.satelliteScreenName : this.props?.match?.params?.organization} | 2023 Sundance Film Festival`} />
          <meta name="twitter:url" content={`https://festival.sundance.org/village/community-hub/${this.props?.match?.params?.organization}/${this.props?.match?.params?.id}` } />
          <meta name="twitter:description" content=''/>
          <meta name="twitter:image" content="https://d2wsrejhnxatgp.cloudfront.net/imgpsh_fullsize_festival_2023.png" />
                
          <meta name="description" content={`${article?.satelliteData?.satellitePageDescription?.replace(/<[^>]+>/g, '') || ''}`} />
          <meta name="keywords" content={`${article?.satelliteData?.satelliteScreenName ? article?.satelliteData?.satelliteScreenName : this.props?.match?.params?.organization}, Sundance Film Festival, 2023 Sundance Festival, Sundance Festival, Film Festival`} />
          <title>{`${article?.satelliteData?.satelliteScreenName ? article?.satelliteData?.satelliteScreenName : this.props?.match?.params?.organization} | 2023 Sundance Film Festival`}</title>
          <link rel="canonical" href={`https://festival.sundance.org/village/community-hub/${this.props?.match?.params?.organization}/${this.props?.match?.params?.id}` } />
        </Helmet> */}
        {/* Header */}
        <Header history={this.props.history}/>
        <section className="sd_mainstreet">
          <div className="sd_mainstreet_header">
            <div className="sd_mainstreet_container">
              <div className="sd_mainstreet_inner">
                <div className="sd_mainstreet_des">
                  <h1 className="sd_textuppercase">
                    {article && article.satelliteData.satelliteScreenName}
                  </h1>
                  {/* <h3 className="sd_textuppercase">
                    {article && article.satelliteData.city},{" "}
                    {article && article.satelliteData.state}
                  </h3> */}
                  <div className="sd_mainstreet_logo">
                  {article.satelliteData &&
                      article.satelliteData.satelliteLogo && (
                        <img
                          src={
                            article &&
                            article.satelliteData && 
                            article.satelliteData.satelliteLogo != ""
                              ? `${process.env.REACT_APP_IMAGE_URL}` +
                              article.satelliteData.satelliteLogo
                              : ''
                          }
                          alt="logo"
                        />
                      )}
                    </div>
                </div>

                <div
                  className={
                    ispartnerTitleEvent
                      ? "sd_mainstreet_para"
                      : " sd_mainstreet_para sd_main_paragraph"
                  }
                >
                  <p
                    className="paragraph_span_22"
                    dangerouslySetInnerHTML={{
                      __html:
                        article &&
                        article.satelliteData.satellitePageDescription,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sd_mainstreet">
          {article &&
            article.eventData[0] &&
            article.eventData[0].map(
              (card, i) =>
                card.satelliteTitleEvent == true && (
                  <div className={"sd_header_hero"} key={i}>
                    {card.eventDisplayTemplate == "Offer" &&
                    //   card.eventDate == "" ? (
                    //   <div className="sd_header_date_day">
                    //     <h4>All Festival</h4>
                    //   </div>
                    // ) : 
                    card && card.eventDate == "" ? (
                      ""
                    ) : 
                    // card &&
                    //   card.eventDate[0] &&
                    //   card.eventDate[0].dateKey &&
                    //   card.eventDate[0].dateKey != "" ? (
                    //   <div className="sd_header_date_day">
                    //     <h4>
                    //       {card &&
                    //         card.eventDate[0] &&
                    //         card.eventDate[0].dateKey &&
                    //         card.eventDate[0].dateKey}
                    //     </h4>
                    //   </div>
                    // ) 
                    card.availableThroughoutFestival === true ? (
                      <div className="sd_header_date_day">
                        <h4>
                         All Festival
                        </h4>
                      </div>
                    ) :  (
                      <div className="sd_header_date_day">
                        <>
                          <p>
                            {TimezoneConverter(moment
                              .utc(
                                card &&
                                card.eventDate[0] &&
                                card.eventDate[0].date
                              )
                              .local()
                              ,"MMMM")}
                          </p>
                          <h3>
                            {TimezoneConverter(moment
                              .utc(
                                card &&
                                card.eventDate[0] &&
                                card.eventDate[0].date
                              )
                              .local()
                              ,"DD")}
                          </h3>
                        </>
                      </div>
                    )}

                    {this.checkisIframe(
                      card &&
                        card.keyEventThumbnailImage[0] &&
                        card.keyEventThumbnailImage[0].link != ""
                        ? card.keyEventThumbnailImage[0].link
                        : card.keyEventThumbnailImage[0].url
                    ) ? (
                      <div className="sd_video_wpr iframeContainer">
                        <iframe
                          src={
                            card &&
                              card.keyEventThumbnailImage[0] &&
                              card.keyEventThumbnailImage[0].link != ""
                              ? process.env.REACT_APP_IMAGE_URL +
                              card.keyEventThumbnailImage[0].link
                              : card.keyEventThumbnailImage[0].url
                          }
                        ></iframe>
                      </div>
                    ) : this.checkIsVideo(
                      card &&
                        card.keyEventThumbnailImage[0] &&
                        card.keyEventThumbnailImage[0].link != ""
                        ? card.keyEventThumbnailImage[0].link
                        : card.keyEventThumbnailImage[0].url
                    ) ? (
                      <div className="sd_video_wpr">
                        <VideoPlayer
                          videoSrc={
                            card &&
                              card.keyEventThumbnailImage[0] &&
                              card.keyEventThumbnailImage[0].link != ""
                              ? process.env.REACT_APP_IMAGE_URL +
                              card.keyEventThumbnailImage[0].link
                              : card.keyEventThumbnailImage[0].url
                          }
                          autoplay={false}
                          loop={true}
                        />
                      </div>
                    ) : (
                      <img
                        src={
                          card.keyEventThumbnailImage[0] &&
                            card.keyEventThumbnailImage[0].link != ""
                            ? process.env.REACT_APP_IMAGE_URL +
                            card.keyEventThumbnailImage[0].link
                            : card.keyEventThumbnailImage[0].url
                        }
                        alt="Setellite block"
                      />
                    )}
                    <div className="sd_header_hero_des">
                      {card.eventDisplayTemplate == "Offer"
                        ? card && (
                          <h2
                            className={
                              card.eventDate == ""
                                ? "sd_offer_space"
                                : "sd_offer"
                            }
                          >
                            {card.offerTitle}
                          </h2>
                        )
                        : card && (
                          <h2
                            className={
                              card.eventDate == ""
                                ? "sd_offer_space"
                                : "sd_offer"
                            }
                          >
                            {card.eventTitle}
                          </h2>
                        )}
                      {card && card.eventDate == "" ? (
                        ""
                      ) :
                      //  card &&
                      //   card.eventDate[0] &&
                      //   card.eventDate[0].dateKey &&
                      //   card.eventDate[0].dateKey != "" ? (
                      //   <span>
                      //     {card &&
                      //       card.eventDate[0] &&
                      //       card.eventDate[0].dateKey &&
                      //       card.eventDate[0].dateKey}
                      card.availableThroughoutFestival === true ? (
                        <span>
                          All Festival
                            {" "}
                              {/* {getZone()} */}
                              {  card &&
                              card.eventDate[0] && (
                              card.eventDate[0].eventParticularTime[0].availability == "1" ?
                               <span className="sd_textuppercase in-person ml-2"> | IN-PERSON</span> 
                                :
                                card.eventDate[0].eventParticularTime[0].availability == "2" &&
                                <span className="ml-2"> | ONLINE </span>

                            )}
                          {this.state[card._id] ? (
                            <button
                              className="sd_textuppercase"
                              onClick={() =>
                                this.getRemoveScheduleEvent(
                                  card._id,
                                  card.eventDate[0]
                                    ? card.eventDate[0].eventParticularTime[0]
                                      .start
                                    : ""
                                )
                              }
                            >
                              <img
                                src={Righticon}
                                alt="plus"
                                className="home-right-btn"
                              />
                            </button>
                          ) : (
                            <button
                              onClick={() =>
                                this.getAddScheduleEvent(
                                  card._id,
                                  card.eventDate[0]
                                    ? card.eventDate[0].eventParticularTime[0]
                                      .start
                                    : "",
                                  card.eventDisplayTemplate
                                )
                              }
                            >
                              <img src={Plus} alt="Plus" />
                            </button>
                          )}
                        </span>
                      ) : card.eventDisplayTemplate == "Offer" &&
                      //   card.eventDate == "" ? (
                      //   <span>All Festival</span>
                      // ) : 
                      card.availableThroughoutFestival === true ? (
                          <span>All Festival</span>
                      ) :
                      (
                        <span className="sd_textuppercase">
                          {TimezoneConverter(moment
                            .utc(
                              card &&
                              card.eventDate[0] &&
                              card.eventDate[0].eventParticularTime[0].start
                            )
                            .local()
                            ,"hh:mm A ")}{" "}
                          -{" "}
                          {TimezoneConverter(moment
                            .utc(
                              card &&
                              card.eventDate[0] &&
                              card.eventDate[0].eventParticularTime[0].end
                            )
                            .local()
                            ,"hh:mm A")} {" "}
                            {getZone()}
                            {  card &&
                            card.eventDate[0] && (
                            card.eventDate[0].eventParticularTime[0].availability == "1" ?
                            <span className="sd_textuppercase in-person ml-2"> | IN-PERSON</span> 
                              :
                              card.eventDate[0].eventParticularTime[0].availability == "2" &&
                              <span className="ml-2"> | ONLINE </span>

                          )}
                          {this.state[card._id] ? (
                            <button
                              className="sd_textuppercase"
                              onClick={() =>
                                this.getRemoveScheduleEvent(
                                  card._id,
                                  card.eventDate[0]
                                    ? card.eventDate[0].eventParticularTime[0]
                                        .start
                                    : ""
                                )
                              }
                            >
                              <img
                                src={Righticon}
                                alt="plus"
                                className="home-right-btn"
                              />
                            </button>
                          ) : (
                            <button
                              onClick={() =>
                                this.getAddScheduleEvent(
                                  card._id,
                                  card.eventDate[0]
                                    ? card.eventDate[0].eventParticularTime[0]
                                        .start
                                    : "",
                                  card.eventDisplayTemplate
                                )
                              }
                            >
                              <img src={Plus} alt="Plus" />
                            </button>
                          )}
                        </span>
                      )}

                      {card.eventDisplayTemplate == "Offer" ? (
                        <p
                          className="paragraph_span_16 mainstreet_span"
                          dangerouslySetInnerHTML={{
                            __html: card.offerDescription,
                          }}
                        />
                      ) : (
                        <p
                          className="paragraph_span_16 mainstreet_span"
                          dangerouslySetInnerHTML={{
                            __html: card.eventDescription,
                          }}
                        />
                      )}
                      <Link
                        to={"/panel-view/" + card._id}
                        className="sd_textcapitalize"
                      >
                        Learn More
                      </Link>
                    </div>
                  </div>
                )
            )}
        </section>
        <section
          className={
            article && article.eventData && article.eventData != ""
              ? "sd_mainstreet_Block"
              : "sd_mainstreet_Block_padd"
          }
        >
          <div className="sd_mainstreet_Block_inner">
            {article &&
              article.eventData[0] &&
              article.eventData[0].map(
                (card, i) =>
                  card.satelliteTitleEvent == false && (
                    <div className="sd_mainstreet_block_Sec" key={i}>
                      {card.eventDisplayTemplate == "Offer" &&
                      //   card.eventDate == "" ? (
                      //   <div className="sd_header_date_day">
                      //     <h4>All Festival</h4>
                      //   </div>
                      // ) :
                       card.eventDate == "" ? (
                        ""
                      ) : 
                      // card &&
                      //   card.eventDate[0] &&
                      //   card.eventDate[0].dateKey &&
                      //   card.eventDate[0].dateKey != "" ? (
                      //   <div className="sd_header_date_day">
                      //     <h4>
                      //       {card &&
                      //         card.eventDate[0] &&
                      //         card.eventDate[0].dateKey &&
                      //         card.eventDate[0].dateKey}
                      //     </h4>
                      //   </div>
                      // ) 
                      card.availableThroughoutFestival === true ? (
                      <div className="sd_header_date_day">
                          <h4>
                            All Festival
                          </h4>
                        </div>
                      )
                      : (
                        <div className="sd_header_date_day">
                          <p>
                            {TimezoneConverter(moment
                              .utc(
                                card &&
                                card.eventDate[0] &&
                                card.eventDate[0].date
                              )
                              .local()
                              ,"MMMM")}
                          </p>
                          <h3>
                            {TimezoneConverter(moment
                              .utc(
                                card &&
                                card.eventDate[0] &&
                                card.eventDate[0].date
                              )
                              .local()
                             ,"DD")}
                          </h3>
                        </div>
                      )}

                      <div className="sd_mainstreet_block_img">
                        {this.checkisIframe(
                          card &&
                            card.keyEventThumbnailImage[0] &&
                            card.keyEventThumbnailImage[0].link != ""
                            ? card.keyEventThumbnailImage[0].link
                            : card.keyEventThumbnailImage[0].url
                        ) ? (
                          <div className="sd_video_wpr iframeContainer">
                            <iframe
                              src={
                                card &&
                                  card.keyEventThumbnailImage[0] &&
                                  card.keyEventThumbnailImage[0].link != ""
                                  ? process.env.REACT_APP_IMAGE_URL +
                                  card.keyEventThumbnailImage[0].link +
                                  "?enablejsapi=1&version=3&playerapiid=ytplayer"
                                  : card.keyEventThumbnailImage[0].url +
                                  "?enablejsapi=1&version=3&playerapiid=ytplayer"
                              }
                            ></iframe>
                          </div>
                        ) : this.checkIsVideo(
                          card &&
                            card.keyEventThumbnailImage[0] &&
                            card.keyEventThumbnailImage[0].link != ""
                            ? card.keyEventThumbnailImage[0].link
                            : card.keyEventThumbnailImage[0].url
                        ) ? (
                          <div className="sd_video_wpr">
                            <VideoPlayer
                              videoSrc={
                                card &&
                                  card.keyEventThumbnailImage[0] &&
                                  card.keyEventThumbnailImage[0].link != ""
                                  ? process.env.REACT_APP_IMAGE_URL +
                                  card.keyEventThumbnailImage[0].link
                                  : card.keyEventThumbnailImage[0].url
                              }
                              autoplay={false}
                              loop={true}
                            />
                          </div>
                        ) : (
                          <img
                            src={
                              card.keyEventThumbnailImage[0] &&
                                card.keyEventThumbnailImage[0].link != ""
                                ? process.env.REACT_APP_IMAGE_URL +
                                card.keyEventThumbnailImage[0].link
                                : card.keyEventThumbnailImage[0].url
                            }
                            alt="Setellite block"
                          />
                        )}
                      </div>
                      <div className="sd_mainstreet_block_des">
                        {/* <h2 className="sd_textuppercase">{card.eventTitle}</h2> */}
                        {card.eventDisplayTemplate == "Offer"
                          ? card && (
                            <h2
                              className={
                                card.eventDate == ""
                                  ? "sd_offer_space"
                                  : "sd_offer"
                              }
                            >
                              {card.offerTitle}
                            </h2>
                          )
                          : card && (
                            <h2
                              className={
                                card.eventDate == ""
                                  ? "sd_offer_space"
                                  : "sd_offer"
                              }
                            >
                              {card.eventTitle}
                            </h2>
                          )}
                        {card.eventDisplayTemplate == "Offer" &&
                        //   card.eventDate == "" ? (
                        //   <span>All Festival</span>
                        // ) :
                        card && card.eventDate == "" ? (
                          ""
                        ) :
                        //  card &&
                        //   card.eventDate[0] &&
                        //   card.eventDate[0].dateKey &&
                        //   card.eventDate[0].dateKey != "" ? (
                        //   <span>
                        //     {card &&
                        //       card.eventDate[0] &&
                        //       card.eventDate[0].dateKey &&
                        //       card.eventDate[0].dateKey} 
                        card.availableThroughoutFestival === true ? (
                          <span> All Festival
                              {/* {" "} */}
                             {/* { getZone()} */}
                              {" "}
                              {  card &&
                              card.eventDate[0] && (
                              card.eventDate[0].eventParticularTime[0].availability == "1" ?
                              <span className="sd_textuppercase in-person ml-2"> | IN-PERSON</span> 
                                :
                                card.eventDate[0].eventParticularTime[0].availability == "2" &&
                                <span className="ml-2"> | ONLINE </span>

                            )}
                            {this.state[card._id] ? (
                              <button
                                className="sd_textuppercase"
                                onClick={() =>
                                  this.getRemoveScheduleEvent(
                                    card._id,
                                    card.eventDate[0]
                                      ? card.eventDate[0].eventParticularTime[0]
                                        .start
                                      : ""
                                  )
                                }
                              >
                                <img
                                  src={Righticon}
                                  alt="plus"
                                  className="home-right-btn"
                                />
                              </button>
                            ) : (
                              <button
                                onClick={() =>
                                  this.getAddScheduleEvent(
                                    card._id,
                                    card.eventDate[0]
                                      ? card.eventDate[0].eventParticularTime[0]
                                        .start
                                      : "",
                                    card.eventDisplayTemplate
                                  )
                                }
                              >
                                <img src={Plus} alt="Plus" />
                              </button>
                            )}
                          </span>
                        ) : (
                          <span className="sd_textuppercase">
                            {TimezoneConverter(moment
                              .utc(
                                card &&
                                card.eventDate[0] &&
                                card.eventDate[0].eventParticularTime[0].start
                              )
                              .local()
                            ,"hh:mm A ")}{" "}
                            -{" "}
                            {TimezoneConverter(moment
                              .utc(
                                card &&
                                card.eventDate[0] &&
                                card.eventDate[0].eventParticularTime[0].end
                              )
                              .local()
                             ,"hh:mm A")} {" "}
                              { getZone()}
                             {" "}
                              {  card &&
                              card.eventDate[0] && (
                              card.eventDate[0].eventParticularTime[0].availability == "1" ?
                              <span className="sd_textuppercase in-person ml-2"> | IN-PERSON</span> 
                                :
                                card.eventDate[0].eventParticularTime[0].availability == "2" &&
                                <span className="ml-2"> | ONLINE </span>

                            )}
                            {this.state[card._id] ? (
                              <button
                                className="sd_textuppercase"
                                onClick={() =>
                                  this.getRemoveScheduleEvent(
                                    card._id,
                                    card.eventDate[0]
                                      ? card.eventDate[0].eventParticularTime[0]
                                        .start
                                      : ""
                                  )
                                }
                              >
                                <img
                                  src={Righticon}
                                  alt="plus"
                                  className="home-right-btn"
                                />
                              </button>
                            ) : (
                              <button
                                onClick={() =>
                                  this.getAddScheduleEvent(
                                    card._id,
                                    card.eventDate[0]
                                      ? card.eventDate[0].eventParticularTime[0]
                                        .start
                                      : "",
                                    card.eventDisplayTemplate
                                  )
                                }
                              >
                                <img src={Plus} alt="Plus" />
                              </button>
                            )}
                          </span>
                        )}
                        {card.eventDisplayTemplate == "Offer" ? (
                          <p
                            className="paragraph_span_16 mainstreet_span"
                            dangerouslySetInnerHTML={{
                              __html: card.offerDescription,
                            }}
                          />
                        ) : (
                          <p
                            className="paragraph_span_16 mainstreet_span"
                            dangerouslySetInnerHTML={{
                              __html: card.eventDescription,
                            }}
                          />
                        )}
                        <Link
                          to={"/panel-view/" + card._id}
                          className="sd_textcapitalize"
                        >
                          Learn More
                        </Link>
                      </div>
                    </div>
                  )
              )}
          </div>
        </section>
        {this.state.loginPrompt && (
          <FavoriteCard userLogin={this.removePopup} />
        )}
        {/* footer */}
        <Footer />
      </section>
    );
  }
}
