import React, { Component } from "react";
// layout
import Header from "../layout/header";
import Footer from "../layout/footer";
//Carousel
import { Carousel } from "react-responsive-carousel";
import Whiteplus from "../images/whiteplus.png";
//moment
import moment from "moment";
// video
import VideoPlayer from "../components/VideoPlayer";
// services
import service from "../common/service";
import Video from "../images/video1.mp4";
import Righticon from "../images/tick_icon_white.svg";
import Loader from "../components/loader/loader";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const videoExtensions = [
  "3g2",
  "3gp",
  "aaf",
  "asf",
  "avchd",
  "avi",
  "drc",
  "flv",
  "m2v",
  "m4p",
  "m4v",
  "mkv",
  "mng",
  "mov",
  "mp2",
  "mp4",
  "mpe",
  "mpeg",
  "mpg",
  "mpv",
  "mxf",
  "nsv",
  "ogg",
  "ogv",
  "qt",
  "rm",
  "rmvb",
  "roq",
  "svi",
  "vob",
  "webm",
  "wmv",
  "yuv",
];

let sub;
let timer = null;
class ScreeningView extends Component {
  constructor(props) {
    super(props);
    this.apiServe = new service();
    this.state = {
      checkVideo: false,
      loadData: false,
      checkIsfav: this.props.frontData.isFavourite,
    };

    this.checkIsVideo = this.checkIsVideo.bind(this);
    this.changeCarousal = this.changeCarousal.bind(this);
    this.playerVideo = React.createRef();
    document.querySelector("body").scrollTo({ top: 0, behavior: "smooth" });
  }

  componentWillUnmount = () => {
    sub.unsubscribe();
    clearTimeout(timer);
  };

  getAddScheduleEvent = () => {
    let particularUser =
      cookies.get("SundanceNewUser") || cookies.get("particularNewUser");
    if (!particularUser) {
      return this.props.isuserLogin();
    }
    this.apiServe.sendevent("FestivalVillage|Event|addToFavourite", {
      eventId: this.props.frontData.event._id,
      date: this.props.frontData.event.eventDate[0].eventParticularTime[0]
        .start,
      eventType: this.props.frontData.event.eventDisplayTemplate,
    });
    this.setState({
      loadData: true,
    });
  };
  getRemoveScheduleEvent = () => {
    this.apiServe.sendevent("FestivalVillage|Event|removeFavourite", {
      eventId: this.props.frontData.event._id,
      date: this.props.frontData.event.eventDate[0].eventParticularTime[0]
        .start,
    });
    this.setState({
      loadData: true,
    });
  };

  getJoinButton = () => {
 
    this.apiServe.sendevent("FestivalVillage|Event|joinEvent", {
      eventId: this.props.frontData.event._id,
    });
  };
  componentDidMount = () => {
    sub = this.apiServe.getevent().subscribe((response) => {
      switch (response.event) {
        case "FestivalVillage|Event|addToFavourite":
          if (response.error) {
          } else {
            this.setState({
              checkIsfav: true,
              loadData: false,
            });
          }
          break;
        case "FestivalVillage|Event|removeFavourite":
          if (response.error) {
          } else {
            this.setState({
              checkIsfav: false,
              loadData: false,
            });
          }
          break;
        case "FestivalVillage|Event|joinEvent":
          if (response.error) {
          } else {
            
          }
          break;
        default:
          break;
      }
    });
  };

  getFileExtension(filename) {
    return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined;
  }

  checkIsVideo(filePath) {
    var getExtension = this.getFileExtension(filePath);
    return videoExtensions.includes(getExtension);
  }
  checkisIframe(url) {
    var expVimeo = new RegExp(/(vimeo\.com)/);
    var expYoutube = new RegExp(/(youtu\.be|youtube\.com)/);

    var expVimeoTest = expVimeo.test(url);
    var expYoutubeTest = expYoutube.test(url);

    if (expVimeoTest || expYoutubeTest) {
      return true;
    } else {
      return false;
    }
  }
  pauseYoutube() {
    var video = document.querySelectorAll('iframe[src*="youtube.com"]');
    video.forEach((element) => {
      element.contentWindow.postMessage(
        '{"event":"command","func":"' + "pauseVideo" + '","args":""}',
        "*"
      );
    });
  }
  changeCarousal(index) {
    if (this.playerVideo && this.playerVideo.current) {
      this.playerVideo.current.pause();
    }
    var video = document.querySelectorAll('iframe[src*="youtube.com"]');
    if (video.length > 0) {
      this.pauseYoutube();
    }
  }

  render() {
    const { loadData } = this.state;
    return (
      <>
        <section className="sd_GeneralView_section" className="sd_home_page">
          {/* Header */}
          <Header history={this.props.history}/>
          {/* GeneralView hero header */}
          <section className="sd_GeneralView">
            <div className="sd_contaniner">
              <div className="sd_ScreeningView_header">
                <h1 className="sd_textuppercase">
                  {this.props.frontData &&
                    this.props.frontData.event &&
                    this.props.frontData.event.eventTitle}
                </h1>
                <div className="sd_ScreeningView_inner">
                  {/* <p className="sd_textuppercase">Presented by</p> */}
                  {this.props.frontData &&
                  this.props.frontData.user &&
                  this.props.frontData.user.partnerLogo &&
                  this.props.frontData.user.partnerLogo != "" ? (
                    <p className="sd_textuppercase">Presented by</p>
                  ) : (
                    ""
                  )}
                  {this.props.frontData &&
                  this.props.frontData.user &&
                  this.props.frontData.user.satelliteLogo &&
                  this.props.frontData.user.satelliteLogo != "" ? (
                    <p className="sd_textuppercase">Presented by</p>
                  ) : (
                    ""
                  )}
                  <div className="sd_ScreeningView_img">
                    {this.props.frontData.user &&
                      this.props.frontData.user.partnerLogo && (
                        <img
                          src={
                            this.props.frontData &&
                            this.props.frontData.user &&
                            this.props.frontData.user.partnerLogo != ""
                              ? `${process.env.REACT_APP_IMAGE_URL}` +
                                this.props.frontData.user.partnerLogo
                              : this.props.frontData.user &&
                                this.props.frontData.user.partnerLogoUrl
                          }
                          alt="logo"
                        />
                      )}
                    {this.props.frontData.user &&
                      this.props.frontData.user.satelliteLogo && (
                        <img
                          src={
                            this.props.frontData &&
                            this.props.frontData.user
                              .satelliteLogo != ""
                              ? `${process.env.REACT_APP_IMAGE_URL}` +
                                this.props.frontData.user
                                  .satelliteLogo
                              : this.props.frontData.user &&
                                this.props.frontData.user
                                  .satelliteLogo
                          }
                          alt="logo"
                        />
                      )}
                  </div>
                </div>
                <div className="sd_Schedule">
                  {this.props.frontData &&
                  this.props.frontData.event &&
                  this.props.frontData.event.eventDate == "" ? (
                    ""
                  ) : this.props.frontData.event &&
                    this.props.frontData.event.availableThroughoutFestival ==
                      true ? (
                    <h3 className="sd_textcapitalize">
                      {" "}
                      All Festival
                    </h3>
                  ) : (
                    <h3 className="sd_textuppercase">
                      {moment
                        .utc(
                          this.props.frontData.event &&
                            this.props.frontData.event.eventDate[0] &&
                            this.props.frontData.event.eventDate[0].date,
                        )
                        .local()
                        .format("dddd, MMM DD")}

                      <span>
                        {moment
                          .utc(
                            this.props.frontData.event &&
                              this.props.frontData.event.eventDate[0]
                                .eventParticularTime[0].start,
                          )
                          .local()
                          .format("hh:mm A ")}{" "}
                        -{" "}
                        {moment
                          .utc(
                            this.props.frontData.event &&
                              this.props.frontData.event.eventDate[0]
                                .eventParticularTime[0].end
                          )
                          .local()
                          .format("hh:mm A")}
                      </span>
                    </h3>
                  )}
                  {this.state.checkIsfav == false ? (
                    <>
                      {loadData && <Loader />}
                      <button
                        className="sd_textuppercase"
                        onClick={this.getAddScheduleEvent}
                      >
                        <img src={Whiteplus} alt="plus" />
                        Favorite
                      </button>
                    </>
                  ) : this.state.checkIsfav == true ? (
                    <>
                      {loadData && <Loader />}
                      <button
                        className="sd_textuppercase"
                        onClick={this.getRemoveScheduleEvent}
                      >
                        <img
                          src={Righticon}
                          alt="plus"
                          className="right-icon"
                        />
                        Favorited
                      </button>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <p
                  className="paragraph_span_22"
                  dangerouslySetInnerHTML={{
                    __html:
                      this.props.frontData &&
                      this.props.frontData.event &&
                      this.props.frontData.event.eventDescription,
                  }}
                />

                {moment().isBetween(
                  moment(
                    this.props.frontData &&
                      this.props.frontData.event &&
                      this.props.frontData.event.eventDate[0]
                        .eventParticularTime[0].start
                  ).format("dddd, MMMM D, YYYY h:mm:ss A"),
                  moment(
                    this.props.frontData &&
                      this.props.frontData.event &&
                      this.props.frontData.event.eventDate[0]
                        .eventParticularTime[0].end
                  ).format("dddd, MMMM D, YYYY h:mm:ss A")
                ) ||  (this.props.frontData && this.props.frontData.event && this.props.frontData.event.availableThroughoutFestival)  ? (
                  <a
                    onClick={this.getJoinButton}
                    href={
                      this.props.frontData.event &&
                      this.props.frontData.event.CTA[0] &&
                      this.props.frontData.event.CTA[0].url
                    }
                    className="sd_textuppercase"
                    target="_blank"
                  >
                    {this.props.frontData.event &&
                      this.props.frontData.event.CTA[0] &&
                      this.props.frontData.event.CTA[0].title}
                  </a>
                ) : null}
              </div>
            </div>
          </section>
          {/* video */}
          <section>
            <div className="sd_video_sec">
              {this.checkisIframe(
                this.props.frontData &&
                  this.props.frontData.event &&
                  this.props.frontData.event.keyEventThumbnailImage &&
                  this.props.frontData.event.keyEventThumbnailImage[0].link !=
                    ""
                  ? this.props.frontData.event.keyEventThumbnailImage[0].link
                  : this.props.frontData &&
                      this.props.frontData.event &&
                      this.props.frontData.event.keyEventThumbnailImage &&
                      this.props.frontData.event.keyEventThumbnailImage[0].url
              ) ? (
                <div className="sd_video_wpr iframeContainer">
                  <iframe
                    src={
                      this.props.frontData &&
                      this.props.frontData.event &&
                      this.props.frontData.event.keyEventThumbnailImage &&
                      this.props.frontData.event.keyEventThumbnailImage[0]
                        .link != ""
                        ? `${process.env.REACT_APP_IMAGE_URL}` +
                          this.props.frontData.event.keyEventThumbnailImage[0]
                            .link
                        : this.props.frontData &&
                          this.props.frontData.event &&
                          this.props.frontData.event.keyEventThumbnailImage &&
                          this.props.frontData.event.keyEventThumbnailImage[0]
                            .url
                    }
                  ></iframe>
                </div>
              ) : this.checkIsVideo(
                  this.props.frontData &&
                    this.props.frontData.event &&
                    this.props.frontData.event.keyEventThumbnailImage &&
                    this.props.frontData.event.keyEventThumbnailImage[0].link !=
                      ""
                    ? this.props.frontData.event.keyEventThumbnailImage[0].link
                    : this.props.frontData &&
                        this.props.frontData.event &&
                        this.props.frontData.event.keyEventThumbnailImage &&
                        this.props.frontData.event.keyEventThumbnailImage[0].url
                ) ? (
                <div className="sd_video_wpr">
                  <VideoPlayer
                    videoSrc={
                      this.props.frontData &&
                      this.props.frontData.event &&
                      this.props.frontData.event.keyEventThumbnailImage &&
                      this.props.frontData.event.keyEventThumbnailImage[0]
                        .link != ""
                        ? `${process.env.REACT_APP_IMAGE_URL}` +
                          this.props.frontData.event.keyEventThumbnailImage[0]
                            .link
                        : this.props.frontData &&
                          this.props.frontData.event &&
                          this.props.frontData.event.keyEventThumbnailImage &&
                          this.props.frontData.event.keyEventThumbnailImage[0]
                            .url
                    }
                    autoplay={false}
                    loop={true}
                  />
                </div>
              ) : (
                <div>
                  <img
                    src={
                      this.props.frontData &&
                      this.props.frontData.event &&
                      this.props.frontData.event.keyEventThumbnailImage &&
                      this.props.frontData.event.keyEventThumbnailImage[0]
                        .link != ""
                        ? `${process.env.REACT_APP_IMAGE_URL}` +
                          this.props.frontData.event.keyEventThumbnailImage[0]
                            .link
                        : this.props.frontData &&
                          this.props.frontData.event &&
                          this.props.frontData.event.keyEventThumbnailImage &&
                          this.props.frontData.event.keyEventThumbnailImage[0]
                            .url
                    }
                  />
                </div>
              )}
            </div>
          </section>
          <section className="sd_Event_Details_panal">
            <div className="sd_contaniner">
              <div className="sd_event_des">
                <div className="sd_event_detail_left">
                  <h2 className="sd_textuppercase">Event Details</h2>
                  <p
                    className="paragraph_span_16"
                    dangerouslySetInnerHTML={{
                      __html:
                        this.props.frontData &&
                        this.props.frontData.event &&
                        this.props.frontData.event.eventDetail,
                    }}
                  />
                </div>
                <div className="sd_event_detail_right">
                  <h2 className="sd_textuppercase">TIME & ACTIVITIES</h2>
                  <div className="sd_main_time">
                    {this.props.frontData &&
                    this.props.frontData.event &&
                    this.props.frontData.event.availableThroughoutFestival ==
                      true ? (
                      <div className="sd_detail_time">
                        <span className="detail_span">All festival</span>
                      </div>
                    ) : (
                      this.props.frontData &&
                      this.props.frontData.event &&
                      this.props.frontData.event.eventDate &&
                      this.props.frontData.event.eventDate[0] &&
                      this.props.frontData.event.eventDate[0].time.map(
                        (item, i) => (
                          <div className="sd_detail_time">
                            <span>
                              {moment
                                .utc(item.start)
                                .local()
                                .format("hh:mm A ")}{" "}
                              -{" "}
                              {moment
                                .utc(item.end)
                                .local()
                                .format("hh:mm A")}
                            </span>
                            <h3>{item.activityDetails}</h3>
                          </div>
                        )
                      )
                    )}
                  </div>
                </div>
              </div>
              {/* Carousel */}
              <div className="sd_main_GeneralView_images">
                {this.props.frontData.event &&
                this.props.frontData.event.frontDetailImageOrVideo == "" ? (
                  ""
                ) : (
                  <div className="Carousel_video_screening">
                    <Carousel
                      showArrows={true}
                      infiniteLoop={true}
                      autoPlay={false}
                      swipeable={true}
                      emulateTouch={true}
                      dynamicHeight={true}
                      onChange={this.changeCarousal}
                    >
                      {this.props.frontData.event &&
                        this.props.frontData.event.frontDetailImageOrVideo.map(
                          (item, i) =>
                            this.checkisIframe(
                              item.link != "" ? item.link : item.url
                            ) ? (
                              <div className="sd_video_wpr iframeContainer">
                                <iframe
                                  src={
                                    item.link != ""
                                      ? `${process.env.REACT_APP_IMAGE_URL}` +
                                        item.link +
                                        "?enablejsapi=1&version=3&playerapiid=ytplayer"
                                      : item.url +
                                        "?enablejsapi=1&version=3&playerapiid=ytplayer"
                                  }
                                ></iframe>
                              </div>
                            ) : this.checkIsVideo(
                                item.link != "" ? item.link : item.url
                              ) ? (
                              <div className="sd_video_wpr">
                                <VideoPlayer
                                  videoSrc={
                                    item.link != ""
                                      ? `${process.env.REACT_APP_IMAGE_URL}` +
                                        item.link
                                      : item.url
                                  }
                                  autoplay={false}
                                  loop={true}
                                  ref={this.playerVideo}
                                />
                              </div>
                            ) : (
                              <div>
                                <img
                                  src={
                                    item.link != ""
                                      ? `${process.env.REACT_APP_IMAGE_URL}` +
                                        item.link
                                      : item.url
                                  }
                                />
                              </div>
                            )
                        )}
                    </Carousel>
                  </div>
                )}

                <div className="sd_ScreeningView_Carousel">
                  <div className="sd_ScreeningView_Carousel_inner">
                    <h2 className="sd_textuppercase">
                      {this.props.frontData.event &&
                        this.props.frontData.event.CTA[1] &&
                        this.props.frontData.event.CTA[1].description}
                    </h2>
                    <a
                      href={
                        this.props.frontData.event &&
                        this.props.frontData.event.CTA[1] &&
                        this.props.frontData.event.CTA[1].url
                      }
                      className="sd_textuppercase"
                      target="_blank"
                    >
                      {this.props.frontData.event &&
                        this.props.frontData.event.CTA[1] &&
                        this.props.frontData.event.CTA[1].title}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* footer */}
          <Footer />
        </section>
      </>
    );
  }
}

export default ScreeningView;
