import React, { Component } from "react";
import Routers from "./routes";
import {
  BrowserRouter
} from "react-router-dom";
import history from "./history";
// import Idle from 'react-idle'
import Cookies from "universal-cookie";

const cookies = new Cookies();

class App extends Component {

  constructor() {
    super();
    this.state = {
        whiteScreenLoader:true

    };}
  componentDidMount() {
    //redirection for elb url to domain name 
    setTimeout(() => {
      if(window.location.host !== 'festival.sundance.org'){
        window.location.href = process.env.REACT_APP_HOME_URL + window.location.pathname.substring(1)
      }
    }, 1000);
    
    // --------------- ↓↓↓↓↓↓ remove on 9/12 release ↓↓↓↓↓↓  ------------------
    // let ipList = ['122.169.113.25','27.54.182.197','72.181.125.46','107.135.63.168','38.102.20.80','67.164.250.253','65.130.63.83','98.148.10.72','108.185.213.213','66.8.194.194','76.93.23.30','138.207.158.153','98.228.244.74','76.119.190.178','136.36.147.93','67.211.141.224']
    let ipList = JSON.parse(process.env.REACT_APP_WHITELIST_IP) || []
    if (ipList.length > 0) {
      
    let CurrIp 
    this.getIP().then(data => CurrIp = data.ip).then(()=>{

      console.log(':::::::',ipList.includes(CurrIp))
      if((window.location.host.includes("festival.sundance.org") || window.location.host.includes("localhost") || window.location.host.includes("digital.sundance.org") || window.location.host.includes("staginghome.sundance.org")) && !ipList.includes(CurrIp)) {
        window.location.href = process.env.REACT_APP_HOME_URL;
      }else{
        this.setState({ whiteScreenLoader:false })
      }
    }).catch((e)=>{
      window.location.href = process.env.REACT_APP_HOME_URL;
      this.setState({ whiteScreenLoader:false })
    })
    } else this.setState({ whiteScreenLoader: false })

    
    // --------------- ↑↑↑↑↑↑ remove on 9/12 release ↑↑↑↑↑↑  ------------------
    
    setInterval(function() {
      let User = cookies.get("SundanceNewUser") || cookies.get("particularNewUser")
      if (User) {
        let currDate = new Date();
        let Time = process.env.REACT_APP_IDLE_LOGOUT_TIME
        currDate.setTime(currDate.getTime() + (JSON.parse(Time)));
        cookies.set("SundanceNewUser", User, {domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/", expires: currDate });
      }
    }, 10000);

    
  }
  async getIP() {
      this.setState({
        whiteScreenLoader:true
      })
      const response = await fetch('https://api.ipify.org/?format=json');
      const data = await response.json();
      return response.status === 200 ? data : "err"
  }
  logout = () => {  
    let LoginUser = cookies.get("SundanceNewUser") || cookies.get("particularNewUser")
    if (LoginUser && LoginUser.token) {
        // eslint-disable-next-line
      document.cookie = "SundanceNewUser" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      cookies.remove("SundanceNewUser", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
  
      // eslint-disable-next-line
      document.cookie = "particularNewUser" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      cookies.remove("particularNewUser", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
  
      let Zone = localStorage.getItem('TIMEZONE')
      localStorage.clear();
      if(Zone) localStorage.setItem('TIMEZONE' , Zone)
      window.open(process.env.REACT_APP_HOME_URL + 'sign-in',"_self")
    }    
  };
  render() {

    return (
      <BrowserRouter basename="village">
        {/* <Idle timeout={process.env.REACT_APP_IDLE_LOGOUT_TIME} render={({ idle }) =><h1>{idle ? this.logout() : "" }</h1>} /> */}
        {this.state.whiteScreenLoader ? <></> : <Routers history={history}/>}
      </BrowserRouter>
    )
  }
}

export default App;
