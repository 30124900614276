import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class FavoriteCard extends Component {
  constructor(props) {
    super(props);
  }

  closePopup = () =>{
    this.props.userLogin()
  }

 
  
  render() {
    return (
      <section>
          <div className="sd_FavoriteCard_sec">
          <div className="sd_FavoriteCard_inner">
            <div className="sd_FavoriteCard_des">
              <p>
                Please
                <a href={process.env.REACT_APP_HOME_URL + "sign-in"}> Sign In </a>
                or
                <a href={process.env.REACT_APP_HOME_URL + "create-account"}> Register </a>
                in order to favorite a film and build your schedule.
              </p>
              <div className="sd_FavoriteCard_button">
                <button onClick={this.closePopup}>Ok</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
