import React, { Component } from "react";
//layout
import Header from "../layout/header";
import Footer from "../layout/footer";
import Photo from "../images/photo.png";
// services
import service from "../common/service";

let sub;
let timer = null;
class OfferView extends Component {
  constructor(props) {
    super(props);
    this.apiServe = new service();
    this.state = {
      panelData: "",
      frontData: null,
    };
    document.querySelector('body').scrollTo({top: 0, behavior: 'smooth' }); 
  }

  componentWillUnmount = () => {
    sub.unsubscribe();
    clearTimeout(timer);
  };
  componentDidMount() {

    if (!this.props.match.params.id) this.props.history.push("/");
    this.getHomeData();
    this.getPartnerDataFront();
    sub = this.apiServe.getevent().subscribe((response) => {
      switch (response.event) {
        case "FestivalVillage|Event|getEventById":
          if (response.error) {
          } else {
            this.setState({
              panelData: response && response.data,
              addedBy:
                response && response.data.partnerData && response.data.addedBy,
            });
           
            this.getPartnerData(
              response && response.data && response.data.addedBy
            );
          }

          break;
        case "FestivalVillage|Partner|getPartnerById":
          if (response.error) {
          } else {
            this.setState({
              PartnerLogoData: response.data,
            });

            
          }

          break;
        case "FestivalVillage|Event|getEventByIdFront":
          if (response.error) {
          } else {
            this.setState({
              frontData: response.data,
            });
           
          }
          break;
        default:
          break;
      }
    });
  }
  getHomeData() {
    this.apiServe.sendevent("FestivalVillage|Event|getEventById", {
      id: this.props.match.params.id,
    });
  }
  getPartnerData(id) {
    this.apiServe.sendevent("FestivalVillage|Partner|getPartnerById", {
      id: id,
    });
  }
  getPartnerDataFront() {
    this.apiServe.sendevent("FestivalVillage|Event|getEventByIdFront", {
      id: this.props.match.params.id,
    });
  }
  checkisIframe(url){
    var expVimeo = new RegExp(/(vimeo\.com)/);
    var expYoutube = new RegExp(/(youtu\.be|youtube\.com)/);
    var a  = url.match("/http:\/\/(?:www.)?(?:(vimeo).com\/(.*)|(youtube).com\/watch\?v=(.*?)&)/");
    if (expVimeo || expYoutube){ 
      return true;
    }else{
      return false;
    }
  }
  render() {
    const { panelData, viewType, PartnerLogoData, frontData } = this.state;
    return (
      <>
        <section className="sd_GeneralView_section" className="sd_home_page">
          {/* Header */}
          <Header history={this.props.history}/>
          {/* GeneralView hero header */}
          {
            frontData && 
            <>
            <section className="sd_GeneralView">
            <div className="sd_contaniner">
              <div className="sd_GeneralView_header">
                <div className="sd_GeneralView_left">
                  <h1 className="sd_textuppercase">
                    {frontData && frontData.event && frontData.event.offerTitle}
                  </h1>
                  <div className="sd_GeneralView_inner">
                    <p className="sd_textuppercase">Presented by</p>
                    <div className="sd_GeneralView_img">
                      <img className={frontData && frontData.user && frontData.user.partnerLogo ? '' : 'hideImage'}
                        src={
                          frontData && 
                          frontData.user && frontData.user.partnerLogo != ""
                            ? `${process.env.REACT_APP_IMAGE_URL}` +
                              frontData.user.partnerLogo
                            : frontData && frontData.user && frontData.user.partnerLogoUrl
                        }
                        alt="logo"
                      />
                    </div>
                  </div>
                  <p
                    className="paragraph_span_22 general_span"
                    dangerouslySetInnerHTML={{
                      __html:
                        frontData &&
                        frontData.event &&
                        frontData.event.offerDescription,
                    }}
                  />
                  <a
                    href={
                      frontData &&
                      frontData.event &&
                      frontData.event.offerOutboundUrl
                    }
                    className="sd_textuppercase"
                    target="_blank"
                  >Learn More</a>
                </div>
                <div className="sd_GeneralView_right ">
                  <div className="offer-img">
                    <img
                      src={
                        frontData &&
                        frontData.event &&
                        frontData.event.keyEventThumbnailImage[0].link != ""
                          ? `${process.env.REACT_APP_IMAGE_URL}` +
                            frontData.event.keyEventThumbnailImage[0].link
                          : frontData.event &&
                            frontData.event.keyEventThumbnailImage[0].url
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
            <section className="sd_Event_Details">
            <div className="sd_contaniner">
              <div className="sd_event_des">
                <div className="sd_event_detail_left">
                  <h2 className="sd_textuppercase">Details</h2>
                  <p
                    className="paragraph_span_16"
                    dangerouslySetInnerHTML={{
                      __html:
                        frontData &&
                        frontData.event &&
                        frontData.event.offerDetail,
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
            </>
          }
          {/* footer */}
          <Footer />
        </section>
      </>
    );
  }
}

export default OfferView;
