import React, { Component } from "react";
//layout
import Header from "../layout/header";
import Footer from "../layout/footer";
import VideoPlayer from "./VideoPlayer";

const videoExtensions = [
  "3g2",
  "3gp",
  "aaf",
  "asf",
  "avchd",
  "avi",
  "drc",
  "flv",
  "m2v",
  "m4p",
  "m4v",
  "mkv",
  "mng",
  "mov",
  "mp2",
  "mp4",
  "mpe",
  "mpeg",
  "mpg",
  "mpv",
  "mxf",
  "nsv",
  "ogg",
  "ogv",
  "qt",
  "rm",
  "rmvb",
  "roq",
  "svi",
  "vob",
  "webm",
  "wmv",
  "yuv",
];

class OfferView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkVideo: false,
    };

    this.checkIsVideo = this.checkIsVideo.bind(this);
    document.querySelector("body").scrollTo({ top: 0, behavior: "smooth" });
  }
  getFileExtension(filename) {
    return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined;
  }

  checkIsVideo(filePath) {
    var getExtension = this.getFileExtension(filePath);
    return videoExtensions.includes(getExtension);
  }
  checkisIframe(url) {
    var expVimeo = new RegExp(/(vimeo\.com)/);
    var expYoutube = new RegExp(/(youtu\.be|youtube\.com)/);
    var expVimeoTest = expVimeo.test(url);
    var expYoutubeTest = expYoutube.test(url);
    if (expVimeoTest || expYoutubeTest) {
      return true;
    } else {
      return false;
    }
  }
  render() {
    return (
      <>
        <section className="sd_GeneralView_section" className="sd_home_page">
          {/* Header */}
          <Header history={this.props.history}/>
          {/* GeneralView hero header */}
          {this.props.frontData && (
            <section className="sd_GeneralView">
              <div className="sd_contaniner">
                <div className="sd_GeneralView_header sd_offer-view">
                  <div className="sd_GeneralView_left">
                    <h1 className="sd_textuppercase">
                      {this.props.frontData.event && this.props.frontData.event.offerTitle}
                    </h1>
                    <div className="sd_GeneralView_inner sd_offer_logo  ">
                      {/* <p className="sd_textuppercase">Presented by</p> */}
                      {this.props.frontData &&
                      this.props.frontData.user && this.props.frontData.user.partnerLogo &&
                      this.props.frontData.user.partnerLogo != "" ? (
                        <p className="sd_textuppercase">Presented by</p>
                      ) : (
                        ""
                      )}
                      {this.props.frontData &&
                      this.props.frontData.user && this.props.frontData.user.satelliteLogo &&
                      this.props.frontData.user.satelliteLogo !=
                        "" ? (
                        <p className="sd_textuppercase">Presented by</p>
                      ) : (
                        ""
                      )}
                      <div className="sd_GeneralView_img ">
                        {this.props.frontData.user &&
                          this.props.frontData.user.partnerLogo && (
                            <img
                              src={
                                this.props.frontData &&
                                this.props.frontData.user.partnerLogo != ""
                                  ? `${process.env.REACT_APP_IMAGE_URL}` +
                                    this.props.frontData.user.partnerLogo
                                  : this.props.frontData.user &&
                                    this.props.frontData.user.partnerLogoUrl
                              }
                              alt="logo"
                            />
                          )}
                        {this.props.frontData.user &&
                          this.props.frontData.user
                            .satelliteScreenThumbnail && (
                            <img
                              src={
                                this.props.frontData &&
                                this.props.frontData.user
                                  .satelliteLogo != ""
                                  ? `${process.env.REACT_APP_IMAGE_URL}` +
                                    this.props.frontData.user
                                      .satelliteLogo
                                  : this.props.frontData.user &&
                                    this.props.frontData.user
                                      .satelliteLogo
                              }
                              alt="logo"
                            />
                          )}
                      </div>
                    </div>
                    
                    <p
                      className="paragraph_span_22 general_span"
                      dangerouslySetInnerHTML={{
                        __html: this.props.frontData.event && this.props.frontData.event.offerDescription,
                      }}
                    />
                    <a
                      href={this.props.frontData.event && this.props.frontData.event.offerOutboundUrl}
                      className="sd_textuppercase"
                      target="_blank"
                    >
                      Learn More
                    </a>
                  </div>
                  <div className="sd_GeneralView_right">
                    <div>
                      {this.checkisIframe(
                        this.props.frontData &&
                        this.props.frontData.event &&
                          this.props.frontData.event
                            .keyEventThumbnailImage[0] &&
                          this.props.frontData.event.keyEventThumbnailImage[0]
                            .link != ""
                          ?  this.props.frontData.event.keyEventThumbnailImage[0]
                              .link
                          : this.props.frontData &&
                          this.props.frontData.event &&
                            this.props.frontData.event
                              .keyEventThumbnailImage[0] && this.props.frontData.event.keyEventThumbnailImage[0]
                              .url
                      ) ? (
                        <div className="sd_video_wpr iframeContainer">
                          <iframe
                            src={
                              this.props.frontData &&
                              this.props.frontData.event &&
                              this.props.frontData.event
                                .keyEventThumbnailImage[0] &&
                              this.props.frontData.event
                                .keyEventThumbnailImage[0].link != ""
                                ? `${process.env.REACT_APP_IMAGE_URL}` +
                                  this.props.frontData.event
                                    .keyEventThumbnailImage[0].link
                                :this.props.frontData &&
                                this.props.frontData.event &&
                                  this.props.frontData.event
                                    .keyEventThumbnailImage[0] && this.props.frontData.event
                                    .keyEventThumbnailImage[0].url
                            }
                          ></iframe>
                        </div>
                      ) : this.checkIsVideo(
                        this.props.frontData &&
                          this.props.frontData.event &&
                            this.props.frontData.event
                              .keyEventThumbnailImage[0] &&
                            this.props.frontData.event.keyEventThumbnailImage[0]
                              .link != ""
                            ? `${process.env.REACT_APP_IMAGE_URL}` +
                                this.props.frontData.event
                                  .keyEventThumbnailImage[0].link
                            : this.props.frontData &&
                            this.props.frontData.event &&
                              this.props.frontData.event
                                .keyEventThumbnailImage[0] &&this.props.frontData.event
                                .keyEventThumbnailImage[0].url
                        ) ? (
                        <div className="sd_video_wpr">
                          <VideoPlayer
                            videoSrc={
                              this.props.frontData &&
                              this.props.frontData.event &&
                              this.props.frontData.event
                                .keyEventThumbnailImage[0] &&
                              this.props.frontData.event
                                .keyEventThumbnailImage[0].link != ""
                                ? `${process.env.REACT_APP_IMAGE_URL}` +
                                  this.props.frontData.event
                                    .keyEventThumbnailImage[0].link
                                : this.props.frontData &&
                                this.props.frontData.event &&
                                  this.props.frontData.event
                                    .keyEventThumbnailImage[0] &&this.props.frontData.event
                                    .keyEventThumbnailImage[0].url
                            }
                            autoplay={false}
                            loop={true}
                          />
                        </div>
                      ) : (
                        <div className="offer-img">
                          <img
                            src={
                              this.props.frontData &&
                              this.props.frontData.event &&
                              this.props.frontData.event
                                .keyEventThumbnailImage[0] &&
                              this.props.frontData.event
                                .keyEventThumbnailImage[0].link != ""
                                ? `${process.env.REACT_APP_IMAGE_URL}` +
                                  this.props.frontData.event
                                    .keyEventThumbnailImage[0].link
                                :this.props.frontData &&
                                this.props.frontData.event &&
                                  this.props.frontData.event
                                    .keyEventThumbnailImage[0] &&this.props.frontData && this.props.frontData.event
                                    .keyEventThumbnailImage[0].url
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          <section className="sd_Event_Details">
            <div className="sd_contaniner">
              <div className="sd_event_des">
                <div className="sd_event_detail_left">
                  <h2 className="sd_textuppercase">Details</h2>
                  <p
                    className="paragraph_span_16"
                    dangerouslySetInnerHTML={{
                      __html:this.props.frontData &&this.props.frontData.event && this.props.frontData.event.offerDetail,
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
          {/* footer */}
          <Footer />
        </section>
      </>
    );
  }
}

export default OfferView;
