import React, { Component } from "react";
import Header from "../layout/header";
import Whiteplus from "../images/whiteplus.png";
import Footer from "../layout/footer";
import { Carousel } from "react-responsive-carousel";
// video
import VideoPlayer from "./VideoPlayer";
import moment from "moment";
// services
import service from "../common/service";
import { Link } from "react-router-dom";
import Righticon from "../images/tick_icon_white.svg";
import Loader from "../components/loader/loader";
import Cookies from "universal-cookie";
import { getuserRole, getZone, TimezoneConverter } from "../store/functions";
const cookies = new Cookies();

const videoExtensions = [
  "3g2",
  "3gp",
  "aaf",
  "asf",
  "avchd",
  "avi",
  "drc",
  "flv",
  "m2v",
  "m4p",
  "m4v",
  "mkv",
  "mng",
  "mov",
  "mp2",
  "mp4",
  "mpe",
  "mpeg",
  "mpg",
  "mpv",
  "mxf",
  "nsv",
  "ogg",
  "ogv",
  "qt",
  "rm",
  "rmvb",
  "roq",
  "svi",
  "vob",
  "webm",
  "wmv",
  "yuv",
];
let sub;
let timer = null;
class PanelView extends Component {
  constructor(props) {
    super(props);
    this.apiServe = new service();
    this.state = {
      checkVideo: false,
      loadData: false,
      checkIsfav: this.props.frontData.isFavourite,
      sDataInperson:'',
      sDataOnline:''
    };

    this.checkIsVideo = this.checkIsVideo.bind(this);
    this.changeCarousal = this.changeCarousal.bind(this);
    this.playerVideo = React.createRef();
    document.querySelector("body").scrollTo({ top: 0, behavior: "smooth" });
  }

  componentWillUnmount = () => {
    sub.unsubscribe();
    clearTimeout(timer);
  };

  getAddScheduleEvent = () => {
    let particularUser =
      cookies.get("SundanceNewUser") || cookies.get("particularNewUser");
    if (!particularUser) {
      return this.props.isuserLogin();
    }
    window.dataLayer.push({
      'event': 'favoriteEvent',
      'eventName': this.props.frontData.event?.eventTitle,
      'sponsor': this.props.frontData.user?.partnerVenueName,
      'pageCategory': 'Community Hub',
      'eventType':'Event',
      'participationMethod': '',
      'visitorType': getuserRole(particularUser?.user?.role || '')
    });
    this.apiServe.sendevent("FestivalVillage|Event|addToFavourite", {
      eventId: this.props.frontData.event._id,
      date: this.props.frontData.event.eventDate[0].eventParticularTime[0]
        .start,
      eventType: this.props.frontData.event.eventDisplayTemplate,
    });

    this.setState({
      loadData: true,
    });
  };
  getRemoveScheduleEvent = () => {
    this.apiServe.sendevent("FestivalVillage|Event|removeFavourite", {
      eventId: this.props.frontData.event._id,
      date: this.props.frontData.event.eventDate[0].eventParticularTime[0]
        .start,
    });
    this.setState({
      loadData: true,
    });
  };
  getJoinButton = () => {
    
    this.apiServe.sendevent("FestivalVillage|Event|joinEvent", {
      eventId: this.props.frontData.event._id,
    });
  };
  componentDidMount = () => {
    const eData = this.props.frontData.event
    let image = []
    eData.detailImageOrVideo.length > 0 && eData.detailImageOrVideo.map(img => {
      if(img.link !== "" || img.url !== ""){
        console.log(img.link.match(/\.(jpeg|jpg|gif|png|jfif)/) != null,img.url, img.link,"??");
          if(img.link && img.link.match(/\.(jpeg|jpg|gif|png|jfif)$/) != null){
            image.push(this.apiServe.imageUrl + img.link)
          } else {
            img.url.match(/\.(jpeg|jpg|gif|png|jfif)/) != null && image.push(img.url)
          }
    }
    })
    function convertToPlain(html){
      var tempDivElement = document.createElement("div");
      tempDivElement.innerHTML = html;
      return tempDivElement.textContent || tempDivElement.innerText || "";
  }
    let panelList = [];
    eData.panelists && eData.panelists.length > 0 && eData.panelists.map(list => {
        panelList.push({"@type": "Person","name":list.name})
    })
      if(eData.eventDate[0].eventParticularTime[0].availability === "2" || eData.availableThroughoutFestival){
          const online = {
            "@context": "https://schema.org",
            "@type": "Event",
            "name": eData.eventTitle,
            "startDate": eData.eventDate[0].eventParticularTime[0].start,
            "endDate": eData.eventDate[0].eventParticularTime[0].end ,
            "eventAttendanceMode": "https://schema.org/MixedEventAttendanceMode",
            "eventStatus": "https://schema.org/EventScheduled",
            "location": {
              "@type": "VirtualLocation",
              "url": eData && eData.CTA[0] && eData.CTA[0].url
            },
            "image": image,
            "description": convertToPlain(eData.eventDescription).replace(/\n/g, " "),
            "performer": panelList,
            "organizer": {
              "@type": "Organization",
              "name": "2023 Sundance Film Festival",
              "url": process.env.REACT_APP_HOME_URL.replace('/#', '')
            }
          }
          this.setState({sDataOnline:online})
        } else {
          const inPerson = {
            "@context": "https://schema.org",
          "@type": "Event",
          "name": eData.eventTitle,
          "startDate": eData.eventDate[0].eventParticularTime[0].start,
          "endDate": eData.eventDate[0].eventParticularTime[0].end ,
          "eventAttendanceMode": "https://schema.org/MixedEventAttendanceMode",
          "eventStatus": "https://schema.org/EventScheduled",
          "location":{
              "@type": "Place",
              "name": eData.eventDate[0].eventParticularTime[0].EventLocation.locationName,
              "address": {
              "@type": "PostalAddress",
              "streetAddress": eData.eventDate[0].eventParticularTime[0].EventLocation.street,
              "addressLocality": eData.eventDate[0].eventParticularTime[0].EventLocation.city,
              "postalCode": eData.eventDate[0].eventParticularTime[0].EventLocation.zip,
              "addressRegion": eData.eventDate[0].eventParticularTime[0].EventLocation.state,
              "addressCountry": "US",
              "contactType": "Mailing Address" 
            }
          },
          "image": image,
          "description": convertToPlain(eData.eventDescription).replace(/\n/g, " "),
          "performer":panelList,
          "organizer": {
            "@type": "Organization",
            "name": "2023 Sundance Film Festival",
            "url": process.env.REACT_APP_HOME_URL.replace('/#', '')
          }
        }
        this.setState({sDataInperson:inPerson})
      }
      sub = this.apiServe.getevent().subscribe((response) => {
      switch (response.event) {
        case "FestivalVillage|Event|addToFavourite":
          if (response.error) {
          } else {
            this.setState({
              checkIsfav: true,
              loadData: false,
            });
          }
          break;
        case "FestivalVillage|Event|removeFavourite":
          if (response.error) {
          } else {
            this.setState({
              checkIsfav: false,
              loadData: false,
            });
          }
          break;
        case "FestivalVillage|Event|joinEvent":
          if (response.error) {
          } else {
            
          }
          break;
        default:
          break;
      }
    });
  };

  getFileExtension(filename) {
    return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined;
  }

  checkIsVideo(filePath) {
    var getExtension = this.getFileExtension(filePath);
    return videoExtensions.includes(getExtension);
  }
  pauseYoutube() {
    var video = document.querySelectorAll('iframe[src*="youtube.com"]');
    video.forEach((element) => {
      element.contentWindow.postMessage(
        '{"event":"command","func":"' + "pauseVideo" + '","args":""}',
        "*"
      );
    });
  }
  changeCarousal(index) {
    if (this.playerVideo && this.playerVideo.current) {
      this.playerVideo.current.pause();
    }
    var video = document.querySelectorAll('iframe[src*="youtube.com"]');
    if (video.length > 0) {
      this.pauseYoutube();
    }
  }

  checkisIframe(url) {
    var expVimeo = new RegExp(/(vimeo\.com)/);
    var expYoutube = new RegExp(/(youtu\.be|youtube\.com)/);

    var expVimeoTest = expVimeo.test(url);
    var expYoutubeTest = expYoutube.test(url);

    if (expVimeoTest || expYoutubeTest) {
      return true;
    } else {
      return false;
    }
  }

  getEventButton = () => {
    // debugger
    let Event = this.props.frontData && this.props.frontData.event && this.props.frontData.event.eventDate[0]
    let EventOnly = this.props.frontData && this.props.frontData.event && this.props.frontData.event.CTA[0]
    let availableThroughoutFestival = this.props.frontData && this.props.frontData.event && this.props.frontData.event.availableThroughoutFestival
    let isBetweenTime = moment().isBetween(moment(Event.eventParticularTime[0].start), moment(Event.eventParticularTime[0].end))

    if (isBetweenTime || availableThroughoutFestival) return (<a onClick={this.getJoinButton} href={EventOnly.url} className="sd_textuppercase" target="_blank" > {EventOnly.title} </a>)
  }


  render() {
    const { loadData,sDataOnline,sDataInperson } = this.state;
    return (
      <>
        <section className="sd_GeneralView_section sd_home_page">
        {Object.keys(sDataOnline).length > 0 && <script type="application/ld+json"> {JSON.stringify(sDataOnline)} </script> }
        {Object.keys(sDataInperson).length > 0 && <script type="application/ld+json">{JSON.stringify(sDataInperson)}</script>}
          {/* Header */}
          <Header history={this.props.history} />
          {/* GeneralView hero header */}
          <section className="sd_PanelView ">
            <div className="sd_inner_container">
              <div className="sd_flex sd_flexwrap sd_main_panelview">
                <div
                  className={
                    this.props.frontData.event &&
                      this.props.frontData.event.keyEventThumbnailImage == ""
                      ? "sd_panel_view_bottom"
                      : "sd_PanelView_header"
                  }
                >
                  <h1 className="sd_textuppercase">
                    {this.props.frontData.event &&
                      this.props.frontData.event.eventTitle}
                  </h1>
                  <div className="sd_PanelView_inner">
                    {/* <p className="sd_textuppercase">Presented by</p> */}
                    {this.props.frontData &&
                      this.props.frontData.user &&
                      this.props.frontData.user.partnerLogo &&
                      this.props.frontData.user.partnerLogo != "" ? (
                      <p className="sd_textuppercase">Presented by</p>
                    ) : (
                      ""
                    )}
                    {this.props.frontData &&
                      this.props.frontData.user &&
                      this.props.frontData.user.satelliteLogo &&
                      this.props.frontData.user.satelliteLogo != "" ? (
                      <p className="sd_textuppercase">Presented by</p>
                    ) : (
                      ""
                    )}
                    <div className="sd_PanelView_img">
                      {this.props.frontData.user &&
                        this.props.frontData.user.partnerLogo && (
                          <img
                            src={
                              this.props.frontData &&
                                this.props.frontData.user.partnerLogo != ""
                                ? `${process.env.REACT_APP_IMAGE_URL}` +
                                this.props.frontData.user.partnerLogo
                                : this.props.frontData.user &&
                                this.props.frontData.user.partnerLogoUrl
                            }
                            alt="logo"
                          />
                        )}
                      {this.props.frontData.user &&
                        this.props.frontData.user.satelliteLogo && (
                          <img
                            src={
                              this.props.frontData &&
                                this.props.frontData.user
                                  .satelliteLogo != ""
                                ? `${process.env.REACT_APP_IMAGE_URL}` +
                                this.props.frontData.user
                                  .satelliteLogo
                                : this.props.frontData.user &&
                                this.props.frontData.user
                                  .satelliteLogo
                            }
                            alt="logo"
                          />
                        )}
                    </div>
                  </div>
                  <div className="sd_Schedule">
                    {this.props.frontData.event &&
                      this.props.frontData.event.eventDate == "" ? (
                      ""
                    ) : this.props.frontData.event &&
                      this.props.frontData.event.availableThroughoutFestival ==
                      true ? (
                      <h3 className="sd_textcapitalize sd_flex ">
                        All Festival
                      </h3>
                    ) : (
                      <h3 className="sd_textuppercase">
                        {TimezoneConverter(moment
                          .utc
                          (
                            this.props.frontData.event &&
                            this.props.frontData.event.eventDate[0] &&
                            this.props.frontData.event.eventDate[0].date,
                          )
                          .local()
                          , "dddd, MMM DD")}

                        <span>
                          {TimezoneConverter(moment
                            .utc
                            (
                              this.props.frontData.event &&
                              this.props.frontData.event.eventDate[0]
                                .eventParticularTime[0].start,
                            )
                            .local()
                            , "hh:mm A ")}{" "}
                          -{" "}
                          {TimezoneConverter(moment
                            .utc
                            (
                              this.props.frontData.event &&
                              this.props.frontData.event.eventDate[0]
                                .eventParticularTime[0].end,
                            )
                            .local()
                            , "hh:mm A")}
                          {" "}
                          {
                            getZone()
                          }
                        </span>
                      </h3>
                    )}

                    {this.state.checkIsfav == false ? (
                      <>
                        {loadData && <Loader />}
                        <button
                          className="sd_textuppercase sd_display_favorite"
                          onClick={this.getAddScheduleEvent}
                        >
                          <img src={Whiteplus} alt="plus" />
                          Add to My Schedule
                        </button>
                        {/* <button
                          className="sd_textuppercase sd_mobile_favorite"
                          onClick={this.getAddScheduleEvent}
                        >
                          <img src={Whiteplus} alt="plus" />
                          Favorite
                        </button> */}
                      </>
                    ) : this.state.checkIsfav == true ? (
                      <>
                        {loadData && <Loader />}
                        <button
                          className="sd_textuppercase"
                          onClick={this.getRemoveScheduleEvent}
                        >
                          <img
                            src={Righticon}
                            alt="plus"
                            className="right-icon"
                          />
                          Favorited
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  {
                    this.props.frontData.event &&
                    this.props.frontData.event.eventDate[0] &&
                    this.props.frontData.event.eventDate[0].eventParticularTime[0] && (
                      this.props.frontData.event.eventDate[0].eventParticularTime[0].availability == "1" ?
                        <p className="sd_textcapitalize"><span className="sd_textuppercase">IN-PERSON</span> |
                          {` ${this.props.frontData.event.eventDate[0].eventParticularTime[0].EventLocation.locationName ? this.props.frontData.event.eventDate[0].eventParticularTime[0].EventLocation.locationName + ',' : ''}
                        ${this.props.frontData.event.eventDate[0].eventParticularTime[0].EventLocation.street}, 
                        ${this.props.frontData.event.eventDate[0].eventParticularTime[0].EventLocation.city},
                        ${this.props.frontData.event.eventDate[0].eventParticularTime[0].EventLocation.state},
                        ${this.props.frontData.event.eventDate[0].eventParticularTime[0].EventLocation.zip}`}</p>
                        :
                        this.props.frontData.event.eventDate[0].eventParticularTime[0].availability == "2" &&
                        <p>ONLINE</p>
                    )

                  }


                  <div className="sd_PanelView_section">
                    <p
                      className="paragraph_span_22 panel_span"
                      dangerouslySetInnerHTML={{
                        __html:
                          this.props.frontData &&
                          this.props.frontData.event &&
                          this.props.frontData.event.eventDescription,
                      }}
                    />
                    <div className="sd_Filter_by_Interest">
                      <div className="sd_container">
                        <div className="sd_main_category sd_flex sd_flexwrap">
                          {
                            this.props.frontData &&
                            this.props.frontData.event && this.props.frontData.event.interest.length > 0 &&
                            this.props.frontData.event && this.props.frontData.event.interest.map((interest, i) => (
                              <div className="sd_inner_category sd_flex sd_aligncenter active" key={i}>
                                <div className="sd_filter_icon">
                                  {
                                    interest.icon ?
                                      <img
                                        src={this.apiServe.imageUrl + interest.icon}
                                        alt="Next Arrow"
                                        title={interest.name}
                                      />
                                      :
                                      <img
                                        src={this.apiServe.imageUrl + interest.icon}
                                        alt="Next Arrow"
                                        title={interest.name}
                                      />
                                  }

                                </div>
                                <div className="sd_filter_title">
                                  <span>{interest.name}</span>
                                </div>
                              </div>
                            ))
                          }



                          {/* <div className="sd_inner_category sd_flex sd_aligncenter">
                            <div className="sd_filter_icon">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_PATH}interest_icon.svg`}
                                alt="Next Arrow"
                                title="Next Arrow"
                              />
                            </div>
                            <div className="sd_filter_title">
                              <span>Diversity, Equity, & Inclusion</span>
                            </div>
                          </div>
                          <div className="sd_inner_category sd_flex sd_aligncenter">
                            <div className="sd_filter_icon">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_PATH}interest_icon.svg`}
                                alt="Next Arrow"
                                title="Next Arrow"
                              />
                            </div>
                            <div className="sd_filter_title">
                              <span>LGBTQ Representation</span>
                            </div>
                          </div>
                          <div className="sd_inner_category sd_flex sd_aligncenter ">
                            <div className="sd_filter_icon">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_PATH}interest_icon.svg`}
                                alt="Next Arrow"
                                title="Next Arrow"
                              />
                            </div>
                            <div className="sd_filter_title">
                              <span>Lorem Ipsum</span>
                            </div>
                          </div>
                          <div className="sd_inner_category sd_flex sd_aligncenter ">
                            <div className="sd_filter_icon">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_PATH}interest_icon.svg`}
                                alt="Next Arrow"
                                title="Next Arrow"
                              />
                            </div>
                            <div className="sd_filter_title">
                              <span>Lorem Ipsum</span>
                            </div>
                          </div>
                          <div className="sd_inner_category sd_flex sd_aligncenter ">
                            <div className="sd_filter_icon">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_PATH}interest_icon.svg`}
                                alt="Next Arrow"
                                title="Next Arrow"
                              />
                            </div>
                            <div className="sd_filter_title">
                              <span>Lorem Ipsum</span>
                            </div>
                          </div> */}

                        </div>
                      </div>
                    </div>

                    {
                      this.getEventButton()
                    }

                  </div>

                </div>
                <section
                  className={
                    this.props.frontData &&
                      this.props.frontData.event &&
                      this.props.frontData.event.keyEventThumbnailImage &&
                      this.props.frontData.event.keyEventThumbnailImage[0].link == '' && this.props.frontData.event.keyEventThumbnailImage[0].url == ''
                      ? "sd_panel_view_padd"
                      : " sd_video_details"
                  }
                >
                  {this.checkisIframe(
                    this.props.frontData &&
                      this.props.frontData.event &&
                      this.props.frontData.event.keyEventThumbnailImage &&
                      this.props.frontData.event.keyEventThumbnailImage[0].link != ""
                      ? `${process.env.REACT_APP_IMAGE_URL}` +
                      this.props.frontData.event.keyEventThumbnailImage[0].link
                      : this.props.frontData &&
                      this.props.frontData.event &&
                      this.props.frontData.event.keyEventThumbnailImage &&
                      this.props.frontData.event.keyEventThumbnailImage[0].url
                  ) ? (
                    <div className="sd_video_wpr iframeContainer">
                      <iframe
                        src={
                          this.props.frontData &&
                            this.props.frontData.event &&
                            this.props.frontData.event.keyEventThumbnailImage &&
                            this.props.frontData.event.keyEventThumbnailImage[0].link !=
                            ""
                            ? `${process.env.REACT_APP_IMAGE_URL}` +
                            this.props.frontData.event.keyEventThumbnailImage[0]
                              .link
                            : this.props.frontData &&
                            this.props.frontData.event &&
                            this.props.frontData.event.keyEventThumbnailImage &&
                            this.props.frontData.event.keyEventThumbnailImage[0].url
                        }
                      ></iframe>
                    </div>
                  ) : this.checkIsVideo(
                    this.props.frontData &&
                      this.props.frontData.event &&
                      this.props.frontData.event.keyEventThumbnailImage &&
                      this.props.frontData.event.keyEventThumbnailImage[0].link !=
                      ""
                      ? `${process.env.REACT_APP_IMAGE_URL}` +
                      this.props.frontData.event.keyEventThumbnailImage[0].link
                      : this.props.frontData &&
                      this.props.frontData.event &&
                      this.props.frontData.event.keyEventThumbnailImage &&
                      this.props.frontData.event.keyEventThumbnailImage[0].url
                  ) ? (
                    <div className="sd_video_wpr">
                      <VideoPlayer
                        videoSrc={
                          this.props.frontData &&
                            this.props.frontData.event &&
                            this.props.frontData.event.keyEventThumbnailImage &&
                            this.props.frontData.event.keyEventThumbnailImage[0].link !=
                            ""
                            ? `${process.env.REACT_APP_IMAGE_URL}` +
                            this.props.frontData.event.keyEventThumbnailImage[0]
                              .link
                            : this.props.frontData &&
                            this.props.frontData.event &&
                            this.props.frontData.event.keyEventThumbnailImage &&
                            this.props.frontData.event.keyEventThumbnailImage[0].url
                        }
                        autoplay={false}
                        loop={true}
                      />
                    </div>
                  ) : (
                    <div>
                      <img
                        src={
                          this.props.frontData &&
                            this.props.frontData.event &&
                            this.props.frontData.event.keyEventThumbnailImage &&
                            this.props.frontData.event.keyEventThumbnailImage[0].link !=
                            ""
                            ? `${process.env.REACT_APP_IMAGE_URL}` +
                            this.props.frontData.event.keyEventThumbnailImage[0]
                              .link
                            : this.props.frontData &&
                            this.props.frontData.event &&
                            this.props.frontData.event.keyEventThumbnailImage &&
                            this.props.frontData.event.keyEventThumbnailImage[0].url
                        }
                      />
                    </div>
                  )}
                </section>
              </div>
            </div>
          </section>
          {/* video */}

          <section className="sd_Event_Details sd_Event_Details_panelview">
            <div className="sd_inner_container">
              <div className="sd_event_des_Panel">
                <div className="sd_event_detail_left_Panel">
                  <h2 className="sd_textuppercase">Event Details</h2>
                  <p
                    className="paragraph_span_16"
                    dangerouslySetInnerHTML={{
                      __html:
                        this.props.frontData &&
                        this.props.frontData.event &&
                        this.props.frontData.event.eventDetail,
                    }}
                  />
                </div>
                <div className="sd_panel_right_speakers">
                  <div className="sd_event_detail_right">
                    {((this.props.frontData && this.props.frontData.event && this.props.frontData.event.availableThroughoutFestival == true) ||
                      (this.props.frontData.event &&
                        this.props.frontData.event.eventDate[0] &&
                        this.props.frontData.event.eventDate[0].time.length > 0)) &&
                        // this.props.frontData.event.eventDate[0].time &&
                        <h2 className="sd_textuppercase"> {this.props.frontData.event.eventDate[0].time.find(_=>_.start && _.end ) &&' TIME & Locations'}</h2>
                      }
                    <div className="sd_main_time">
                      {this.props.frontData && this.props.frontData.event && this.props.frontData.event.availableThroughoutFestival ==
                        true ? (
                        <div className="sd_detail_time">
                          <span className="detail_span">All festival</span>
                        </div>
                      ) : (
                        this.props.frontData &&
                        this.props.frontData.event &&
                        this.props.frontData.event.eventDate[0] &&
                        this.props.frontData.event.eventDate[0].time.map(
                          (item, i) => (
                            <div className="sd_detail_time" key={i}>
                              {item.start && item.end && <span>
                                {TimezoneConverter(moment
                                  .utc(item.start)
                                  .local()
                                  , "hh:mm A")}{" "}
                                -{" "}
                                {TimezoneConverter(moment
                                  .utc(item.end)
                                  .local()
                                  , "hh:mm A")}
                              </span>}
                              {item.start && item.end && <h3 className="sd_textcapitalize">{item.activityDetails}</h3>}
                            </div>
                          )
                        )
                      )}
                    </div>
                    {
                      this.props.frontData.event && this.props.frontData.event.CTA.length > 0 &&
                      (this.props.frontData.event.CTA[1]?.url && this.props.frontData.event.CTA[1].title) &&
                      <div className="sd_main_panel_cta_btn">
                        <a
                          href={
                            this.props.frontData.event &&
                            this.props.frontData.event.CTA[1] &&
                            this.props.frontData.event.CTA[1]?.url
                          }
                          className="sd_textuppercase sd_panel_cta_btn"
                          target="_blank"
                        >
                          {this.props.frontData.event &&
                            this.props.frontData.event.CTA[1] &&
                            this.props.frontData.event.CTA[1].title}
                        </a>
                      </div>
                    }

                  </div>
                  {
                    this.props.frontData && this.props.frontData.event.panelists.length > 0 &&
                    <>
                      <div className="sd_Panel_Speakers_title">
                        <h2 className="sd_textuppercase">{(this.props.frontData.event.panelists[0].bio.length > 7 || this.props.frontData.event.panelists[0].image || this.props.frontData.event.panelists[0].name)  ? 'Speakers':''}</h2>
                      </div>
                      <div className="sd_Panel_Speakers_right">
                        {this.props.frontData.event &&
                          this.props.frontData.event.panelists &&
                          this.props.frontData.event.panelists.map((item, i) => (
                            <div className="sd_panel_data" key={i}>
                              <div className="sd_inner_panel">

                                {item.image == "" ? (
                                  ""
                                ) : (
                                  <div className="sd_panel_data_img">
                                    <img
                                      src={
                                        `${process.env.REACT_APP_IMAGE_URL}` +
                                        item.image
                                      }
                                      alt="Panel Speakers image"
                                    />
                                  </div>
                                )}
                                <div className="sd_panel_des">
                                  <h3 className="sd_textuppercase">{item.name}</h3>

                                </div>
                              </div>
                              <p
                                className="paragraph_span_14 "
                                dangerouslySetInnerHTML={{
                                  __html: item.bio,
                                }}
                              />
                            </div>
                          ))}
                        {this.props.frontData.event && this.props.frontData.event.CTA.length > 0 &&
                        (this.props.frontData.event.CTA[1].url && this.props.frontData.event.CTA[1].title) &&
                          <div className="sd_main_panel_cta_btn_mobile">
                            <a
                              href={
                                this.props.frontData.event &&
                                this.props.frontData.event.CTA[1] &&
                                this.props.frontData.event.CTA[1].url
                              }
                              className="sd_textuppercase sd_panel_cta_btn"
                              target="_blank"
                            >
                              {this.props.frontData.event &&
                                this.props.frontData.event.CTA[1] &&
                                this.props.frontData.event.CTA[1].title}
                            </a>
                          </div>
                        }
                      </div>
                    </>
                  }

                </div>
              </div>
              {/* Carousel */}
              <div className="sd_PanelView_Carousel_des">
                {/* <div className="sd_PanelView_Carousel_left">
                  <div className="sd_PanelView_Carousel_inner">
                    <h2>
                      {this.props.frontData.event &&
                        this.props.frontData.event.CTA[1] &&
                        this.props.frontData.event.CTA[1].description}
                    </h2>
                    <a
                      href={
                        this.props.frontData.event &&
                        this.props.frontData.event.CTA[1] &&
                        this.props.frontData.event.CTA[1].url
                      }
                      className="sd_textuppercase"
                      target="_blank"
                    >
                      {this.props.frontData.event &&
                        this.props.frontData.event.CTA[1] &&
                        this.props.frontData.event.CTA[1].title}
                    </a>
                  </div>
                </div> */}
                <div className="sd_main_GeneralView_images sd_generalView_Carousel">
                  {this.props.frontData &&
                    this.props.frontData.event &&
                    this.props.frontData.event.frontDetailImageOrVideo == "" ? (
                    ""
                  ) : (
                    <div className="Carousel_panel_view">
                      <Carousel
                        showArrows={true}
                        infiniteLoop={true}
                        autoPlay={false}
                        swipeable={true}
                        emulateTouch={true}
                        dynamicHeight={true}
                        onChange={this.changeCarousal}
                        showIndicators={this.props?.frontData?.event?.frontDetailImageOrVideo?.length > 1 ? true : false}
                      >
                        {this.props.frontData &&
                          this.props.frontData.event &&
                          this.props.frontData.event.frontDetailImageOrVideo.map(
                            (item, i) =>
                              this.checkisIframe(
                                item.link != "" ? item.link : item.url
                              ) ? (
                                <div className="sd_video_wpr iframeContainer" key={i}>
                                  <iframe
                                    src={
                                      item.link != ""
                                        ? `${process.env.REACT_APP_IMAGE_URL}` +
                                        item.link +
                                        "?enablejsapi=1&version=3&playerapiid=ytplayer"
                                        : item.url +
                                        "?enablejsapi=1&version=3&playerapiid=ytplayer"
                                    }
                                  ></iframe>
                                </div>
                              ) : this.checkIsVideo(
                                item.link != "" ? item.link : item.url
                              ) ? (
                                <div className="sd_video_wpr" key={i}>
                                  <VideoPlayer
                                    videoSrc={
                                      item && item.link != ""
                                        ? `${process.env.REACT_APP_IMAGE_URL}` +
                                        item.link
                                        : item.url
                                    }
                                    autoplay={false}
                                    loop={true}
                                    ref={this.playerVideo}
                                  />
                                </div>
                              ) : (
                                <div key={i}>
                                  <img
                                    src={
                                      item && item.link != ""
                                        ? `${process.env.REACT_APP_IMAGE_URL}` +
                                        item.link
                                        : item.url
                                    }
                                  />
                                </div>
                              )
                          )}
                      </Carousel>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
          {/* footer */}
          <Footer />
        </section>
      </>
    );
  }
}

export default PanelView;
