import React, { Component } from "react";
// header and footer
import Header from "../../layout/header";
import Footer from "../../layout/footer";
//component
import SponsorsLogo from "../../components/sponsorsLogo/SponsorsLogo";
import VideoPlayer from "../../components/VideoPlayer";
// Carousel and Slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { Carousel } from "react-responsive-carousel";
// services
import service from "../../common/service";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import moment from "moment";
import Sundance from "../../images/sundanceimg.png";
// Arrow
import $ from "jquery";
import Path from "../../images/Path.png";
import Plus from "../../images/plus.png";
import Arrow from "../../images/down_arrow.png";
import { Link } from "react-router-dom";
import Photo from "../../images/photo.png";
import Cookies from "universal-cookie";
import Righticon from "../../images/tick_icon_white.svg";
import FavoriteCard from "../../components/FavoriteCard";
import ReactPaginate from "react-paginate";
import { element } from "prop-types";
import { TimezoneConverter } from "../../store/functions";

const cookies = new Cookies();

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1366,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const videoExtensions = [
  "3g2",
  "3gp",
  "aaf",
  "asf",
  "avchd",
  "avi",
  "drc",
  "flv",
  "m2v",
  "m4p",
  "m4v",
  "mkv",
  "mng",
  "mov",
  "mp2",
  "mp4",
  "mpe",
  "mpeg",
  "mpg",
  "mpv",
  "mxf",
  "nsv",
  "ogg",
  "ogv",
  "qt",
  "rm",
  "rmvb",
  "roq",
  "svi",
  "vob",
  "webm",
  "wmv",
  "yuv",
];

let sub;
let timer = null;
let filterValues = [];
class VillageHomePreview extends Component {
  constructor(props) {
    super(props);
    this.apiServe = new service();
    this.state = {
      activePage: 1,
      homeData: "",
      allEvent: "",
      allPage: "",
      heroHeader: "",
      listItems: false,

      hasNextPage: false,
      hasPrevPage: false,
      totalPage: "",
      totalRecords: "",
      filterArray: [],
      loginPrompt: false,
      pageNextPagi: true,
      paginationData: false,
      checkData: false,
      paginationFirst: "",
      totalpage: "",
      intialFilter: ["artist", "satellite", "partner"],
      selectedFilter: ["artist", "satellite", "partner"],
      pageTemp: [],
      currentPage: 1,
      nextPage: 2,
      prevPage: -1,
    };
    this.checkIsVideo = this.checkIsVideo.bind(this);
    this.next_medium_1 = this.next_medium_1.bind(this);
    this.previous_medium_1 = this.previous_medium_1.bind(this);
    this.next_small_1 = this.next_small_1.bind(this);
    this.previous_small_1 = this.previous_small_1.bind(this);
    this.next_small_2 = this.next_small_2.bind(this);
    this.previous_small_2 = this.previous_small_2.bind(this);
    this.next_small_3 = this.next_small_3.bind(this);
    this.previous_small_3 = this.previous_small_3.bind(this);
    this.next_small_4 = this.next_small_4.bind(this);
    this.previous_small_4 = this.previous_small_4.bind(this);
    this.pagination = this.pagination.bind(this);
    this.getpage = this.getpage.bind(this);
    this.getFilter = this.getFilter.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.changeCarousal = this.changeCarousal.bind(this);
    this.setPagination = this.setPagination.bind(this);
    this.playerVideo = React.createRef();

    document.querySelector("body").scrollTo({ top: 0, behavior: "smooth" });
  }
  next_medium_1() {
    this.slider1.slickNext();
  }
  previous_medium_1() {
    this.slider1.slickPrev();
  }
  next_small_1() {
    this.small_slider_1.slickNext();
  }
  previous_small_1() {
    this.small_slider_1.slickPrev();
  }
  next_small_2() {
    this.small_slider_2.slickNext();
  }
  previous_small_2() {
    this.small_slider_2.slickPrev();
  }
  next_small_3() {
    this.small_slider_3.slickNext();
  }
  previous_small_3() {
    this.small_slider_3.slickPrev();
  }
  next_small_4() {
    this.small_slider_4.slickNext();
  }
  previous_small_4() {
    this.small_slider_4.slickPrev();
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateDimensions);
    sub.unsubscribe();
    clearTimeout(timer);
  };

  getPreview = () => {
    this.apiServe.sendevent("FestivalVillage|Event|Preview|getPreview", {});
  }
  getHeader = (previewData) => {

    let paramsData = this.apiServe.decrypt(previewData)
    var self = this;
    paramsData.then(function (val) {
      if (val) {
        self.setState({
          homeData: val
        })

      }
    });

  };
  getAllEventList = (page) => {
    this.apiServe.sendevent("FestivalVillage|Event|getAllEventList", {
      limit: 0,
      page: page,
      eventTypeTitle: this.state.intialFilter,
    });
  };
  getHeroHeadre = () => {
    this.apiServe.sendevent("homePage|HeaderAndFooter|getList", {
      limit: 10,
      page: 1,
    });
  };

  removePopup = () => {
    this.setState({
      loginPrompt: false,
    });
  };
  getAddScheduleEvent = (cardid, cardDateTime, cardTemplate) => {
    let particularUser =
      cookies.get("SundanceNewUser") || cookies.get("particularNewUser");
    if (!particularUser) {
      return this.setState({
        loginPrompt: true,
      });
    }

    this.setState({
      [cardid]: !this.state[cardid],
    });

    this.apiServe.sendevent("FestivalVillage|Event|addToFavourite", {
      eventId: cardid,
      date: cardDateTime,
      eventType: cardTemplate,
    });
  };
  getRemoveScheduleEvent = (cardid, cardDateTime) => {
    this.setState({
      [cardid]: !this.state[cardid],
    });
    this.apiServe.sendevent("FestivalVillage|Event|removeFavourite", {
      eventId: cardid,
      date: cardDateTime,
    });
  };
  componentDidMount = () => {
    let particularUser =
      cookies.get("SundanceNewUser") || cookies.get("particularNewUser");
    if (particularUser && particularUser.token) {
      this.apiServe.sendevent("authentication", {
        token: particularUser.token,
      });
    }
    this.getPreview()

    this.getHeroHeadre();
    this.getAllEventList();
    this.getpage();
    sub = this.apiServe.getevent().subscribe((response) => {
      switch (response.event) {
        case "authentication":
          if (response.error) {
            let Zone = localStorage.getItem('TIMEZONE')
            localStorage.clear();
            if (Zone) localStorage.setItem('TIMEZONE', Zone)
          } else {
            localStorage.setItem("loggedInUser", response.data.user.role);
            localStorage.setItem("myLoginToken", response.data.token);
            this.getProfile();
          }
          break;
        case "user|getProfile":
          if (response.error) {
          } else {
            if (localStorage.getItem("myLoginToken")) {
              let userInfo = {
                fname: response.data.fname,
                lname: response.data.lname,
                image: response.data.image,
                showMyLibrary: response.data.showMyLibrary,
              };
              localStorage.setItem("Header", JSON.stringify(userInfo));
            }
            this.setState({
              Header: true,
              code_conduct: response.data.COMM_AGMT_FLAG,
            });
            this.forceUpdate();
            this.getAllEventList();
            this.getpage();
          }
          break;
        case "FestivalVillage|FestivalVillageHomePage|getList":
          if (response.error) {
          } else {
            this.setState({
              homeData: response.data,
            });
           
            if (
              response.data &&
              response.data.promotionalEvent &&
              response.data.promotionalEvent.isFavourite
            ) {
              this.setState({
                [response.data.promotionalEvent._id]:
                  response.data.promotionalEvent.isFavourite,
              });
            }
          }
          break;
        case "FestivalVillage|Event|getAllEventList":
          if (response.error) {
          } else {
            var _this = this;
            _this.setState({
              allEvent: response.data,
              allPage: response.data.totalRecords,

              totalRecords: response.data.totalRecords,
              topRight:
                _this.state.currentPage == 1
                  ? response.data.event.slice(0, 2)
                  : null,
              EventsItem:
                _this.state.currentPage == 1
                  ? response.data.event.slice(2)
                  : response.data.event,
              allEventSection: response.data.event.slice(0),
            });

            _this.setState({
              totalPages: Math.ceil((response.data.totalRecords - 10) / 12 + 1),
            });
            setTimeout(() => {
              var pagearray = [];
              for (let index = 0; index < _this.state.totalPages; index++) {
                pagearray.push(index + 1);
              }
              _this.setState({
                pageTemp: pagearray,
              });
              _this.setPagination(_this.state.currentPage);
            }, 500);

            
          }
          break;

        case "homePage|HeaderAndFooter|getList":
          if (response.error) {
          } else {
            this.setState({
              heroHeader: response.data.docs[0],
            });
          }
          break;
        case "FestivalVillage|Event|addToFavourite":
          if (response.error) {
          } else {
            this.setState({
              loadData: false,
            });
          }
          break;
        case "FestivalVillage|Event|removeFavourite":
          if (response.error) {
          } else {
            this.setState({
              loadData: false,
            });
          }
          break;
        case "FestivalVillage|Event|Preview|getPreview":
          if (response.error) {
          } else {

            this.getHeader(response.data.previewString);
          }
          break;
        default:
          break;
      }
    });
  };

  getProfile = () => {
    this.apiServe.sendevent("user|getProfile", {});
  };
  openfilter() {
    this.setState({
      listItems: !this.state.listItems,
    });
  }
  chunk(array, size) {
    if (array && array != "") {
      return array.reduce((chunks, item, i) => {
        if (i % size === 0) {
          chunks.push([item]);
        } else {
          chunks[chunks.length - 1].push(item);
        }
        return chunks;
      }, []);
    }
  }

  //pagination
  getpage(page) {
    if (page) {
      document
        .getElementById("section1")
        .scrollIntoView({ top: 0, behavior: "smooth" });
    }

    this.apiServe.sendevent("FestivalVillage|Event|getAllEventList", {
      page: page,
      eventTypeTitle: this.state.selectedFilter,
    });
    this.setPagination(page);
  }

  getFilter(filterItem) {
    this.apiServe.sendevent("FestivalVillage|Event|getAllEventList", {
      limit: 10,
      page: this.state.currentPage,
      eventTypeTitle:
        filterItem.length > 0 ? filterItem : this.state.intialFilter,
    });
    this.setState({
      selectedFilter:
        filterItem.length > 0 ? filterItem : this.state.intialFilter,
    });
  }

  setPagination(page) {
    this.setState({
      currentPage: this.state.totalPages < page ? 1 : page ? page : 1,
      nextPage:
        this.state.totalPages == 1
          ? -1
          : page
            ? this.state.pageTemp.length == page
              ? -1
              : page + 1
            : 2,
      prevPage: this.state.totalPages == 1 ? -1 : page > 1 ? page - 1 : -1,

      topRight:
        this.state.currentPage == 1 || this.state.totalPages < page
          ? this.state.allEvent.event && this.state.allEvent.event.slice(0, 2)
          : null,
      EventsItem:
        this.state.currentPage == 1 || this.state.totalPages < page
          ? this.state.allEvent.event && this.state.allEvent.event.slice(2)
          : this.state.allEvent.event && this.state.allEvent.event,
      allEventSection:
        this.state.allEvent.event && this.state.allEvent.event.slice(0),
    });

    setTimeout(() => {
    }, 500);
  }

  setFilter(event) {
    var target = event.target;
    var value = target.value;

    if (target.checked) {
      filterValues.push(value);
    } else {
      var index = filterValues.indexOf(value);
      if (index > -1) {
        filterValues.splice(index, 1);
      }
    }
    this.getFilter(filterValues);
  }
  pagination() {
    var pagination = [];

    for (let index = 0; index < this.totalPages; index++) {
      pagination.push(
        <li
          key={index}
          className={this.state.allPage == index + 1 ? "active_page" : ""}
        >
          <button onClick={() => this.getpage(index + 1)}>{index + 1}</button>
        </li>
      );
    }

    return pagination;
  }

  setContainer(c) {
    this.container = c;
  }

  pauseYoutube() {
    var video = document.querySelectorAll('iframe[src*="youtube.com"]');
    video.forEach((element) => {
      element.contentWindow.postMessage(
        '{"event":"command","func":"' + "pauseVideo" + '","args":""}',
        "*"
      );
    });
  }

  changeCarousal(index) {
    if (this.playerVideo && this.playerVideo.current) {
      this.playerVideo.current.pause();
    }
    var video = document.querySelectorAll('iframe[src*="youtube.com"]');
    if (video.length > 0) {
      this.pauseYoutube();
    }
  }

  getFileExtension(filename) {
    return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined;
  }

  checkIsVideo(filePath) {
    var getExtension = this.getFileExtension(filePath);
    return videoExtensions.includes(getExtension);
  }

  checkisIframe(url) {
    var expVimeo = new RegExp(/(vimeo\.com)/);
    var expYoutube = new RegExp(/(youtu\.be|youtube\.com)/);

    var expVimeoTest = expVimeo.test(url);
    var expYoutubeTest = expYoutube.test(url);

    if (expVimeoTest || expYoutubeTest) {
      return true;
    } else {
      return false;
    }
  }

  hanlerStop() {
    var v = document.getElementById("Test_Video");
    // v.pause();
  }

  render() {
    const { homeData, allEvent, EventsItem, topRight } = this.state;
    const topRightEvent = this.chunk(allEvent && topRight, 2);
    const sections = this.chunk(allEvent && EventsItem, 4);
    return (
      <React.Fragment>
        <section className="sd_simplelp_bottom sd_home_page sd_bg_layer">
          {/* Header */}
          <Header history={this.props.history} />
          {/* Content */}
          <div className="sd_header">
            <div className="sd_header_sec_section">
              <div className="sd_simplelp_section">
                <div className="sd_large_container sd_inner_container sd_hero_header_inner_section sd_relative">
                  <div className="sd_header_sec">
                    <div className="sd_simplelp_left sd_header_text">
                      <h1 className="sd_textuppercase">
                        {homeData && homeData.pageTitle}
                      </h1>
                      <p
                        className="paragraph_span_22"
                        dangerouslySetInnerHTML={{
                          __html: homeData && homeData.pageDescription,
                        }}
                      />
                    </div>
                    <div className="sd_simplelp_right_inner">
                      <div className="sd_homepage_carousel" id="container">
                        <Carousel
                          showArrows={true}
                          infiniteLoop={true}
                          swipeable={true}
                          emulateTouch={true}
                          dynamicHeight={true}
                          autoPlay={false}
                          onChange={this.changeCarousal}
                        >
                          {homeData && homeData.frontMedia == ""
                            ? ""
                            : homeData &&
                            homeData.frontMedia &&
                            homeData.frontMedia.map((card, i) =>
                              this.checkisIframe(
                                card.media != "" ? card.media : card.mediaUrl
                              ) ? (
                                  <div
                                    className="sd_video_wpr iframeContainer"
                                    key={i}
                                  >
                                    <iframe
                                      src={
                                        card.media != ""
                                          ? `${process.env.REACT_APP_IMAGE_URL}` +
                                          card.media +
                                          "?enablejsapi=1&version=3&playerapiid=ytplayer"
                                          : card.mediaUrl +
                                          "?enablejsapi=1&version=3&playerapiid=ytplayer"
                                      }
                                    ></iframe>
                                  </div>
                                ) : this.checkIsVideo(
                                  card.media != ""
                                    ? card.media
                                    : card.mediaUrl
                                ) ? (
                                    <div className="sd_video_wpr video" key={i}>
                                      <VideoPlayer
                                        videoSrc={
                                          card.media != ""
                                            ? `${process.env.REACT_APP_IMAGE_URL}` +
                                            card.media
                                            : card.mediaUrl
                                        }
                                        id={"videoPlayer_" + i}
                                        loop={false}
                                        ref={this.playerVideo}
                                      />
                                    </div>
                                  ) : (
                                    <img
                                      key={i}
                                      src={
                                        card.media != ""
                                          ? `${process.env.REACT_APP_IMAGE_URL}` +
                                          card.media
                                          : card.mediaUrl
                                      }
                                    />
                                  )
                            )}
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FEATURED section */}
        <section className="sd_medium_card sd_medium_card_home">
          <div className="sd_home_slider">
            <div className="sd_container_slider">
              <div className="sd_slider_title">
                <h2 className="sd_textuppercase">FEATURED</h2>
                {
                  homeData && homeData.featuredEvent.length > 3 &&
                  <div className="sd_slider_arrows">
                    <button className="button " onClick={this.previous_medium_1}>
                      <img
                        src="/village/images/arrow_left.svg"
                        alt="Prev Arrow"
                        title="Prev Arrow"
                      />
                    </button>
                    <button className="button " onClick={this.next_medium_1}>
                      <img
                        src="/village/images/arrow_right.svg"
                        alt="Next Arrow"
                        title="Next Arrow"
                      />
                    </button>
                  </div>
                }
              </div>
              <div className="sd_slider">
                {homeData && homeData.featuredEvent && homeData.featuredEvent && (
                  <Slider {...settings} ref={(c) => (this.slider1 = c)}>
                    {homeData &&
                      homeData.featuredEvent &&
                      homeData.featuredEvent.map((card, i) => (
                        <>
                        <div className="slider-images">
                          <div className="sd_slider_img">
                            <img
                              src={
                                card &&
                                  card.keyEventThumbnailImage[0].link != ""
                                  ? `${process.env.REACT_APP_IMAGE_URL}` +
                                  card.keyEventThumbnailImage[0].link
                                  : card && card.keyEventThumbnailImage[0].url
                              }
                            />
                          </div>
                          <div className="content_slider">
                            {card &&
                              card &&
                              card.eventDisplayTemplate == "Offer" &&
                              card.eventDate == "" ? (
                                <span>All Festival</span>
                              ) : card && card && card.eventDate == "" ? (
                                ""
                              ) : card &&
                                card.eventDate &&
                                card.eventDate[0].dateKey &&
                                card.eventDate[0].dateKey != "" ? (
                                    <span className="sd_time sd_textuppercase">
                                      {card &&
                                        card.eventDate[0] &&
                                        card.eventDate[0].dateKey}
                                    </span>
                                  ) : moment().isBetween(
                                    TimezoneConverter(moment(
                                      card &&
                                      card.eventDate[0] &&
                                      card.eventDate[0].eventParticularTime[0]
                                        .start
                                    ), "dddd, MMMM D, YYYY h:mm:ss A"),
                                    TimezoneConverter(moment(
                                      card &&
                                      card.eventDate[0] &&
                                      card.eventDate[0].eventParticularTime[0]
                                        .end
                                    ), "dddd, MMMM D, YYYY h:mm:ss A")
                                  ) ? (
                                      <span className="Available_Now">
                                        Available Now
                                </span>
                                    ) : (
                                      <span className="sd_time sd_textuppercase">
                                        {TimezoneConverter(moment
                                          .utc(
                                          card &&
                                          card.eventDate &&
                                          card.eventDate[0].date
                                          )
                                          .local()
                                          , "MMM DD,")}{" "}
                                        {TimezoneConverter(moment
                                          .utc(
                                          card &&
                                          card.eventDate[0] &&
                                          card.eventDate[0].eventParticularTime[0]
                                            .start
                                          )
                                          .local()
                                          , "hh:mm A ")}{" "}
                                        -{" "}
                                        {TimezoneConverter(moment
                                          .utc(
                                          card &&
                                          card.eventDate[0] &&
                                          card.eventDate[0].eventParticularTime[0]
                                            .end
                                          )
                                          .local()
                                          , "hh:mm A")}
                                      </span>
                                    )}
                            {card &&
                              card &&
                              card.eventDisplayTemplate == "Offer" ? (
                                <h2> {card && card.eventTitle}</h2>
                              ) : (
                                <h2
                                  className={
                                    card.eventDate == ""
                                      ? "sd_eventdate_space"
                                      : "sd_eventdate"
                                  }
                                >
                                  {card && card.eventTitle}
                                </h2>
                              )}
                            <Link
                              to={"/panel-view/" + card._id}
                              className="sd_textcapitalize"
                            >
                              Learn More
                                <div className="sd_arrow">
                                <img
                                  src="/village/images/arrow_right.svg"
                                  alt="Next Arrow"
                                  title="Next Arrow"
                                />
                              </div>
                            </Link>
                          </div>
                        </div>
                        </>
                      ))}
                  </Slider>
                )}
              </div>
            </div>
          </div>

          {/* sd_Mainstreet_block */}
          <div className="sd_Mainstreet">
            <div className="sd_Mainstreet_sec">
              <div className="sd_Mainstreet_container">
                <div className="sd_feature_inner">
                  <div className="sd_feature_inner_sec">
                    <Link to="main-street">MAIN STREET</Link>
                    <p>Experiences and events from our Festival sponsors</p>
                  </div>
                  <div className="sd_feature_inner_sec">
                    <Link to="/community-hub">SATELLITE SCREENS</Link>
                    <p>
                      Programming from the independent cinemas in our Festival
                      2021 network
                    </p>
                  </div>
                  <div className="sd_feature_inner_sec">
                    <Link to="/artist-lounge">ARTIST LOUNGE</Link>
                    <p>A space for artists, events, and gatherings</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sd_home_presention_sec" id="section1">
          <div className="sd_home_presention"></div>
          <div className="sd_home_presention_sponsor">
            <div className="sd_presention_sponsor ">
              <div className="sd_container">
                <div className="sd_full_calendar">
                  <div className="sd_calendar_title">
                    <h2>What’s Happening</h2>
                  </div>
                  <div className="sd_calender_right">
                    <div
                      className="sd_calendar_title_right"
                      onClick={() => this.openfilter()}
                    >
                      <div className="sd_calendar_sub_title">
                        <h3>FILTER</h3>
                        <p>BY EVENT TYPE</p>
                      </div>
                      {this.state.listItems ? (
                        <div className="sd_calendar_images">
                          <img src={Arrow} alt="PathArrow" />
                        </div>
                      ) : (
                          <div className="sd_calendar_images">
                            <img src={Path} alt="PathArrow" />
                          </div>
                        )}
                    </div>
                    {this.state.listItems ? (
                      <div className="sd_calendar_checkbox">
                        <form className="sd_calendar_checkbox_inner">
                          <div className="form-group">
                            <input
                              type="checkbox"
                              id="Main_Street"
                              value="partner"
                              onChange={(e) => this.setFilter(e)}
                            />
                            <label htmlFor="Main_Street">Main Street</label>
                          </div>
                          <div className="form-group">
                            <input
                              type="checkbox"
                              value="satellite"
                              id="Satellite_Screens"
                              onChange={(e) => this.setFilter(e)}
                            />
                            <label htmlFor="Satellite_Screens">
                              Satellite Screens
                            </label>
                          </div>
                          <div className="form-group">
                            <input
                              type="checkbox"
                              value="artist"
                              id="Artist_Lounge"
                              onChange={(e) => this.setFilter(e)}
                            />
                            <label htmlFor="Artist_Lounge">Artist Lounge</label>
                          </div>
                        </form>
                      </div>
                    ) : (
                        ""
                      )}
                  </div>
                </div>
              </div>
            </div>
            {topRightEvent && this.state.pageNextPagi && (
              <div className="sd_presention_block">
                <div className="sd_container">
                  <div className="sd_presention_block_inner">
                    <div className="sd_presention_border">
                      <div className="sd_presention_block_left">
                        {homeData &&
                          homeData.promotionalEvent &&
                          homeData.promotionalEvent.eventDisplayTemplate ==
                          "Offer" &&
                          homeData.promotionalEvent &&
                          homeData.promotionalEvent.eventDate == "" ? (
                            <div className="logo_Day">
                              <h3>All Festival</h3>
                            </div>
                          ) : homeData &&
                            homeData.promotionalEvent &&
                            homeData.promotionalEvent.eventDate == "" ? (
                              ""
                            ) : (
                              <div className="logo_Day">
                                {homeData &&
                                  homeData.promotionalEvent &&
                                  homeData.promotionalEvent
                                    .availableThroughoutFestival == true ? (
                                    <h3>
                                      {homeData &&
                                        homeData.promotionalEvent &&
                                        homeData.promotionalEvent.eventDate[0]
                                          .dateKey}
                                    </h3>
                                  ) : (
                                    <div>
                                      <h4>
                                        {TimezoneConverter(moment
                                          .utc(
                                          homeData &&
                                          homeData.promotionalEvent &&
                                          homeData.promotionalEvent.eventDate &&
                                          homeData.promotionalEvent.eventDate[0]
                                            .date
                                          )
                                          .local()
                                          , "MMMM")}
                                      </h4>
                                      <span>
                                        {TimezoneConverter(moment
                                          .utc(
                                          homeData &&
                                          homeData.promotionalEvent &&
                                          homeData.promotionalEvent.eventDate &&
                                          homeData.promotionalEvent.eventDate[0]
                                            .date
                                          )
                                          .local()
                                          , "DD")}
                                      </span>
                                    </div>
                                  )}
                              </div>
                            )}

                        <div className="sd_block_left_images">
                          <img
                            src={
                              homeData &&
                                homeData.promotionalEvent &&
                                homeData.promotionalEvent
                                  .keyEventThumbnailImage &&
                                homeData.promotionalEvent
                                  .keyEventThumbnailImage[0].link
                                ? `${process.env.REACT_APP_IMAGE_URL}` +
                                homeData.promotionalEvent
                                  .keyEventThumbnailImage[0].link
                                : homeData.promotionalEvent &&
                                  homeData.promotionalEvent
                                    .keyEventThumbnailImage &&
                                  homeData.promotionalEvent
                                    .keyEventThumbnailImage[0].url != ""
                                  ? homeData.promotionalEvent
                                    .keyEventThumbnailImage &&
                                  homeData.promotionalEvent
                                    .keyEventThumbnailImage[0].url
                                  : Sundance
                            }
                            alt="Blockimage"
                          />
                        </div>
                        <div className="sd_block_left_des">
                          {homeData &&
                            homeData.promotionalEvent &&
                            homeData.promotionalEvent.eventDisplayTemplate ==
                            "Offer"
                            ? homeData && (
                              <h2
                                className={
                                  homeData &&
                                    homeData.promotionalEvent &&
                                    homeData.promotionalEvent.eventDate == ""
                                    ? "sd_offer_space"
                                    : "sd_offer"
                                }
                              >
                                {homeData &&
                                  homeData.promotionalEvent &&
                                  homeData.promotionalEvent.offerTitle}
                              </h2>
                            )
                            : homeData && (
                              <h2
                                className={
                                  homeData &&
                                    homeData.promotionalEvent &&
                                    homeData.promotionalEvent.eventDate == ""
                                    ? "sd_offer_space"
                                    : "sd_offer"
                                }
                              >
                                {homeData &&
                                  homeData.promotionalEvent &&
                                  homeData.promotionalEvent.eventTitle}
                              </h2>
                            )}
                          {homeData &&
                            homeData.promotionalEvent &&
                            homeData.promotionalEvent.eventDisplayTemplate ==
                            "Offer" ? (
                              <span>All Festival</span>
                            ) : homeData &&
                              homeData.promotionalEvent &&
                              homeData.promotionalEvent.eventDate == "" ? (
                                ""
                              ) : homeData &&
                                homeData.promotionalEvent &&
                                homeData.promotionalEvent
                                  .availableThroughoutFestival == true ? (
                                  <span className="sd_textuppercase">
                                    {homeData &&
                                      homeData.promotionalEvent &&
                                      homeData.promotionalEvent.eventDate[0].dateKey}
                                    {this.state[
                                      homeData &&
                                      homeData.promotionalEvent &&
                                      homeData.promotionalEvent._id
                                    ] ? (
                                        <button
                                          className="sd_textuppercase"
                                          onClick={() =>
                                            this.getRemoveScheduleEvent(
                                              homeData && homeData.promotionalEvent._id,
                                              homeData &&
                                                homeData.promotionalEvent.eventDate[0]
                                                ? homeData.promotionalEvent.eventDate[0]
                                                  .eventParticularTime[0].start
                                                : ""
                                            )
                                          }
                                        >
                                          <img
                                            src={Righticon}
                                            alt="plus"
                                            className="home-right-btn"
                                          />
                                        </button>
                                      ) : (
                                        <button
                                          onClick={() =>
                                            this.getAddScheduleEvent(
                                              homeData && homeData.promotionalEvent._id,
                                              homeData.promotionalEvent.eventDate[0]
                                                ? homeData.promotionalEvent.eventDate[0]
                                                  .eventParticularTime[0].start
                                                : "",
                                              homeData &&
                                              homeData.promotionalEvent
                                                .eventDisplayTemplate
                                            )
                                          }
                                        >
                                          <img src={Plus} alt="Plus" />
                                        </button>
                                      )}
                                  </span>
                                ) : (
                                  <span className="sd_textuppercase">
                                    {TimezoneConverter(moment
                                      .utc(
                                      homeData &&
                                      homeData.promotionalEvent &&
                                      homeData.promotionalEvent.eventDate &&
                                      homeData.promotionalEvent.eventDate[0] &&
                                      homeData.promotionalEvent.eventDate[0]
                                        .eventParticularTime[0].start
                                      )
                                      .local()
                                      , "hh:mm A ")}{" "}
                                    -{" "}
                                    {TimezoneConverter(moment
                                      .utc(
                                      homeData &&
                                      homeData.promotionalEvent &&
                                      homeData.promotionalEvent.eventDate &&
                                      homeData.promotionalEvent.eventDate[0] &&
                                      homeData.promotionalEvent.eventDate[0]
                                        .eventParticularTime[0].end
                                      )
                                      .local()
                                      , "hh:mm A")}
                                    {this.state[
                                      homeData &&
                                      homeData.promotionalEvent &&
                                      homeData.promotionalEvent._id
                                    ] ? (
                                        <button
                                          className="sd_textuppercase"
                                          onClick={() =>
                                            this.getRemoveScheduleEvent(
                                              homeData && homeData.promotionalEvent._id,
                                              homeData &&
                                                homeData.promotionalEvent.eventDate[0]
                                                ? homeData.promotionalEvent.eventDate[0]
                                                  .eventParticularTime[0].start
                                                : ""
                                            )
                                          }
                                        >
                                          <img
                                            src={Righticon}
                                            alt="plus"
                                            className="home-right-btn"
                                          />
                                        </button>
                                      ) : (
                                        <button
                                          onClick={() =>
                                            this.getAddScheduleEvent(
                                              homeData && homeData.promotionalEvent._id,
                                              homeData.promotionalEvent.eventDate[0]
                                                ? homeData.promotionalEvent.eventDate[0]
                                                  .eventParticularTime[0].start
                                                : "",
                                              homeData &&
                                              homeData.promotionalEvent
                                                .eventDisplayTemplate
                                            )
                                          }
                                        >
                                          <img src={Plus} alt="Plus" />
                                        </button>
                                      )}
                                  </span>
                                )}
                          {homeData &&
                            homeData.promotionalEvent &&
                            homeData.promotionalEvent.eventDisplayTemplate ==
                            "Offer" ? (
                              <p
                                className="paragraph_span_16"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    homeData &&
                                    homeData.promotionalEvent &&
                                    homeData.promotionalEvent.offerDescription,
                                }}
                              />
                            ) : (
                              <p
                                className="paragraph_span_16"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    homeData &&
                                    homeData.promotionalEvent &&
                                    homeData.promotionalEvent.eventDescription,
                                }}
                              />
                            )}
                          {homeData && homeData.promotionalEvent && (
                            <Link
                              to={
                                "/panel-view/" + homeData.promotionalEvent._id
                              }
                              className="sd_textcapitalize"
                            >
                              Learn More
                              <div className="sd_arrow">
                                <img
                                  src="/village/images/arrow_right.svg"
                                  alt="Next Arrow"
                                  title="Next Arrow"
                                />
                              </div>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="sd_presention_block_section_right">
                      {topRightEvent &&
                        topRightEvent.map((article, i) => (
                          <div className="sd_presention_block_right" key={i}>
                            {article.map((card, i) => (
                              <div
                                className="sd_presention_left_inner sd_presention"
                                key={i}
                              >
                                {card &&
                                  card.eventDisplayTemplate == "Offer" &&
                                  card.eventDate == "" ? (
                                    <div className="logo_Day">
                                      <h3>All Festival</h3>
                                    </div>
                                  ) : card.eventDate == "" ? (
                                    ""
                                  ) : (
                                      <div className="logo_Day">
                                        {card &&
                                          card.availableThroughoutFestival == true ? (
                                            <div>
                                              <h3>
                                                {card &&
                                                  card.eventDate[0] &&
                                                  card.eventDate[0].dateKey}
                                              </h3>
                                            </div>
                                          ) : (
                                            <div>
                                              <h4>
                                                {TimezoneConverter(moment
                                                  .utc(
                                                  card &&
                                                  card.eventDate[0] &&
                                                  card.eventDate[0].date
                                                  )
                                                  .local()
                                                  , "MMMM")}
                                              </h4>
                                              <span>
                                                {TimezoneConverter(moment
                                                  .utc(
                                                  card &&
                                                  card.eventDate[0] &&
                                                  card.eventDate[0].date
                                                  )
                                                  .local()
                                                  , "DD")}
                                              </span>
                                            </div>
                                          )}
                                      </div>
                                    )}
                                <div className="sd_block_right_image">
                                  <img
                                    src={
                                      card &&
                                        card.keyEventThumbnailImage[0] &&
                                        card.keyEventThumbnailImage[0].link != ""
                                        ? `${process.env.REACT_APP_IMAGE_URL}` +
                                        card.keyEventThumbnailImage[0].link
                                        : card.keyEventThumbnailImage[0].url !=
                                          ""
                                          ? card.keyEventThumbnailImage[0].url
                                          : Sundance
                                    }
                                    alt="Blockimage"
                                  />
                                </div>
                                <div className="sd_block_right_dec">
                                  {card && card.eventDisplayTemplate == "Offer"
                                    ? card && <h2>{card.offerTitle}</h2>
                                    : card && <h2>{card.eventTitle}</h2>}

                                  {card &&
                                    card.eventDisplayTemplate == "Offer" ? (
                                      <span className="sd_textuppercase">
                                        All Festival
                                    </span>
                                    ) : card && card.eventDate == "" ? (
                                      ""
                                    ) : card &&
                                      card.availableThroughoutFestival == true ? (
                                          <span className="sd_textuppercase">
                                            {card &&
                                              card.eventDate[0] &&
                                              card.eventDate[0].dateKey}
                                            {this.state[card._id] ? (
                                              <button
                                                className="sd_textuppercase"
                                                onClick={() =>
                                                  this.getRemoveScheduleEvent(
                                                    card._id,
                                                    card && card.eventDate[0]
                                                      ? card.eventDate[0]
                                                        .eventParticularTime[0]
                                                        .start
                                                      : ""
                                                  )
                                                }
                                              >
                                                <img
                                                  src={Righticon}
                                                  alt="plus"
                                                  className="home-right-btn"
                                                />
                                              </button>
                                            ) : (
                                                <button
                                                  onClick={() =>
                                                    this.getAddScheduleEvent(
                                                      card._id,
                                                      card && card.eventDate[0]
                                                        ? card.eventDate[0]
                                                          .eventParticularTime[0]
                                                          .start
                                                        : "",
                                                      card.eventDisplayTemplate
                                                    )
                                                  }
                                                >
                                                  <img src={Plus} alt="Plus" />
                                                </button>
                                              )}
                                          </span>
                                        ) : (
                                          <span className="sd_textuppercase">
                                            {TimezoneConverter(moment
                                              .utc(
                                              card &&
                                              card.eventDate[0] &&
                                              card.eventDate[0]
                                                .eventParticularTime[0].start
                                              )
                                              .local()
                                              , "hh:mm A ")}{" "}
                                            -{" "}
                                            {TimezoneConverter(moment
                                              .utc(
                                              card &&
                                              card.eventDate[0] &&
                                              card.eventDate[0]
                                                .eventParticularTime[0].end
                                              )
                                              .local()
                                              , "hh:mm A")}
                                            {this.state[card._id] ? (
                                              <button
                                                className="sd_textuppercase"
                                                onClick={() =>
                                                  this.getRemoveScheduleEvent(
                                                    card._id,
                                                    card.eventDate[0]
                                                      ? card.eventDate[0]
                                                        .eventParticularTime[0]
                                                        .start
                                                      : ""
                                                  )
                                                }
                                              >
                                                <img
                                                  src={Righticon}
                                                  alt="plus"
                                                  className="home-right-btn"
                                                />
                                              </button>
                                            ) : (
                                                <button
                                                  onClick={() =>
                                                    this.getAddScheduleEvent(
                                                      card._id,
                                                      card && card.eventDate[0]
                                                        ? card.eventDate[0]
                                                          .eventParticularTime[0]
                                                          .start
                                                        : "",
                                                      card.eventDisplayTemplate
                                                    )
                                                  }
                                                >
                                                  <img src={Plus} alt="Plus" />
                                                </button>
                                              )}
                                          </span>
                                        )}
                                  {card.eventDisplayTemplate == "Offer" ? (
                                    <p
                                      className="paragraph_span_16"
                                      dangerouslySetInnerHTML={{
                                        __html: card.offerDescription,
                                      }}
                                    />
                                  ) : (
                                      <p
                                        className="paragraph_span_16"
                                        dangerouslySetInnerHTML={{
                                          __html: card.eventDescription,
                                        }}
                                      />
                                    )}

                                  {card && (
                                    <Link
                                      to={"/panel-view/" + card._id}
                                      className="sd_textcapitalize"
                                    >
                                      Learn More
                                      <div className="sd_arrow">
                                        <img
                                          src="/village/images/arrow_right.svg"
                                          alt="Next Arrow"
                                          title="Next Arrow"
                                        />
                                      </div>
                                    </Link>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* inner homepage */}
            <div className="sd_inner_sec_block">
              {sections &&
                sections.map((articles, i) => (
                  <section key={i} className="sd_inner_sec_block_">
                    <div className="sd_inner_sec_container">
                      {articles.map((card, i) => (
                        <div className="sd_presention_left_inner" key={i}>
                          {card &&
                            card.eventDisplayTemplate == "Offer" &&
                            card.eventDate == "" ? (
                              <div className="logo_Day">
                                <h3>All Festival</h3>
                              </div>
                            ) : card && card.eventDate == "" ? (
                              ""
                            ) : (
                                <div className="logo_Day">
                                  {card &&
                                    card.availableThroughoutFestival == true ? (
                                      <div>
                                        <h3>
                                          {card &&
                                            card.eventDate[0] &&
                                            card.eventDate[0].dateKey}
                                        </h3>
                                      </div>
                                    ) : (
                                      <div>
                                        <h4>
                                          {TimezoneConverter(moment
                                            .utc(
                                            card &&
                                            card.eventDate[0] &&
                                            card.eventDate[0].date
                                            )
                                            .local()
                                            , "MMMM")}
                                        </h4>
                                        <span>
                                          {TimezoneConverter(moment
                                            .utc(
                                            card &&
                                            card.eventDate[0] &&
                                            card.eventDate[0].date
                                            )
                                            .local()
                                            , "DD")}
                                        </span>
                                      </div>
                                    )}
                                </div>
                              )}

                          <div className="sd_block_right_image">
                            <img
                              src={
                                card &&
                                  card.keyEventThumbnailImage[0] &&
                                  card.keyEventThumbnailImage[0].link != ""
                                  ? `${process.env.REACT_APP_IMAGE_URL}` +
                                  card.keyEventThumbnailImage[0].link
                                  : card.keyEventThumbnailImage[0].url != ""
                                    ? card.keyEventThumbnailImage[0].url
                                    : Sundance
                              }
                              alt="Blockimage"
                            />
                          </div>
                          <div className="sd_block_right_dec">
                            {card && card.eventDisplayTemplate == "Offer"
                              ? card && <h2>{card.offerTitle}</h2>
                              : card && (
                                <h2
                                  className={
                                    card.eventDate == ""
                                      ? "sd_offer_space"
                                      : "sd_offer"
                                  }
                                >
                                  {card.eventTitle}
                                </h2>
                              )}

                            {card && card.eventDisplayTemplate == "Offer" ? (
                              <span className="sd_textuppercase">
                                All Festival
                              </span>
                            ) : card && card.eventDate == "" ? (
                              ""
                            ) : card &&
                              card.availableThroughoutFestival == true ? (
                                    <span className="sd_textuppercase">
                                      {card &&
                                        card.eventDate[0] &&
                                        card.eventDate[0].dateKey}

                                      {this.state[card._id] ? (
                                        <button
                                          className="sd_textuppercase"
                                          onClick={() =>
                                            this.getRemoveScheduleEvent(
                                              card._id,
                                              card.eventDate[0]
                                                ? card.eventDate[0]
                                                  .eventParticularTime[0].start
                                                : ""
                                            )
                                          }
                                        >
                                          <img
                                            src={Righticon}
                                            alt="plus"
                                            className="home-right-btn"
                                          />
                                        </button>
                                      ) : (
                                          <button
                                            onClick={() =>
                                              this.getAddScheduleEvent(
                                                card._id,
                                                card.eventDate[0]
                                                  ? card.eventDate[0]
                                                    .eventParticularTime[0].start
                                                  : "",
                                                card.eventDisplayTemplate
                                              )
                                            }
                                          >
                                            <img src={Plus} alt="Plus" />
                                          </button>
                                        )}
                                    </span>
                                  ) : (
                                    <span className="sd_textuppercase">
                                      {TimezoneConverter(moment
                                        .utc(
                                        card &&
                                        card.eventDate[0] &&
                                        card.eventDate[0].eventParticularTime[0]
                                          .start
                                        )
                                        .local()
                                        , "hh:mm A ")}{" "}
                                      -{" "}
                                      {TimezoneConverter(moment
                                        .utc(
                                        card &&
                                        card.eventDate[0] &&
                                        card.eventDate[0].eventParticularTime[0]
                                          .end
                                        )
                                        .local()
                                        , "hh:mm A")}
                                      {this.state[card._id] ? (
                                        <button
                                          className="sd_textuppercase"
                                          onClick={() =>
                                            this.getRemoveScheduleEvent(
                                              card._id,
                                              card.eventDate[0]
                                                ? card.eventDate[0]
                                                  .eventParticularTime[0].start
                                                : ""
                                            )
                                          }
                                        >
                                          <img
                                            src={Righticon}
                                            alt="plus"
                                            className="home-right-btn"
                                          />
                                        </button>
                                      ) : (
                                          <button
                                            onClick={() =>
                                              this.getAddScheduleEvent(
                                                card._id,
                                                card.eventDate[0]
                                                  ? card.eventDate[0]
                                                    .eventParticularTime[0].start
                                                  : "",
                                                card.eventDisplayTemplate
                                              )
                                            }
                                          >
                                            <img src={Plus} alt="Plus" />
                                          </button>
                                        )}
                                    </span>
                                  )}

                            {card && card.eventDisplayTemplate == "Offer" ? (
                              <p
                                className="paragraph_span_16"
                                dangerouslySetInnerHTML={{
                                  __html: card.offerDescription,
                                }}
                              />
                            ) : (
                                <p
                                  className="paragraph_span_16"
                                  dangerouslySetInnerHTML={{
                                    __html: card.eventDescription,
                                  }}
                                />
                              )}

                            {card && (
                              <Link
                                to={"/panel-view/" + card._id}
                                className="sd_textcapitalize"
                              >
                                Learn More
                                <div className="sd_arrow">
                                  <img
                                    src="/village/images/arrow_right.svg"
                                    alt="Next Arrow"
                                    title="Next Arrow"
                                  />
                                </div>
                              </Link>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </section>
                ))}
            </div>

            {this.state.loginPrompt && (
              <FavoriteCard userLogin={this.removePopup} />
            )}
            {/* pagination */}
            <div className="sd_pagination_sec">
              <div className="sd_pagination_container">
                <div className="customPagination">
                  <ul>
                    <li
                      className={
                        this.state.prevPage != -1 ? "enable" : "disabled"
                      }
                    >
                      <button onClick={() => this.getpage(this.state.prevPage)}>
                        <img
                          src="/village/images/arrow_left.svg"
                          alt="Next Arrow"
                          title="prev Arrow"
                        />
                      </button>
                    </li>
                    {allEvent &&
                      this.state.pageTemp.map((page, i) => (
                        <li
                          key={i}
                          className={
                            this.state.currentPage == page ? "active" : ""
                          }
                        >
                          <button onClick={() => this.getpage(page)}>
                            {page}
                          </button>
                        </li>
                      ))}

                    <li
                      className={
                        this.state.nextPage != -1 ? "enable" : "disabled"
                      }
                    >
                      <button onClick={() => this.getpage(this.state.nextPage)}>
                        <img
                          src="/village/images/arrow_right.svg"
                          alt="Next Arrow"
                          title="Next Arrow"
                        />
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sd_sponsors_section sd_home_sponsors_section">
          <SponsorsLogo slider={true} />
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default VillageHomePreview;
