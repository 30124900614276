import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';


// const mapDispatchToProps = dispatch => {
//     return bindActionCreators(ActCreators, dispatch)
// };

const FestivalVillage = ({ history, festivalData,SearchData, activetab }) => {

    let sub;
    const [PerPage] = useState(5);
    const [offset, setOffset] = useState(1);
    const [mobile, setMobile] = useState(false)
    useEffect(() => {
        window.innerWidth < 991 && setMobile(true)
    }, [])
    window.addEventListener('resize', () => {
        window.innerWidth < 991 ? setMobile(true) : setMobile(false)
    })
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setOffset(selectedPage + 1)
    };

    const handleLink = (id) => {
       
        // window.location = `https://fest21village.sundance.org/panel-view/${id}`
        // window.open(`${process.env.REACT_APP_HEADER_FESTIVAL_MAINSTREET}/panel-view/${id}`,'_self')
        festivalData.map((item) => {
            debugger
            if(item.partnerName){
                window.open(`${process.env.REACT_APP_HEADER_FESTIVAL_MAINSTREET}/main-street/${id}`,'_self')
            }
            else if(item.satelliteScreenName){
                // window.open(`${process.env.REACT_APP_HOME_URL}/community-hub-detail/${id}`)
                window.open(`${process.env.REACT_APP_HEADER_FESTIVAL_MAINSTREET}/community-hub-detail/${id}`,'_self')
            }
            else{
                window.open(`${process.env.REACT_APP_HEADER_FESTIVAL_MAINSTREET}/panel-view/${id}`,'_self')
            }
        })
    }
    return (
        mobile ?
            <AccordionItem className="accordion__item ">
                <div className="" >
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <div className="">
                                <p className="">Festival Village</p>
                                <span className="sd_cal_dropdown"></span>
                            </div>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <section className="sd_search_section">
                            {festivalData ?.length === 0 ? <div class="sd_no_filters_found" > <h3 className="sd_textuppercase">{`NO RESULTS FOUND FOR ${SearchData ? "“" + SearchData + "”" : "“FESTIVALVILLAGES”"}.`}</h3> </div> :
                                <>
                                <div className="sd_inner_container">
                                    <div className="sd_filter">
                                        {/* Festival Village */}
                                        {festivalData ?.length > 0 && festivalData ?.slice(offset - 1, offset - 1 + PerPage).map((item) => {
                                            return (
                                                <div className="sd_search_Details">
                                                    <div className="sd_flex sd_flexwrap sd_justbetween">
                                                        <div className="sd_main_details">
                                                            <div className="sd_details_inner">
                                                                <div className="sd_category_title">
                                                                    <p className="sd_textuppercase">{activetab === 4  ? "FESTIVAL VILLAGE" : ''}</p>
                                                                </div>
                                                                <div className="sd_category_details">
                                                                    <h3 className="sd_textuppercase llll" onClick={() => handleLink(item._id)}><Link>{item.role ? item.role === 'partner' ? item.partnerName : item.role === 'satellite' && item.satelliteScreenName : item.eventTitle}</Link></h3>
                                                                </div>
                                                                <div dangerouslySetInnerHTML={{ __html: item.role ? item.role === 'partner' ? item.partnerPageDescription : item.role === 'satellite' && item.satellitePageDescription : item.eventDescription }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>

                                <div className="custom-pagination">
                                    <ReactPaginate
                                        previousLabel={<img src="/images/slider_arrow_left.svg" />}
                                        nextLabel={<img src="/images/slider_arrow_right.svg" />}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={Math.ceil(festivalData ?.length / 5)}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                    // forcePage={this.state.currentPage.selected || 0}
                                    />
                                </div>
                                </>
                }
                        </section>
                    </AccordionItemPanel>
                </div>
            </AccordionItem>
            :
            <div className="sd_search_result">
                <section className="sd_search_section">
                    {festivalData ?.length === 0 ? <div class="sd_no_filters_found" > <h3 className="sd_textuppercase">{`NO RESULTS FOUND FOR ${SearchData ? "“" + SearchData + "”" : "“FESTIVALVILLAGES”"}.`}</h3> </div> :
                        <>
                        <div className="sd_inner_container">
                            <div className="sd_filter">
                                {/* Festival Village */}
                                {festivalData ?.length > 0 && festivalData ?.slice(offset - 1, offset - 1 + PerPage).map((item) => {
                                    return (
                                        <div className="sd_search_Details">
                                            <div className="sd_flex sd_flexwrap sd_justbetween">
                                                <div className="sd_main_details">
                                                    <div className="sd_details_inner">
                                                        <div className="sd_category_title">
                                                            <p className="sd_textuppercase">{activetab === 4  ? "FESTIVAL VILLAGE" : ''}</p>
                                                        </div>
                                                        <div className="sd_category_details">
                                                            <h3 className="sd_textuppercase" onClick={() => handleLink(item._id)}><Link>{item.role ? item.role === 'partner' ? item.partnerName : item.role === 'satellite' && item.satelliteScreenName : item.eventTitle}</Link></h3>
                                                        </div>
                                                        <div dangerouslySetInnerHTML={{ __html: item.role ? item.role === 'partner' ? item.partnerPageDescription : item.role === 'satellite' && item.satellitePageDescription : item.eventDescription }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div className="custom-pagination">
                            <ReactPaginate
                                previousLabel={<img src="/images/slider_arrow_left.svg" />}
                                nextLabel={<img src="/images/slider_arrow_right.svg" />}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={Math.ceil(festivalData ?.length / 5)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                            // forcePage={this.state.currentPage.selected || 0}
                            />
                        </div>
                        </>
                    }
                </section>
            </div>

    )
}
export default FestivalVillage