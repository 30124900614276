import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActCreators } from "../store/bindActionCretor";

const mapStateToProps = (state) => ({
  sponsors: state.sponsors,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(ActCreators, dispatch);
};

class HeroHeader extends Component {
  render() {
    const sponsors = this.props.sponsors;
    return (
      <div className="sd_hero_header sd_sponsors_header">
        <div className="sd_inner_container">
          <div className="sd_hero_header_inner">
            <div className="sd_hero_header_text">
              <h1 className="sd_textuppercase">{sponsors.title}</h1>
              <div
                className="sd_hero_header_content sd_rtf_content para_span paragraph_span_16"
                dangerouslySetInnerHTML={{ __html: sponsors.description }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(HeroHeader);
