import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { dispatch } from 'rxjs/internal/observable/pairs';
import service from "../common/service";
import { useDispatch } from 'react-redux';
import SimpleReactValidator from "simple-react-validator";
import Loader from './Loader';


const SearchBox = ({ history, closePopups }) => {
    const apiserve = new service();
    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('');
    const [searchErr, setSearchErr] = useState('');
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState('')

    const handleChange = (e) => {

        const { value } = e.target

        setLoader(true)

        setSearchText(value)
        // getSeatchData(value)
        setData(value)

        // if (value.length === 0 || value.length === 1) { setSearchErr('Enter at least two characters to search.') }
        // else { setSearchErr(); }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        if (searchText.length >= 2) {
            getSeatchData(data)
            window.open(`${process.env.REACT_APP_HOME_URL}search?id=${data}`,'_self')
            // history.push(`/search-results?id=${data}`);
            // history.push(`/film-info/${id}`)
            closePopups();
            setSearchErr('');
        }
        else{ setSearchErr('Enter at least two characters to search.')}
    }

    const getSeatchData = (val) => {
        setLoader(true)
        // dispatch({ type: 'GlobleSearch', payload: val })
        // this.apiserve.sendevent("search|searchAll", { filter: data });
    }
    // Search Program - Close Popup

    return (
        <div className="serach_modal">
            <span className="overlay_header"></span>
            <div className="sd_main_serach">
                <div className="sd_serach_box">
                    <form>
                        <input
                            type="text"
                            placeholder="Search the Festival website"
                            value={searchText}
                            onChange={(e) => handleChange(e)}
                        />
                        <span className="sd_error_msg">
                            {searchErr}
                        </span>
                        <Link>
                            <button className="sd_enter_serach" type="submit" onClick={(e) => handleSubmit(e)}>
                                {/* {loader && `${process.env.REACT_APP_IMAGE_PATH}slider_arrow_right.svg`} */}
                                {/* <img src={loader ? apiserve.loader : `${process.env.REACT_APP_IMAGE_PATH}slider_arrow_right.svg`} alt="search List" title="Search List" /> */}
                                <img src={`${process.env.REACT_APP_IMAGE_PATH}slider_arrow_right.svg`} alt="search List" title="Search List" />
                            </button>
                        </Link>
                    </form>
                </div>
                <div className="close_search">
                    <button className="sd_close_popup" onClick={() => closePopups()}>
                        <span></span>
                        <span></span>
                    </button>
                </div>
            </div>
        </div>
    )
}
export default SearchBox