import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sundance from '../images/sundanceimg.png';

class PartnerEvent extends Component {
  render() {
    // Props
    let { content } = this.props;
    return (
      <div className="sd_block">
        <div className="sd_block_images">
          <img
            src={
              content.state.image  && content.state.image != ""
                ? `${process.env.REACT_APP_IMAGE_URL}` + content.state.image
                : content.state.imageUrl != '' ? content.state.imageUrl : Sundance   
            }
            alt="PartnerEvent"
          />
        </div>
        <div className="sd_block_des">
          <h3>{content.state.partnerVenueName}</h3>
          <Link to={content.link[1]}>
            See Details
            <div className="right_arrow">
              <img
                src="/village/images/arrow_right.svg"
                alt="Next Arrow"
                title="Next Arrow"
              />
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

export default PartnerEvent;
