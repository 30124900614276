import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { connect } from "react-redux";
import service from "../common/service";

import user_avtar from "../images/user_profile_logo.svg";

const cookies = new Cookies();

let sub;

class HeaderMenu extends Component {
  constructor() {
    super();
    this.state = {
      filmData: [],
      isShowSearch: false,
      isShowMenu: false,
    };
    this.apiserve = new service();
    this.gotoPage = this.gotoPage.bind(this);
  }

  componentWillUnmount() {
    sub.unsubscribe();
  }

  componentDidMount() {
    // Window Resize Event
    window.addEventListener("resize", this.windowResize);
    this.apiserve.sendevent("user|checkMTTime", {});
    sub = this.apiserve.getevent().subscribe((response) => {
      switch (response.event) {
        case "user|thirdPartyToken":
          if (!response.error) {
            window.open(response.data.URL, "_blank", "noopener,noreferrer");
          } else {
          }
          break;
        case "user|checkMTTime":
          if (!response.error) {
            this.setState({ isShowMenu: response.data.flag || false })
          }
          break;
        default:
          break;
      }
    });
  }

  // Window Resize Event
  windowResize = () => {
    // Hide Nav's on desktop
    document
      .querySelectorAll(".has-innerlist")
      .forEach((list) => list.classList.remove("active"));
  };

  // Toggle Inner Navigation Menu
  innerNavMenu = (e) => {
    let target = e.target;
    if (target.classList.contains("toggle_arrow")) {
      let li = target.parentNode.closest(".has-innerlist");

      if (li.classList.contains("active")) {
        li.classList.remove("active");
      } else {
        document
          .querySelectorAll(".has-innerlist")
          .forEach((list) => list.classList.remove("active"));

        li.classList.add("active");
      }
    }
  };

  gotoPage(to) {
    if (to === "schedule") {
      window.open(
        process.env.REACT_APP_HEADER_PROGRAM + `/schedule/`,
        "_self"
      );
    } else if (to === "new-frontier") {
      window.open(
        process.env.REACT_APP_HEADER_PROGRAM + `/new-frontier/`,
        "_self"
      );
    } else if (to === "beyond-film") {
      window.open(
        process.env.REACT_APP_HEADER_PROGRAM + `/beyond-film/`,
        "_self"
      );
    } else if (to === "live-events") {
      window.open(process.env.REACT_APP_HEADER_PROGRAM + `/live-events`, "_self");
    }else if (to === "partner-events") {
      window.open(process.env.REACT_APP_HEADER_PROGRAM + `/partner-events/`, "_self");
    } else if (to === "help") {
      window.open(process.env.REACT_APP_HOME_URL + `help`, "_self");
    } else {
      window.open(
        process.env.REACT_APP_HEADER_PROGRAM + `/films/${to}`,
        "_self"
      );
    }
  }

  gotoPageWithFilter(Filter) {
    window.open(
      process.env.REACT_APP_HEADER_PROGRAM + `/films/${Filter}`,
      "_self"
    );
  }

  goto(Link) {
    window.open(Link, "_self");
    document.querySelector('body').classList.remove('no-scroll');
    document.querySelector('nav').classList.remove('navmenu-active');
    if (document.querySelector('.navmenu-active').classList.contains('navmenu-active')) {
      document.querySelector('.navmenu-active').classList.remove('navmenu-active');
    }
  }

  signinRegister() {
    window.open(process.env.REACT_APP_HOME_URL + "sign-in", "_self");
  }
  checkAuthentication = () => {
    let particularUser =
      cookies.get("SundanceNewUser") || cookies.get("particularNewUser");
    if (!particularUser) {
      this.props.history.push("/sign-in");
    } else {
      window.open(`${process.env.REACT_APP_HOME_URL}/my-account`, "_self")
      if (this.props.tab) {
        this.props.tab(0);
      }
    }
  };
  gotoProfile(e, to) {
    window.open(`${process.env.REACT_APP_HOME_URL}my-account?tab=${to}`, "_self");
  }
  getLink = () => {
    var User = cookies.get("SundanceNewUser") || cookies.get("particularNewUser");
    if (User) {
      this.apiserve.sendevent("user|thirdPartyToken", {
        id: User.user._id,
        redirectLink: "https://industry.sundance.org/",
      });
    }
  };
  cartPage(e) {
    window.open(`${process.env.REACT_APP_HEADER_BUY_TICKETS}/cart`, "_self");
  }
  logout = (e) => {
    e.preventDefault();
    document.cookie =
      "SundanceNewUser=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";

    cookies.remove("SundanceNewUser", { path: "/" });
    document.cookie = "particularNewUser" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    cookies.remove("SundanceNewUser", { domain: ".sundance.org", path: "/" });
    cookies.remove("particularNewUser", { domain: ".sundance.org", path: "/" });
    let Zone = localStorage.getItem('TIMEZONE')
    localStorage.clear();
    if (Zone) localStorage.setItem('TIMEZONE', Zone)
    this.forceUpdate();
    this.props.history.push("/");
    window.open(process.env.REACT_APP_HOME_URL + 'sign-in', "_self")
    return this.props.userLogout();

  };

  activeSearchPopup = () => {
    this.setState({
      isShowSearch: true,
    });

    if (document.querySelector(".toggle_account_menu")) {
      document
        .querySelector(".toggle_account_menu")
        .classList.add("search-active");
    }
  };

  closePopup = () => {
    this.setState({
      isShowSearch: false,
    });

    if (document.querySelector(".toggle_account_menu")) {
      document
        .querySelector(".toggle_account_menu")
        .classList.remove("search-active");
    }
  };

  gotoNewTab(e, link) {
    e.preventDefault();
    window.open(link);
  }

  render() {
    let SundanceNewUser = cookies.get("SundanceNewUser");
    var userData = {};
    let userInfo = JSON.parse(localStorage.getItem("Header"));
    if (this.props && this.props.userInfo) {
      userData = this.props.userInfo;
    }
    let particularUser =
      cookies.get("SundanceNewUser") || cookies.get("particularNewUser");

    return (
      <>
        <ul className="sd_navigation sd_flex sd_aligncenter">

          {/* {
          window.location.host.includes("festival.sundance.org") || window.location.host.includes("digital.sundance.org") ? 
            <li><Link onClick={(e) => this.gotoNewTab(e, process.env.REACT_APP_HEADER_FESTIVAL_MERCH)}> Shop </Link></li> : ''
        } */}

        <li className="has-innerlist">
          <Link onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_PROGRAM+'/')}>Program</Link>
          <span className="toggle_arrow" onClick={this.innerNavMenu.bind(this)}></span>
          <ul className="sd_inner_navigation sd_hidden">
            <li><Link onClick={this.gotoPage.bind(this, 'schedule')}>Schedule</Link></li>
            <li><Link onClick={this.gotoPageWithFilter.bind(this, 'feature-films')}>Feature Films</Link></li>
            <li><Link onClick={this.gotoPage.bind(this, 'new-frontier')}>New Frontier</Link></li>
            <li><Link onClick={this.gotoPageWithFilter.bind(this, 'short-films')}>Short Films</Link></li>
            <li><Link onClick={this.gotoPageWithFilter.bind(this, 'indie-episodic')}>Indie Episodic</Link></li>
            <li><Link onClick={this.gotoPage.bind(this, 'beyond-film')} >Beyond Film</Link></li>
            {/* <li><Link onClick={this.gotoPage.bind(this, 'beyond-film')} className={window.location.host.includes('festival.sundance.org') ? this.state.isShowMenu ? '' : 'sd_events_none' : ''}>Beyond Film</Link></li> */}
            <li><Link onClick={this.gotoPage.bind(this, 'partner-events')}>Partner Events</Link></li>

            </ul>
          </li>
          {
            window.location.host.includes("festival.sundance.org") ? '' :
              <li><Link onClick={(e) => this.gotoNewTab(e, process.env.REACT_APP_HEADER_FESTIVAL_MERCH)}> Shop Merch</Link></li>
          }
          {
            window.location.host.includes("festival.sundance.org") || window.location.host.includes("digital.sundance.org") ? '' :
              <li><Link onClick={this.goto.bind(this, process.env.REACT_APP_HOME_URL + "highlight")}>Highlights</Link></li>
          }


          {window.location.host.includes("festival.sundance.org") ? '' :
            <li className="has-innerlist">
              <Link onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_BUY_TICKETS)}>Buy Tickets</Link>
              <span className="toggle_arrow" onClick={this.innerNavMenu.bind(this)}></span>
              <ul className="sd_inner_navigation sd_hidden">
                <li><Link onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_FESTIVAL_MERCH)}>Buy Merch</Link></li>
              </ul>
            </li>}
          {/* <li><Link onClick={this.gotoPage.bind(this, 'live-events')}>Join In-Person</Link></li> */}



          {
            window.location.host.includes("festival.sundance.org") ? '' :
              <li className="has-innerlist">
                <Link onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_ONLINE_EXPERIENCE)}>How to Fest</Link>
                <span className="toggle_arrow" onClick={this.innerNavMenu.bind(this)}></span>
                <ul className="sd_inner_navigation sd_hidden">
                  <li><Link onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_ONLINE_EXPERIENCE)}>Online</Link></li>
                  <li><Link onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_VENUES_EXPERIENCE)}>Satellite Screens</Link></li>
                </ul>
              </li>}
          {/* <li><Link onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_ONLINE_EXPERIENCE)}>Online Experience</Link></li>
                <li><Link onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_INPERSON_EXPERIENCE)}>In-Person Experience</Link></li>
                <li><Link onClick={this.gotoPage.bind(this, 'help')}>Help</Link></li> */}
          {/* <li><Link onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_INPERSON_EXPERIENCE)}>In-Person</Link></li> */}
          {/* <li><a href="https://www.sundance.org/festivals/sundance-film-festival/plan-your-trip" target='_blank' rel="noopener noreferrer">Lodging</a></li> */}



          {window.location.host.includes("festival.sundance.org") ? '' : <li className="has-innerlist">
            <Link onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_FESTIVAL_MAINSTREET)}> Festival Village</Link>
            <span className="toggle_arrow" onClick={this.innerNavMenu.bind(this)}></span>
            <ul className="sd_inner_navigation sd_hidden">
              <li>
                <Link onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_MAINSTREET)}
                > Main Street </Link></li>
              <li>
                <Link onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_FESTIVAL_SATELLITE_SCREENS)}
                > Community Hub</Link></li>
            </ul>
          </li>}


          {
            window.location.host.includes("festival.sundance.org") || window.location.host.includes("digital.sundance.org") ? '' :

              particularUser && (particularUser.user.role === 'sioUser' || particularUser.user.role === 'pressUser' || particularUser.user.role === 'artistUser') ?
                <li>
                  <Link onClick={this.getLink}>Press &amp; Industry</Link>
                </li> : ''
          }
          {/* { userInfo && userInfo.showMyLibrary ? <li><Link onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_SCREENING + 'Library')}>My Library</Link></li> : '' } */}
          {window.innerWidth <= 1366 ? (
            ""
          ) : !particularUser ? (
            <li className="sd_sign_in_btn">
              <Link onClick={this.signinRegister} className="sd_textuppercase sd_sign_in_btn">
                Sign in/register
              </Link>
            </li>
          ) : (
            ""
          )}
          {SundanceNewUser ? (
            <div className="sd_userProfile">


              <div className={`sd_user_profile_cart sd_flex sd_aligncenter ${this.props.isActive ? 'active' : ''}`}>
                <div className="sd_user_profile_user  sd_aligncenter">

                  <Link
                    onClick={this.checkAuthentication}
                    className="sd_user_profile sd_aligncenter"
                  >
                    <div className="sd_user_profile_image">
                      <img
                        src={
                          userInfo && userInfo.image
                            ? `${process.env.REACT_APP_IMAGE_URL}${userInfo.image}`
                            : user_avtar
                        }
                        alt="User name"
                        title="User name"
                      />

                    </div>
                    <p
                      title={`${userInfo && userInfo.fname} ${userInfo && userInfo.lname
                        }`}
                    >
                      {" "}
                      Hi, {userInfo && userInfo.fname}{" "}
                    </p>
                  </Link>

                </div>

                <div className="sd_calender">
                  <div className='sd_calender_img'>
                    <Link onClick={(e) => this.gotoProfile(e, 2)}>
                      <img src="/images/calendar.png" alt="calender" title="calender" />
                    </Link>
                  </div>
                </div>


                <div className="sd_cart_btn">
                  <button onClick={window.innerWidth <= 768 ? (e) => this.cartPage(e) : (e) => this.props.cartPage(e)}>
                    <img src="/images/cart.png" alt="Cart" title="Cart" />
                    {this.props.cartCount ? (
                      <span className="sd_label sd_label_yellow">
                        {this.props.cartCount}
                      </span>
                    ) : ("")}
                  </button>
                </div>


                <div class="sd_menu_search" onClick={() => this.props.isSearchOpen()}><img src="/images/search.png" alt="search" title="search" /></div>

              </div>
            </div>
          ) : ("")}
          <div className="sd_signin">
            <div className="sd_option sd_flex">
              {/* { !particularUser?<div className="sd_menu_search"><img src="/images/search.png" alt="search" title="search"/></div>:''} */}
              {/* {!particularUser ? '' : ''} */}
              {!particularUser ? <div className="sd_menu_search" onClick={() => this.props.isSearchOpen()}><img src="/images/search.png" alt="search" title="search" /></div> : ''}

              {/* For Mobile */}
              <div className="sd_menu_options sd_hidden">
                <div className="sd_menu_signin">
                  {this.props.isSingin ? (
                    <Link onClick={this.logout}>Sign out</Link>
                  ) : (
                    // <Link to="/sign-in">Sign In/register</Link>
                    <Link onClick={this.signinRegister}>Sign In/register</Link>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="sd_menu_search" onClick={this.activeSearchPopup}>
              <input
                className="sd_events_none"
                type="text"
                placeholder="SEARCH"
              />
              <button type="button">
                <img
                  src="/village/images/search_icon.svg"
                  alt="search"
                  title="search"
                />
              </button>
            </div> */}
          </div>
        </ul>
      </>
    );
  }
}

export default connect(null, null)(withRouter(HeaderMenu));
