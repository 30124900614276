import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import service from "../common/service";
import Popup from "../components/Popup";

let sub;
class Newsletter extends Component {
  constructor(props) {
    super(props);
    this.apiServe = new service();
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      subscribeMsg: false,
      popup: false,
      form_popup: false,
      popup_title: "",
      popup_note: [],
      generalUser: {
        email: "",
        password: "",
      },
    };
  }

  componentWillUnmount = () => {
    sub.unsubscribe();
  };

  openPopup = (popup_title, note, form) => {
    this.setState({
      popup: true,
      popup_title: popup_title,
      popup_note: note,
      form_popup: form,
    });
  };
  closePopup = (forgot) => {
    this.setState({
      popup: false,
      popup_title: "",
      popup_note: "",
      form_popup: false,
    });
  };

  componentDidMount = () => {
    sub = this.apiServe.getevent().subscribe((response) => {
      switch (response.event) {
        case "newsUpdate|create":
          if (response.error) {
            this.openPopup("", [response.data.error], false);

            this.setState({ subscribeMsg: false });

            return false;
          } else {
            this.setState({
              email: "",
            });
            this.validator.hideMessages();
            this.setState({ subscribeMsg: true });
            setTimeout(() => {
              this.setState({ subscribeMsg: false });
            }, 3000);
          }
          break;
        default:
          break;
      }
    });
  };

  submit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let data = {
        email: this.state.email,
      };
      this.apiServe.sendevent("newsUpdate|create", data);
    } else {
      this.setState({ subscribeMsg: false });
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleBlur = () => {
    if (this.validator.allValid()) {
      this.validator.hideMessages();
    } else {
      this.validator.showMessages();
    }
  };

  render() {
    const {
      popup,
      popup_title,
      popup_note,
      form_popup,
      subscribeMsg,
    } = this.state;
    return (
      <div className="sd_newsletter_sec">
        <div className="sd_container sd_inner_container">
          <h2 className="sd_textuppercase">
            Sign up for festival news & updates
          </h2>

          <div className="sd_newsletter_form">
            <form>
              <div className="sd_newsletter_form_inner sd_flex">
                <div className="sd_form_group sd_flex">
                  <input
                    type="email"
                    placeholder="Enter Your Email…"
                    value={this.state.email}
                    onBlur={this.handleBlur}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />

                  <div className="sd_submit_group">
                    <button
                      type="submit"
                      className="sd_btn sd_btn_black"
                      onClick={this.submit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
                <span className="sd_input_msg sd_input_error_msg">
                  {" "}
                  {this.validator.message("email", this.state.email, "required|email")}
                </span>
                {subscribeMsg ? (
                  <span className="sd_input_msg sd_input_success_msg">
                    Thanks for subscribing!
                  </span>
                ) : (
                  ""
                )}
              </div>
            </form>
          </div>
          {popup ? (
            <Popup
              title={popup_title}
              popup_note={popup_note}
              form={form_popup}
              onChildClick={this.closePopup}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default Newsletter;
