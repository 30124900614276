import React from 'react';
import moment from 'moment-timezone';
// import timezones from '../common/timezones.json';

var zone = moment.tz.guess();
var abbr = moment.tz(zone).format("z")
var DEFAULT_ZONE = moment.tz(zone).format("Z")
const TIME_ZONE = JSON.parse(localStorage.getItem('TIMEZONE')) ? JSON.parse(localStorage.getItem('TIMEZONE')).Abbreviation : abbr;

const getTimezoneOffset = (offset) => {
    offset = offset.split(":")
    const hours = parseInt(offset[0]) * 60
    return hours + (offset.length === 2 ? hours < 0 ? (-parseInt(offset[1])) : parseInt(offset[1]) : 0)
}

const Zone = JSON.parse(localStorage.getItem('TIMEZONE')) ? JSON.parse(localStorage.getItem('TIMEZONE')).Zone : DEFAULT_ZONE;
export const OFFSET = getTimezoneOffset(Zone)
export const localOffset = new Date().getTimezoneOffset();

// FOR CONVERTING ANY TIME TO SELECTED TIME_ZONE

// export function TimezoneConverter(DATE, FORMAT) {
//     const DT_FORMAT = FORMAT ? FORMAT : 'DD/MM/YYYY';
//     if(TIME_ZONE === abbr) return  FORMAT === null ?  moment(DATE) : moment(DATE).format(DT_FORMAT)
//     else return  FORMAT === null ?  moment(DATE).tz(TIME_ZONE) : moment(DATE).tz(TIME_ZONE).format(DT_FORMAT)
// }

export function TimezoneConverter(DATE, FORMAT) {
    DATE = DATE ? moment(DATE) : moment()
    const DT_FORMAT = FORMAT ? FORMAT : 'DD/MM/YYYY';

    if (TIME_ZONE === abbr) return FORMAT === null ? moment(DATE) : moment(DATE).format(DT_FORMAT)
    else {
        let newDate = moment(DATE).add(OFFSET + localOffset, 'minutes')
        return FORMAT === null ? newDate : newDate.format(DT_FORMAT)
    }
}

// FOR CONVERT DATE TO MOUNTAIN ZONE ONLY
export function MountainTimezoneConverter(DATE, FORMAT) {
    const DT_FORMAT = FORMAT ? FORMAT : 'DD/MM/YYYY';

        if (TIME_ZONE === abbr) {
            let newDate = moment(DATE).add(-getTimezoneOffset(Mountain_Default.Zone) + (-localOffset), 'minutes')
            return FORMAT === null ? newDate : newDate.format(DT_FORMAT)
        }
        else {
            return FORMAT === null ? moment(DATE) : moment(DATE).format(DT_FORMAT)
        }
}

// FOR GET CURRENT ZONE / HOUR_DIFFRENCE
export function getZone() {
    var USTimeZone = ["PST", "PDT", "EST", "EDT", "MST", "MDT", "CST", "CDT"];
    var getZone = USTimeZone.find((o) => o === TIME_ZONE);
    if (getZone) return getZone
    else return `GMT ${moment().format("Z")}`
}

// FOR MINI_LOADER
export function MiniLoader() {
    return (
        <div className="abs_loader ">
            <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
                <path fill="#FF7433" d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3 c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z"> <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="2s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /> </path>
                <path fill="#3349FF" d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7 c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z"> <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="-360 50 50" repeatCount="indefinite" /> </path>
                <path fill="#FF7433" d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5 L82,35.7z"> <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="2s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /> </path>
            </svg>
        </div>
    )
}

// FOR DEFAULT TIME_ZONE
var ABBR = moment.tz("America/Denver").format("z");
export const Mountain_Default = ABBR === 'MST' ? {
    Abbreviation: "MST", 
    Name: "Mountain Standard Time",
    Zone: "-07:00",
    Offset: "-7 hours"
} :
{
    Abbreviation: "MDT",
    Name: "Mountain Daylight Time",
    Zone: "-06:00",
    Offset: "-6 hours"
}

export const getuserRole = (Type) => {

    if (Type === 'pressUser')
        return 'Press'
    else if (Type === 'sioUser')
        return 'Industry'
    else if (Type === 'circleUser')
        return 'Industry'
    else if (Type === 'circleGuestUser')
        return 'Industry'
    else if (Type === 'generalUser')
        return 'General'
    else if (Type === 'artistUser')
        return 'Artist'
    else
        return 'General'
}

