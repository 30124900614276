// import { REHYDRATE } from 'redux-persist';
// var initState = {
//   setToken: '',
//   getLoggedUserData: {}
// };

// function sundanceReducer(state = initState, action) {
//   switch (action.type) {
//       case 'LogdedUserData':
//           return {
//               ...state,
//               getLoggedUserData: action.res
//           };
//       case REHYDRATE:
//           return {
//               ...state,
//               getLoggedUserData: action.payload &&
//                   action.payload.getLoggedUserData ? action.payload.getLoggedUserData : {}
//           }
//       default:
//           return {
//               ...state
//           }
//   }
// }

// export const reducer = sundanceReducer;
import moment from 'moment';

import { REHYDRATE } from 'redux-persist';
var initState = {
    setToken: '',
    filters: {},
    FilmPage: 0,
    getLoggedUserData: {},
    sponsors: {},
    GlobleSearch: '',
    SundanceUser: {},
    CurrentDate: new Date(),
    CurrentDateUser: new Date('2021-01-28T00:00:00'),
    CurrentUserData: {},
    CallFor: '',
    CallForFilter: '',
    appliedNewVillage: {
        users: [],
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
        accessibility: [],
        interest: []

    },

    appliedFullNewVillage: [],
};

function sundanceReducer(state = initState, action) {
    switch (action.type) {
        case 'LogdedUserData':
            return {
                ...state,
                getLoggedUserData: action.res
            };
        case 'Filters':
            return {
                ...state,
                filters: action.res
            };
        case 'Sponsors':
            return {
                ...state,
                sponsors: action.res
            };
        case 'SundanceUser':
            return {
                ...state,
                SundanceUser: action.res
            };
        case 'CurrentDate':
            return {
                ...state,
                CurrentDate: action.res
            };
        case 'FilmPage':
            return {
                ...state,
                FilmPage: action.res,
            };
        case 'CurrentDateUser':
            return {
                ...state,
                CurrentDateUser: action.res,
            };
        case 'CurrentUserData':
            return {
                ...state,
                CurrentUserData: action.res
            };
        case 'CallFor':
            return {
                ...state,
                CallFor: action.res
            };
        case 'CallForFilter':
            return {
                ...state,
                CallForFilter: action.res
            };
         
        case 'GlobleSearch':
            return {
                ...state,
                GlobleSearch: action.payload,
            };   
        case 'APPLIED_NEW_VILLAGE': {
            // debugger
            const appliedNewVillage = Object.assign({}, state.appliedNewVillage);
            var appliedFullNewVillage = Object.assign([], state.appliedFullNewVillage);
            if (action.payload.checked) {
                if (typeof appliedNewVillage[action.payload.filterType] !== 'object') {
                    appliedNewVillage[action.payload.filterType] = [];
                }
                appliedNewVillage[action.payload.filterType].push(action.payload.id);
                appliedFullNewVillage.push(action.payload);
            } else {
                appliedNewVillage[action.payload.filterType] = appliedNewVillage[action.payload.filterType].filter(e => e !== action.payload.id);
                appliedFullNewVillage = appliedFullNewVillage.filter(e => e.id !== action.payload.id)
            }
            return { ...state, appliedNewVillage, appliedFullNewVillage };
        }


        case 'CLEAR_PARTNERFILTER_FILTERS': {

            var appliedFullNewVillage = Object.assign({}, state.appliedFullNewVillage)
            var appliedNewVillage = Object.assign({}, state.appliedNewVillage)
            appliedFullNewVillage = [];
            appliedNewVillage.users = []
            appliedNewVillage.accessibility = []
            appliedNewVillage.interest = []
            appliedNewVillage.startDate = '';
            appliedNewVillage.endDate = '';
            appliedNewVillage.startTime = '';
            appliedNewVillage.endTime = '';
            return { ...state, appliedNewVillage, appliedFullNewVillage, };
        }

        case 'REMOVE_PARTNERFILTER_FILTERS': {
            const appliedNewVillage = Object.assign({}, state.appliedNewVillage);
            // eslint-disable-next-line
            var appliedFullNewVillage = Object.assign([], state.appliedFullNewVillage);

            appliedNewVillage[action.payload.filterType] = appliedNewVillage[action.payload.filterType].filter(e => e !== action.payload.id);
            appliedFullNewVillage = appliedFullNewVillage.filter(e => e.id !== action.payload.id)
            return { ...state, appliedNewVillage, appliedFullNewVillage };
        }

        case 'APPLIED_FILTERS_USER_DATE_TIME': {
            const appliedNewVillage = Object.assign({}, state.appliedNewVillage);
            appliedNewVillage[action.payload.name] = action.payload.date;
            return { ...state, appliedNewVillage };
        }

        case 'REMOVE_DATE_TIME_FILTERS': {
            const appliedNewVillage = Object.assign({}, state.appliedNewVillage);
            appliedNewVillage[action.payload.filterType] = appliedNewVillage[action.payload.filterType] = '';
            return { ...state, appliedNewVillage };
        }
        case 'HANDLE_DATE_OPERATION': {
            let appliedNewVillage = Object.assign({}, state.appliedNewVillage);
            const { DateOperation } = action.payload
            let Current_Date = moment(DateOperation.dateType === 'startDate' ? (appliedNewVillage.startDate ? appliedNewVillage.startDate : '2022-01-20T00:00:00') : (appliedNewVillage.endDate ? appliedNewVillage.endDate : '2022-01-30T00:00:00'))
            appliedNewVillage[DateOperation.dateType] = Current_Date[DateOperation.operation](1, 'day')

            return { ...state, appliedNewVillage }
        }


        case REHYDRATE:
            return {
                ...state,
                getLoggedUserData: action.payload &&
                    action.payload.getLoggedUserData ? action.payload.getLoggedUserData : {},
                ...state,
                filters: action.payload &&
                    action.payload.filters ? action.payload.filters : [],
                FilmPage: action.payload &&
                    action.payload.FilmPage ? action.payload.FilmPage : 0,
                sponsors: action.payload &&
                    action.payload.sponsors ? action.payload.sponsors : [],
                CurrentUserData: action.res &&
                    action.res.CurrentUserData ? action.res.CurrentUserData : [],
                appliedNewVillage: action.payload && action.payload.appliedNewVillage ? action.payload.appliedNewVillage : {
                    users: [],
                    startDate: '',
                    endDate: '',
                    startTime: '',
                    endTime: '',
                    accessibility: [],
                },
                GlobleSearch: action.payload && action.payload.GlobleSearch ? action.payload.GlobleSearch : ''
            }
        default:
            return {
                ...state
            }
    }
}

export const reducer = sundanceReducer;